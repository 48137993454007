import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {postApi} from '../../utils/fetchApi';
import {useTracking} from '../useTracking';
import {Reason} from '../../components/molecules/popups/CancelSubscriptionPopupReasonAsk';

export const useCancelSubscription = (reason?: string, details?: string) => {
  const queryClient = useQueryClient();
  const {trackEvent} = useTracking();

  const cancelSubscription = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['subscription']});
      await queryClient.invalidateQueries({queryKey: ['me']});
      trackEvent('Settings - Cancel subscription', {
        cancelSubscriptionReason: reason,
        cancelSubscriptionDetails:
          reason === Reason.OTHER ? details : undefined,
      });
    },
    mutationFn: async () =>
      postApi(
        '/subscription/cancel',
        {method: 'POST'},
        t`Unable to cancel the subscription`,
      ),
  });

  return cancelSubscription;
};
