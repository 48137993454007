import {t} from '@lingui/macro';
import {differenceInDays} from 'date-fns';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import {useTracking} from '../../hooks/useTracking';
import {isValidLinkedInProfileUrl} from '../../utils/isValidLinkedInProfileUrl';
import {usePostsToRecycle} from '../../hooks/recyclePost/usePostsToRecycle';
import {Page} from '../../components/templates/Page';
import {TextInput} from '../../components/atoms/TextInput';
import {RecyclePostCard} from '../../components/molecules/RecyclePostCard';
import {isSmallScreen, isTablet} from '../../utils/responsive';
import {FilterSelect, FilterSelectOption} from './components/FilterSelect';
import {RecycleSearchBar} from './components/RecycleSearchBar';
import {RecyclePostForm} from './components/RecyclePostForm';
import {RecyclePostCardSkeleton} from '../../components/molecules/RecyclePostCardSkeleton';
import {gridComponents} from '../myPosts/MyPosts';
import {VirtuosoGrid} from 'react-virtuoso';

export interface Post {
  id: string;
  date: Date;
  reactionCount: number;
  commentCount: number;
  content: string;
  imageUrl?: string;
}

enum TimePeriodsFilter {
  LAST_7_DAYS = 'Last 7 days',
  LAST_14_DAYS = 'Last 14 days',
  LAST_28_DAYS = 'Last 28 days',
  LAST_90_DAYS = 'Last 90 days',
  LAST_365_DAYS = 'Last 365 days',
  ALL_TIME = 'All time',
}

enum SortCriteria {
  MORE_REACTIONS = 'More reactions',
  MORE_COMMENTS = 'More comments',
}

export const RecyclePost: FunctionComponent = () => {
  const timePeriodOptions = useMemo(
    () => [
      {
        label: t`Last 7 days`,
        value: TimePeriodsFilter.LAST_7_DAYS,
      },
      {
        label: t`Last 14 days`,
        value: TimePeriodsFilter.LAST_14_DAYS,
      },
      {
        label: t`Last 28 days`,
        value: TimePeriodsFilter.LAST_28_DAYS,
      },
      {
        label: t`Last 90 days`,
        value: TimePeriodsFilter.LAST_90_DAYS,
      },
      {
        label: t`Last 365 days`,
        value: TimePeriodsFilter.LAST_365_DAYS,
      },
      {
        label: t`All time`,
        value: TimePeriodsFilter.ALL_TIME,
      },
    ],
    [],
  );
  const sortCriterias = useMemo(
    () => [
      {
        label: t`More reactions`,
        value: SortCriteria.MORE_REACTIONS,
      },
      {
        label: t`More comments`,
        value: SortCriteria.MORE_COMMENTS,
      },
    ],
    [],
  );

  const {trackEvent} = useTracking();

  const [sortCriteria, setSortCriteria] = useState<
    FilterSelectOption<SortCriteria>
  >(sortCriterias[0]);

  const [selectedTimePeriod, setSelectedTimePeriod] = useState<
    FilterSelectOption<TimePeriodsFilter>
  >(timePeriodOptions[timePeriodOptions.length - 1]);

  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [profileUrl, setProfileUrl] = useState(
    localStorage.getItem('recyclePostProfileUrl') || '',
  );
  const [urlError, setUrlError] = useState('');
  const [isRecycling, setIsRecycling] = useState(false);
  const [postToBeRecycled, setSelectedPost] = useState<Post | undefined>(
    undefined,
  );

  useEffect(() => {
    trackEvent('RecyclePost - Click - Open page');
  }, [trackEvent]);

  const handleSortCriteriaChange = (
    newFilter: FilterSelectOption<SortCriteria>,
  ) => {
    setSortCriteria(newFilter);
    trackEvent('RecyclePost - Click - Filter by success', {
      filterBy: newFilter.value,
    });
  };

  const handleTimePeriodChange = (
    newTimePeriod: FilterSelectOption<TimePeriodsFilter>,
  ) => {
    setSelectedTimePeriod(newTimePeriod);
    trackEvent('RecyclePost - Click - Filter by date', {
      period: newTimePeriod.value,
    });
  };

  const handleSearchKeywordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchKeyword(event.target.value);
      trackEvent('RecyclePost - Fill - Search', {keyword: searchKeyword});
    },
    [searchKeyword, trackEvent],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const url = event.target.value;
      setProfileUrl(url);
      if (!isValidLinkedInProfileUrl(url)) {
        setUrlError(
          t`This LinkedIn profile url is not a valid url. Please provide a url that begins with ‘https://www.linkedin.com/in/’`,
        );
        return;
      }
      setUrlError('');
      trackEvent('RecyclePost - Fill - LinkedIn profile url', {
        profileUrl: url,
      });
    },
    [trackEvent],
  );

  const {data: postsData, isFetching} = usePostsToRecycle(profileUrl);

  const sortedPosts = useMemo(() => {
    if (!postsData) {
      return [];
    }
    const now = new Date();

    const filterPosts = (posts: Post[]) => {
      return posts.filter((post) => {
        if (!post.content.toLowerCase().includes(searchKeyword.toLowerCase())) {
          return false;
        }
        const postDate = new Date(post.date);
        const daysAgo = differenceInDays(now, postDate);
        const matchesTimePeriod = (() => {
          switch (selectedTimePeriod.value) {
            case TimePeriodsFilter.LAST_7_DAYS:
              return daysAgo <= 7;
            case TimePeriodsFilter.LAST_14_DAYS:
              return daysAgo <= 14;
            case TimePeriodsFilter.LAST_28_DAYS:
              return daysAgo <= 28;
            case TimePeriodsFilter.LAST_90_DAYS:
              return daysAgo <= 90;
            case TimePeriodsFilter.LAST_365_DAYS:
              return daysAgo <= 365;
            case TimePeriodsFilter.ALL_TIME:
              return true;
            default:
              return true;
          }
        })();
        return matchesTimePeriod;
      });
    };

    const sortPosts = (posts: Post[]) => {
      return posts.sort((a, b) => {
        if (sortCriteria.value === SortCriteria.MORE_REACTIONS) {
          return b.reactionCount - a.reactionCount;
        }
        return b.commentCount - a.commentCount;
      });
    };

    const filteredPosts = filterPosts(postsData.posts);
    const sortedFilteredPosts = sortPosts(filteredPosts);

    trackEvent('RecyclePost - List posts', {
      profileUrl: profileUrl,
      period: selectedTimePeriod.value,
      postCount: sortedFilteredPosts.length,
    });

    return sortedFilteredPosts;
  }, [
    postsData,
    trackEvent,
    profileUrl,
    selectedTimePeriod,
    searchKeyword,
    sortCriteria,
  ]);

  return (
    <Page>
      {isRecycling ? (
        postToBeRecycled && (
          <RecyclePostForm
            postToBeRecycled={postToBeRecycled}
            setIsRecycling={setIsRecycling}
          />
        )
      ) : (
        <>
          <Title>{t`Recycle a post`}</Title>
          <Container>
            <ContentContainer $isError={!!urlError}>
              <TextInput
                fullWidth
                placeholder={'https://www.linkedin.com/in/'}
                label={t`LinkedIn profile url`}
                value={profileUrl}
                onChange={handleInputChange}
                multiline={false}
                error={!!urlError}
                helperText={urlError}
              />
              <SelectContainer>
                <Row>
                  <FilterSelect
                    selectedOption={sortCriteria}
                    setSelectedOption={handleSortCriteriaChange}
                    options={sortCriterias}
                  />
                  <FilterSelect
                    selectedOption={selectedTimePeriod}
                    setSelectedOption={handleTimePeriodChange}
                    options={timePeriodOptions}
                  />
                </Row>
              </SelectContainer>
              <RecycleSearchBar
                fullWidth
                placeholder={'Search by keyword'}
                value={searchKeyword}
                onChange={handleSearchKeywordChange}
                multiline={false}
              />
            </ContentContainer>
            {sortedPosts.length > 0 ? (
              <VirtuosoContainer>
                <VirtuosoGrid
                  data={sortedPosts}
                  style={{height: '100%', marginTop: '1em'}}
                  components={gridComponents}
                  itemContent={(index, post) => (
                    <RecyclePostCard
                      key={index}
                      post={post}
                      setIsRecycling={setIsRecycling}
                      setSelectedPost={setSelectedPost}
                    />
                  )}
                />
              </VirtuosoContainer>
            ) : isFetching ? (
              <PostsContainer>
                {Array.from({length: 6}).map((_, index) => (
                  <RecyclePostCardSkeleton key={index} />
                ))}
              </PostsContainer>
            ) : null}
          </Container>
        </>
      )}
    </Page>
  );
};

const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  padding: 1em;
  border-radius: 1.5em;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
`;

const Title = styled.h1`
  font-size: 1.5em;
  margin-bottom: 1.2em;
  margin-top: 0em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const ContentContainer = styled.div<{$isError: boolean}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1em;
  gap: 1em;
  width: 100%;
  margin-bottom: ${({$isError}) => ($isError ? '3em' : 0)};
  @media (max-width: ${isTablet}) {
    flex-wrap: wrap;
    gap: 0.75em;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  row-gap: 0.75em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1em;
  @media (max-width: ${isTablet}) {
    gap: 0.75em;
  }
`;

const PostsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-self: stretch;
  align-items: stretch;
  margin-top: 1em;
  gap: 2em;
  overflow-y: auto;
`;
const VirtuosoContainer = styled.div`
  height: calc(100vh - 15em);
  @media (max-width: ${isSmallScreen}) {
    height: calc(100vh - 20em);
  }
  @media (max-width: ${isTablet}) {
    height: calc(100vh - 29em);
  }
`;
