import {useState, useEffect} from 'react';
import {RedactContentSource} from '../../pages/redactpost/components/RedactPostContent';
import {RedactStyleSource} from '../../pages/redactpost/components/RedactPostStyle';
import {StyleSource} from '../../pages/onboarding/components/ChooseStyleSource';
import {ContentSource} from '../../pages/onboarding/components/ChooseContentSource';
import {RedactedPost} from './useRedactPostMutation';

export const useLoadingProgress = (
  contentSource: ContentSource | RedactContentSource | undefined,
  writingStyle: StyleSource | RedactStyleSource | undefined,
  isLoading: boolean,
  isRedact: boolean = false,
  posts?: RedactedPost[],
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const timeout =
      (10 +
        (contentSource ===
        (isRedact ? RedactContentSource.FROM_AN_URL : ContentSource.FROM_AN_URL)
          ? 60
          : 45) +
        (writingStyle ===
          (isRedact
            ? RedactStyleSource.PROFILE_URL
            : StyleSource.OTHERS_PROFILE_URL) ||
        writingStyle ===
          (isRedact ? RedactStyleSource.PROFILE_URL : StyleSource.PROFILE_URL)
          ? 60
          : 0)) *
      10;

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        let increment = 10;
        if (prevProgress < 30) {
          increment = 1;
        }
        if (prevProgress >= 30 && prevProgress < 60) {
          increment = 10;
        }
        if (prevProgress >= 60 && prevProgress < 80) {
          increment = 20;
        }
        const newProgress = prevProgress + increment;
        if (newProgress >= 99) {
          clearInterval(interval);
          return 99;
        }
        return newProgress;
      });
    }, timeout);

    return () => clearInterval(interval);
  }, [contentSource, writingStyle, isRedact]);

  useEffect(() => {
    if (!isLoading && posts && posts.length > 0) {
      setLoading(false);
    }
  }, [isLoading, posts]);

  return {loading, progress};
};
