import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import ChevronBottom from './Icons/ChevronBottom';
import styled, {keyframes} from 'styled-components';
import {useTheme} from '../theme/theme';
import {CircleFlag} from 'react-circle-flags';
import {isTablet} from '../../utils/responsive';
import {SavedPostLanguage} from '../../hooks/savedPost/savedPost.types';
import {
  Language,
  postLanguages,
} from '../../hooks/postLang/useDefaultLanguage.ts';
import {useClickOutside} from '../../hooks/useClickOutside.ts';

interface Props {
  postLang: SavedPostLanguage;
  setPostLang: Dispatch<SetStateAction<SavedPostLanguage>>;
  className?: string;
  onChange?: (language: Language) => void;
  languages?: Language[];
}

export const LanguageSelect: FunctionComponent<Props> = ({
  postLang,
  setPostLang,
  className,
  onChange,
  languages = postLanguages,
}) => {
  const theme = useTheme();
  const [isActive, setIsActive] = useState<boolean>(false);
  const selectedLanguage = languages.find(
    (langage) => langage.value === postLang,
  );
  const countryCode = selectedLanguage ? selectedLanguage.countryCode : '';

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => {
    setIsActive(false);
  });

  return (
    <Container
      ref={containerRef}
      className={className}
      $active={isActive}
      onClick={() => {
        setIsActive(!isActive);
      }}>
      <Lang>
        <StyledCircleFlag countryCode={countryCode} height="16" />
        <SemiBold>{selectedLanguage?.name}</SemiBold>
      </Lang>
      <StyledChevronBottom
        $active={isActive}
        color={theme.colors.neutral.shade11}
      />
      {isActive && (
        <SubContainer>
          {languages.map((language, index) => (
            <StyledButton
              key={index}
              $selected={postLang === language.value}
              onClick={() => {
                setPostLang(language.value);
                onChange && onChange(language);
              }}>
              <StyledCircleFlag
                countryCode={language.countryCode}
                height="16"
              />
              {language.name}
            </StyledButton>
          ))}
        </SubContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{$active: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.625em;
  justify-content: space-between;
  padding: 0.6em;
  border-radius: 1em;
  border: 1px solid
    ${({theme, $active}) =>
      $active ? theme.colors.primary1.shade2 : theme.colors.neutral.shade5};
  box-shadow: ${({$active, theme}) =>
    $active ? `0px 0px 0px 4px ${theme.colors.primary1.shade1}` : 'none'};
  flex: 0.4;
  cursor: pointer;
  &:hover {
    ${({$active, theme}) =>
      !$active && `border: 1px solid ${theme.colors.neutral.shade7}`}
  }
  @media (max-width: ${isTablet}) {
    padding: 0.8em;
  }
`;
const Lang = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625em;
`;
const SemiBold = styled.span`
  font-weight: 600;
  font-size: 0.875em;
  letter-spacing: 0.03125em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;
const SubContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 3.5em;
  padding: 0.8em;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 0.625em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  z-index: 10;
  animation: ${fadeIn} 0.3s ease forwards;
`;
const StyledButton = styled.button<{$selected: boolean}>`
  display: flex;
  align-items: center;
  gap: 0.625em;
  border-radius: 0.75em;
  padding: 0.3em 0.5em;
  font-size: 0.875em;
  letter-spacing: 0.0125em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
  background-color: ${({theme, $selected}) =>
    $selected ? theme.colors.primary1.shade5 : 'transparent'};
  border: none;
  text-align: start;
  cursor: pointer;

  ${({theme, $selected}) =>
    $selected &&
    `
        border: 2px solid ${theme.colors.primary1.shade2};
      `}
`;
const StyledCircleFlag = styled(CircleFlag)`
  border: 2px solid ${({theme}) => theme.colors.neutral.shade13};
  border-radius: 50%;
`;
const StyledChevronBottom = styled(ChevronBottom)<{$active: boolean}>`
  transition: transform 0.3s ease;
  transform: ${({$active}) => ($active ? 'rotate(180deg)' : 'rotate(0deg)')};
  @media (max-width: ${isTablet}) {
    height: 1.25em;
    width: 1.25em;
  }
`;
