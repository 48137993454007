import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import SignUpBackground from '../../assets/images/SignUpBackground.png';
import {SignUpForm} from '../../components/organisms/LoginForms/SignUpForm';
import RedactAILogo from '../../components/atoms/Images/RedactAILogo.tsx';
import {LoginForm} from '../../components/organisms/LoginForms/LoginForm';
import {ResetPasswordForm} from '../../components/organisms/LoginForms/ResetPasswordForm';
import {t} from '@lingui/macro';
import {BackgroundStripe} from '../../components/atoms/Images/BackgroundStripe.tsx';
import {isTablet} from '../../utils/responsive';

export enum AuthMode {
  LOGIN,
  SIGNUP,
  RESET_PASSWORD,
}

export interface SuperTokensError {
  isSuperTokensGeneralError: boolean;
  message: string;
}

export const LoginPage: FunctionComponent = () => {
  const [authMode, setAuthMode] = useState<AuthMode>(AuthMode.LOGIN);

  const handleAuthModeChange = (newMode: AuthMode) => {
    setAuthMode(newMode);
  };

  const renderForm = () => {
    switch (authMode) {
      case AuthMode.LOGIN:
        return <LoginForm onAuthModeChange={handleAuthModeChange} />;
      case AuthMode.SIGNUP:
        return <SignUpForm onAuthModeChange={handleAuthModeChange} />;
      case AuthMode.RESET_PASSWORD:
        return <ResetPasswordForm onAuthModeChange={handleAuthModeChange} />;
    }
  };

  return (
    <PageContainer>
      <BackgroundStripesStyled />
      <ContentContainer>
        <TabletLogoContainer>
          <RedactAILogo />
        </TabletLogoContainer>
        <LeftContainer>
          <RedactAILogo />
          <TextContainer>
            <Tagline>{t`Revolutionizing Content Creation:`}</Tagline>
            <Title>
              {t`Introducing`} <Highlight>RedactAi</Highlight>
            </Title>
            <Title>{t`Your AI Writing Assistant!`}</Title>
          </TextContainer>
          <FeatureContainer>
            <img src={SignUpBackground} alt="" />
          </FeatureContainer>
        </LeftContainer>
        <RightContainer>{renderForm()}</RightContainer>
      </ContentContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const BackgroundStripesStyled = styled(BackgroundStripe)`
  position: fixed;
  bottom: 10em;
  right: 0em;
`;

const TabletLogoContainer = styled.div`
  display: none;
  justify-content: center;
  padding: 2em 0;
  margin-bottom: 2em;

  @media (max-width: ${isTablet}) {
    display: flex;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 7em;
  position: relative;

  @media (max-width: ${isTablet}) {
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
`;

const LeftContainer = styled.div`
  flex: 1;
  padding: 2em;
  display: flex;
  flex-direction: column;

  @media (max-width: ${isTablet}) {
    display: none;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 37em;
  gap: 0;
  margin-top: 3em;
`;

const Tagline = styled.p`
  font-size: 1.7em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
  margin-bottom: 0.5em;
`;

const Title = styled.h1`
  font-size: 3.2em;
  font-weight: 700;
  margin-top: 0;
`;

const Highlight = styled.span`
  color: ${({theme}) => theme.colors.primary1.shade3};
`;

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 2em;
  img {
    width: 35em;
    height: auto;
  }
`;

const RightContainer = styled.div`
  flex: 1;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${isTablet}) {
    width: 100%;
    max-width: 30em;
    margin: 0 auto;
  }
`;
