import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import EmailVerification, {
  isEmailVerified,
} from 'supertokens-web-js/recipe/emailverification';
import {t} from '@lingui/macro';
import {useSnackbar} from 'notistack';
import {BackgroundStripe} from '../../atoms/Images/BackgroundStripe.tsx';
import {useNavigate} from 'react-router-dom';
import {VerificationCheck} from '../icons/VerificationCheck';
import {signOut} from 'supertokens-web-js/recipe/thirdpartyemailpassword/index';
import {Button} from '../../atoms/Button.tsx';

export const SignUpEmailVerification: React.FunctionComponent = () => {
  const [resendStatus, setResendStatus] = useState<string | null>(null);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const hasShownSnackbar = useRef(false);

  useEffect(() => {
    const checkEmailVerification = async () => {
      const emailVerification = await isEmailVerified();
      if (emailVerification.isVerified) {
        navigate('/redactPost');
        return;
      }
      if (!hasShownSnackbar.current) {
        enqueueSnackbar(t`Sign-up successful! Please verify your email.`, {
          variant: 'success',
        });
        hasShownSnackbar.current = true;
      }
    };

    checkEmailVerification();
  }, [navigate, enqueueSnackbar]);

  const handleResend = async () => {
    try {
      await EmailVerification.sendVerificationEmail();
      setResendStatus(t`Verification email resent successfully!`);
      enqueueSnackbar(resendStatus, {
        variant: 'success',
      });
    } catch (error) {
      setResendStatus(
        t`Failed to resend verification email. Please try again later.`,
      );
    }
  };

  const handleDisconnect = async () => {
    await signOut();
    navigate('/auth');
  };
  return (
    <PageContainer>
      <BackgroundStripesStyled />
      <VerificationContainer>
        <StyledVerificationCheck />
        <Title>{t`Verification email sent!`}</Title>
        <Message>
          {t`A verification email has been sent to your email, please check your inbox.`}
        </Message>
        <Button
          label={t`Resend`}
          variant="main"
          size="medium"
          onClick={handleResend}
        />
        <Button
          label={t`Disconnect`}
          variant="light"
          size="medium"
          onClick={handleDisconnect}
        />
        {resendStatus && <ResendStatus>{resendStatus}</ResendStatus>}
        <Stylediv />
      </VerificationContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const BackgroundStripesStyled = styled(BackgroundStripe)`
  position: fixed;
  bottom: 10em;
  right: 0em;
`;

const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  background: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  box-shadow: 0 4px 12px ${({theme}) => theme.colors.neutral.shade5};
  width: 27em;
  gap: 1em;
  z-index: 1;
`;

const StyledVerificationCheck = styled(VerificationCheck)`
  align-self: center;
  margin: 2em 0 0.2em;
`;

const Title = styled.h2`
  font-size: 2.3em;
  font-weight: 700;
  margin-bottom: 0.5em;
  align-self: center;
`;

const Message = styled.p`
  font-size: 1.2em;
  color: ${({theme}) => theme.colors.neutral.shade7};
  text-align: center;
  margin: 0 1em 1.5em;
  font-weight: 500;
`;

const Stylediv = styled.div`
  margin: 0.5em;
`;

const ResendStatus = styled.p`
  font-size: 1em;
  color: ${({theme}) => theme.colors.primary1.shade2};
  text-align: center;
  margin-top: 1em;
`;
