import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const DotIcon: FunctionComponent<Props> = ({
  width = 5,
  height = 6,
  color = '#1C1B1A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 5 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 3C5 4.38071 3.88071 5.5 2.5 5.5C1.11929 5.5 0 4.38071 0 3C0 1.61929 1.11929 0.5 2.5 0.5C3.88071 0.5 5 1.61929 5 3Z"
        fill={color}
      />
    </svg>
  );
};

export default DotIcon;
