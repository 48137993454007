import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import styled from 'styled-components';
import {Button} from '../atoms/Button';
import {TextInput} from '../atoms/TextInput';
import GenerateIcon from '../atoms/Icons/GenerateIcon';
import {Chip} from '../atoms/Chip';
import {t} from '@lingui/macro';
import {isTablet} from '../../utils/responsive';

interface Props {
  label: string;
  placeholder: string;
  quickActions: string[];
  fullWidth?: boolean;
  message?: string;
  setMessage?: Dispatch<SetStateAction<string>>;
  onRegeneratePostSubmit: () => Promise<void>;
}

export const ChatInputBar: FunctionComponent<Props> = ({
  label,
  placeholder,
  quickActions,
  fullWidth,
  message,
  setMessage,
  onRegeneratePostSubmit,
}) => {
  return (
    <Container>
      <InputButtonContainer>
        <StyledTextInput
          $fullWidth={fullWidth}
          label={label}
          placeholderText={placeholder}
          value={message}
          onChange={(e) => setMessage && setMessage(e.target.value)}
        />
        <StyledButton
          onClick={onRegeneratePostSubmit}
          label={t`Re-generate posts (free)`}
          variant="main"
          size="medium"
          fullWidth
          startIcon={<GenerateIcon height={21} width={21} />}
        />
      </InputButtonContainer>
      <QuickActions>
        <QuickActionLabel>{t`Try these`}</QuickActionLabel>
        {quickActions.map((action) => (
          <Chip
            key={action}
            label={action}
            variant="default"
            onclick={() => setMessage && setMessage(action)}
          />
        ))}
      </QuickActions>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputButtonContainer = styled.div`
  display: flex;
  margin-bottom: 0.2em;
  gap: 1em;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
  }
`;

const StyledTextInput = styled(TextInput)<{$fullWidth: Props['fullWidth']}>`
  width: ${({$fullWidth}) => ($fullWidth ? '100%' : '60em')};
`;

const QuickActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;
  gap: 0.5em;
  flex-wrap: wrap;
`;

const QuickActionLabel = styled.span`
  font-size: 0.9em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const StyledButton = styled(Button)`
  padding-top: 0.58em;
  padding-bottom: 0.58em;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;
