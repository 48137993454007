import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useRef,
} from 'react';
import styled, {keyframes} from 'styled-components';
import RedactAI from '../molecules/images/RedactAI.png';
import {MenuButton} from '../atoms/MenuButton';
import {SubscriptionInformationCard} from '../molecules/SubscriptionInformationCard';
import {useNavigate} from 'react-router-dom';
import {isSmallScreen} from '../../utils/responsive';
import {CloseIcon} from '../atoms/Icons/CloseIcon';
import {SIDEBAR_WIDTH} from './NavigationBar';
import {creditsToPost} from '../../utils/creditsToPost';
import {UserContext} from '../../common/UserContext/user.context';
import {Route} from '../templates/Page';
import {Subscription} from '../../hooks/subscription/useSubscription';
import {useClickOutside} from '../../hooks/useClickOutside';

interface Props {
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  subscription: Subscription | undefined;
  renewalDate: Date | null;
  routes: Route[];
}

export const NavigationBarMobile: FunctionComponent<Props> = ({
  isActive,
  setIsActive,
  subscription,
  renewalDate,
  routes,
}) => {
  const navigate = useNavigate();
  const {me} = useContext(UserContext);
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, () => setIsActive(false));
  return (
    <NavBar $isActive={isActive} ref={containerRef}>
      <div>
        {isActive && <StyledCloseIcon onClick={() => setIsActive(false)} />}
        <Image
          $isActive={isActive}
          src={RedactAI}
          alt="redactai"
          onClick={() => {
            navigate('/redactPost');
            setIsActive(false);
          }}
        />
        <Routes>
          {routes.map((route, index) => (
            <Relative key={index}>
              <MenuButton
                onClick={() => {
                  navigate(route.path);
                  setIsActive(false);
                }}
                label={route.name}
                startIcon={route.icon}
                isActive={
                  location.pathname === route.path ||
                  location.pathname.startsWith(`${route.path}/`)
                }
              />
              {route.isNew && <New>New</New>}
            </Relative>
          ))}
        </Routes>
      </div>
      <StyledSubscriptionInformationCard
        name={`${me?.firstName} ${me?.lastName}`}
        numberOfPostsLeft={creditsToPost(subscription?.remainingCredits || 0)}
        numberOfPosts={creditsToPost(subscription?.product.credits || 0)}
        renewalDate={renewalDate!}
        canceled={!subscription?.isActive}
      />
    </NavBar>
  );
};

const NavBar = styled.div<{$isActive: boolean}>`
  width: calc(${SIDEBAR_WIDTH}em + 0.8em);
  height: 100vh;
  background-color: ${({theme}) => theme.colors.neutral.shade14};
  margin: 0;
  padding: 0 1.5em;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  transform: ${({$isActive}) =>
    $isActive ? 'translateX(0)' : 'translateX(-100%)'};
  animation: ${({$isActive}) => ($isActive ? slideIn : slideOut)} 0.2s
    ease-in-out;
  @media (max-width: ${isSmallScreen}) {
    border-right: 1px solid ${({theme}) => theme.colors.neutral.shade6};
  }
`;
const Image = styled.img<{$isActive: boolean}>`
  width: 100%;
  cursor: pointer;
  margin-top: 2em;
  margin-left: ${({$isActive}) => ($isActive ? '1em' : '0')};
`;
const Routes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-bottom: 3em;
  margin-top: 3em;
`;
const Relative = styled.div`
  position: relative;
`;
const New = styled.span`
  padding: 0.3em 0.5em;
  border-radius: 0.7em;
  text-align: center;
  color: ${({theme}) => theme.colors.primary3.shade2};
  font-weight: 600;
  line-height: 1.25em;
  font-size: 0.75em;
  letter-spacing: 0.0125em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  width: fit-content;
  position: absolute;
  top: 50%;
  right: 1.25em;
  transform: translateY(-50%);
  cursor: pointer;
`;
const StyledSubscriptionInformationCard = styled(SubscriptionInformationCard)`
  margin-bottom: 2em;
`;
const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 1.6em;
  cursor: pointer;
`;
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;
