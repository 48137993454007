import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Button} from '../atoms/Button';
import ArrowRight from '../atoms/Icons/ArrowRight';
import DoneIcon from '../atoms/Icons/DoneIcon';
import Yohan from '../molecules/images/Yohan.png';
import {t} from '@lingui/macro';
import {useTheme} from '../theme/theme';
import {ProductPeriod} from '../../hooks/subscription/useSubscription';
import {Product} from '../../hooks/pricing/useProducts';

interface Props {
  productsToDisplay: Product[];
  priceToString: (price: number, period?: ProductPeriod) => string;
  redirectToCheckout: (productId: string) => void;
}

const Footer: FunctionComponent = () => {
  const theme = useTheme();
  return (
    <Col>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`High quality post generation`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`Copy writing style from any content creator`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`Generate all your posts in minutes`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} width={24} height={23} />
        <Text>{t`Repurpose any piece of content to a LinkedIn post`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`Find original and up-to-date post ideas`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`Multilingual support`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`Dedicated customer support`}</Text>
      </Row>
    </Col>
  );
};

export const PricingCard: FunctionComponent<Props> = ({
  priceToString,
  productsToDisplay,
  redirectToCheckout,
}) => {
  return (
    <Container>
      {productsToDisplay.map((product, index) => (
        <TrialCard key={index}>
          <div>
            <Header>{t`Free Trial for 7 days`}</Header>
            <TrialPrice>0€*/mo</TrialPrice>
            <Text>
              <StrongText>
                {t`Then ${priceToString(product.price)} euros billed` +
                  ' ' +
                  (product.period === ProductPeriod.YEARLY
                    ? t`annually`
                    : t`monthly`)}
              </StrongText>
              <br />
              {t`excluding taxes | Cancel anytime`}
            </Text>
          </div>
          <Button
            onClick={() => redirectToCheckout(product.id)}
            label={t`Try RedactAI for Free`}
            size="medium"
            variant="main"
            fullWidth
            endIcon={<ArrowRight height={18} width={18} />}
          />
          <Footer />
        </TrialCard>
      ))}
      <TalkCard>
        <div>
          <Header>{t`Let's Talk`}</Header>
          <TrialPrice>{t`Learn about RedactAI`}</TrialPrice>
          <Text>
            {t`For `}{' '}
            <StrongText>
              {t`copywriters, agencies or `} <br />
              {t`companies`}
            </StrongText>
          </Text>
        </div>
        <Button
          onClick={() =>
            window.open(
              'https://calendly.com/yohan-fvs/presentation-redactai?month=2024-07',
            )
          }
          label={t`Quick demo with Yohan`}
          size="medium"
          variant="main"
          fullWidth
          startIcon={<StyeldImage src={Yohan} alt="Yohan" />}
          endIcon={<ArrowRight height={18} width={18} />}
        />
        <Footer />
      </TalkCard>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const TrialCard = styled.div`
  width: 20.7em;
  padding: 1.5em;
  border-radius: 1.5em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;
const TalkCard = styled.div`
  width: 20.7em;
  padding: 1.5em;
  border-radius: 1.5em;
  background-color: ${({theme}) => theme.colors.neutral.shade2};
  border: 2px solid ${({theme}) => theme.colors.primary1.shade2};
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;
const Header = styled.p`
  font-size: 2em;
  letter-spacing: -0.03125em;
  font-weight: 700;
  margin: 0;
`;
const TrialPrice = styled.p`
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
`;
const Text = styled.p`
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0.03125em;
  margin: 0;
`;
const StrongText = styled.span`
  font-weight: 700;
  letter-spacing: 0.0125em;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const Row = styled.div`
  display: flex;
  gap: 0.75em;
`;
const StyeldImage = styled.img`
  height: 1.5em;
  width: 1.5em;
`;
