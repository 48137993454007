import {t} from '@lingui/macro';
import {FunctionComponent} from 'react';
import styled from 'styled-components';
import Creators from '../images/Creators.png';

interface Props {
  onClick: () => void;
}

export const CreatorStyle: FunctionComponent<Props> = ({onClick}) => {
  return (
    <Container onClick={onClick}>
      <Header>{t`Another creator's style`}</Header>
      <StyledImage src={Creators} alt="creators" />
      <Div>{t`Redact AI will analyze your last posts to copy your writing style`}</Div>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1em;
  padding-top: 2em;
  width: 19.875em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2em;
  align-items: center;
  cursor: pointer;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.25em;
  letter-spacing: 0.0125em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Div = styled.div`
  padding: 0.75em;
  border-radius: 0.875em;
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 0.875em;
  line-height: 1.25em;
  letter-spacing: 0.0125em;
`;
