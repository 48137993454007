import {t} from '@lingui/macro';
import {FunctionComponent} from 'react';
import styled, {useTheme} from 'styled-components';
import {isTablet} from '../../../utils/responsive';
import {ProgressBar} from '../../../components/atoms/ProgressBar';
import {LoaderChip} from '../../../components/atoms/LoaderChip';
import {Status} from '../../../components/atoms/Status';

interface Props {
  progress: number;
}

export const LoadingLayout: FunctionComponent<Props> = ({progress}) => {
  const theme = useTheme();

  return (
    <>
      <Progress>
        <ProgressHeader>
          <Status
            variant="alert"
            label={
              progress < 80 ? t`Step 2` : progress < 90 ? t`Step 3` : t`Step 4`
            }
          />
          <StepsTitle>
            {progress < 80
              ? t`Downloading the content`
              : progress < 90
                ? t`Analyzing the content`
                : t`Final steps`}
          </StepsTitle>
        </ProgressHeader>
        <StyledProgressBar
          label
          height="0.875em"
          progress={progress}
          background={theme.colors.neutral.shade3}
        />
      </Progress>
      <LoaderChipContainer>
        <LoaderChip
          variant={progress >= 0 ? 'success' : 'loading'}
          label={t`Initializing the IA model`}
        />
        <LoaderChip
          variant={progress >= 50 ? 'success' : 'loading'}
          label={t`Downloading the content`}
        />
        <LoaderChip
          variant={progress >= 80 ? 'success' : 'loading'}
          label={t`Analyzing the content`}
        />
        <LoaderChip
          variant={progress === 100 ? 'success' : 'loading'}
          label={t`Final steps`}
        />
      </LoaderChipContainer>
    </>
  );
};

const StepsTitle = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
`;

const Progress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;

const ProgressHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75em;
`;

const LoaderChipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
  }
`;

const StyledProgressBar = styled(ProgressBar)`
  @media (max-width: ${isTablet}) {
    width: 100%;
    width: 23.75em;
  }
`;
