import {FunctionComponent} from 'react';
import {CurrentSubscription} from '../../../components/molecules/CurrentSubscription';
import {
  ProductPeriod,
  Subscription,
} from '../../../hooks/subscription/useSubscription';
import {creditsToPost} from '../../../utils/creditsToPost';
import {CurrentSubscriptionFree} from '../../../components/molecules/CurrentSubscriptionFree';

/*
const mockInvoices = [
  {no: '#2345', date: '4 May, 2023', amount: '€ 145.34'},
  {no: '#2345', date: '4 May, 2023', amount: '€ 145.34'},
  {no: '#2345', date: '4 May, 2023', amount: '€ 145.34'},
  {no: '#2345', date: '4 May, 2023', amount: '€ 145.34'},
  {no: '#2345', date: '4 May, 2023', amount: '€ 145.34'},
];
 */

interface Props {
  subscription: Subscription;
}

export const SettingsSubscription: FunctionComponent<Props> = ({
  subscription,
}) => {
  return (
    <>
      {subscription.isActive ? (
        <CurrentSubscription
          currentPlan={
            subscription?.product.period === ProductPeriod.MONTHLY
              ? 'Monthly'
              : 'Annual'
          }
          renewalDate={subscription.creditsRenewalDate}
          posts={creditsToPost(subscription.product.credits)}
        />
      ) : (
        <CurrentSubscriptionFree />
      )}
    </>
  );
};

/*
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const StrongText = styled.span`
  font-size: 1.5em;
  font-weight: 700;
  line-height: 2em;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
 */
