import {UserContext} from './user.context';
import {useQuery} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import {fetchApi, postApi} from '../../utils/fetchApi';
import {User} from './user.types';
import Session from 'supertokens-web-js/recipe/session';
import mixpanel from 'mixpanel-browser';
import {getUnixTime} from 'date-fns';

export const useUserContext = (): UserContext => {
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [isSessionChecked, setIsSessionChecked] = useState(false);

  const checkSession = async () => {
    try {
      const session = await Session.getAccessTokenPayloadSecurely();
      if (session) {
        setUserId(session.sub);
      }
    } catch (error) {
      if (!window.location.pathname.startsWith('/auth')) {
        window.location.href = '/auth';
      }
    }
    setIsSessionChecked(true);
  };

  useEffect(() => {
    checkSession();
  }, []);

  const {data, isLoading, refetch, isError} = useQuery<User>({
    queryKey: ['me'],
    queryFn: () => fetchApi(`/user/me`),
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSessionChecked && userId && window.location.pathname === '/auth') {
      window.location.href = '/';
      return;
    }
    if (
      isSessionChecked &&
      !userId &&
      !window.location.pathname.startsWith('/auth')
    ) {
      window.location.href = '/auth';
    }
  }, [isSessionChecked, userId]);

  useEffect(() => {
    if (
      userId &&
      window.location.pathname.indexOf('/auth') === -1 &&
      data?.id !== userId
    ) {
      void refetch();
    }
  }, [data?.id, userId, refetch]);

  useEffect(() => {
    if (!data || !data.id) {
      return;
    }
    mixpanel.identify(data.id);

    mixpanel.people.set({
      $name: `${data.firstName} ${data.lastName}`,
      $email: `${data.email}`,
    });
  }, [data]);

  useEffect(() => {
    if (!data || !data.email) {
      return;
    }
    const me: User = data;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'pf3ye0qr',
      name: `${me.firstName} ${me.lastName}`,
      email: me.email,
      user_id: me.id,
      created_at: getUnixTime(me.createdAt),
      user_hash: me.intercomHash,
    });
  }, [data]);

  useEffect(() => {
    if (data?.id === undefined) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    rewardful('ready', function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      void (async () => {
        await postApi('/user/setReferralId', {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            referralId: Rewardful.referral,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            affiliateId: Rewardful.affiliate.id,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            affiliateName: Rewardful.affiliate.name,
          }),
        });
      })();
    });
  }, [data?.id]);

  return {
    me: isError ? undefined : data,
    loading: isLoading,
    refetch: async () => {
      checkSession();
    },
  };
};
