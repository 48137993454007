import {t} from '@lingui/macro';
import {Dispatch, FunctionComponent, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import {SelectButtonListOfStyle} from '../../atoms/SelectButtonListOfStyle';
import {RedactaiIcon} from '../../atoms/Icons/RedactaiIcon';
import {usePostToneOptions} from '../../../hooks/usePostToneOptions';
import {SavedPostTone} from '../../../hooks/savedPost/savedPost.types';
import {StyleSource} from '../../../pages/onboarding/components/ChooseStyleSource';

interface Props {
  setPostTone: Dispatch<SetStateAction<SavedPostTone>>;
  setchoosenStyle: Dispatch<SetStateAction<StyleSource | undefined | string>>;
}

export const RedactaiStyle: FunctionComponent<Props> = ({
  setPostTone,
  setchoosenStyle,
}) => {
  const postToneOptions = usePostToneOptions();
  const [selectedStyle, setSelectedStyle] = useState<SavedPostTone>();
  const handleStyleClick = (style: SavedPostTone) => {
    setSelectedStyle(style);
  };
  return (
    <Container>
      <Logo>
        <RedactaiIcon />
      </Logo>
      <Header>{t`From our list of style`}</Header>
      <Styles>
        {postToneOptions.map((postToneOption, index) => (
          <SelectButtonListOfStyle
            key={index}
            text={postToneOption.name}
            isSelected={postToneOption.value === selectedStyle}
            onClick={() => {
              handleStyleClick(postToneOption.value);
              setPostTone(postToneOption.value);
              setchoosenStyle(StyleSource.POST_TYPE);
            }}
          />
        ))}
      </Styles>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1em;
  padding-top: 2em;
  width: 19.875em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2em;
  align-items: center;
  position: relative;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.25em;
  letter-spacing: 0.0125em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Styles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
`;
const Logo = styled.div`
  width: 6.25em;
  height: 1.8125em;
  border-radius: 3.75em;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade15};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -0.9em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  padding: 0.5em;
  box-sizing: border-box;
  box-shadow: 0 0.5px 0 3px #ffeed7;
`;
