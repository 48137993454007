import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PostToRedact} from './redactPost.types';
import {t} from '@lingui/macro';
import {redactPostMock} from './redactPost.mock';
import {useContext} from 'react';
import {useTracking} from '../useTracking';
import {UserContext} from '../../common/UserContext/user.context';

export interface RedactedPost {
  id: string;
  text: string;
}

export interface RedactPostResponse {
  posts: RedactedPost[];
}

export class RedactPostError extends Error {
  constructor(
    message: string,
    public readonly level: 'error' | 'warning' | 'info',
  ) {
    super(message);
    this.name = 'RedactPostError';
  }
}

export const useRedactPostMutation = (
  onInsufficientCreditBalance: () => void,
  isRecycling = false,
) => {
  const {trackEvent} = useTracking();
  const {me} = useContext(UserContext);
  const queryClient = useQueryClient();

  const redactPost = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['subscription']});
      await queryClient.invalidateQueries({queryKey: ['savedPosts']});
    },
    mutationFn: async (post: PostToRedact) => {
      if (
        import.meta.env.VITE_NODE_ENV == 'development' &&
        import.meta.env.VITE_WITH_AI !== 'true'
      ) {
        if (me && !me.hasFinishedOnboarding) {
          trackEvent('Onboarding - Post has been generated');
        }
        if (isRecycling) {
          trackEvent('RecyclePost - Post has been generated');
        }
        return redactPostMock();
      }
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/redactPost`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(post),
        },
      );

      if (response.ok) {
        if (me && !me.hasFinishedOnboarding) {
          trackEvent('Onboarding - Post has been generated');
        }
        if (isRecycling) {
          trackEvent('RecyclePost - Post has been generated');
        }
        return response;
      }

      if (response.status === 403) {
        onInsufficientCreditBalance();
        return response;
      }

      const errorText = (await response.json()).message;

      switch (errorText) {
        case 'WEBSITE_URL_NOT_SUPPORTED':
          throw new RedactPostError(
            t`We can't retrieve the content of this website (it is blocking our AI). Please copy paste the content of the website or article directly into the “Key ideas” form field to redact your post.`,
            'info',
          );
        case 'LINKEDIN_URL_NOT_SUPPORTED':
          throw new RedactPostError(
            t`The content link is not a valid LinkedIn post link. Please check that the url is the url of a LinkedIn post. Please note that LinkedIn articles are not supported for now. You can copy/paste the content of the article directly into the “Key ideas” form field.`,
            'info',
          );
        case 'YOUTUBE_URL_NOT_SUPPORTED':
          throw new RedactPostError(
            t`Please check the video link. Sometimes, the video is not compatible because captions are disabled.`,
            'info',
          );
        case 'INVALID_LINKEDIN_PROFILE_URL':
          throw new RedactPostError(
            t`This profile link is not valid, please check that this is a real LinkedIn profile url.`,
            'info',
          );
        default:
          throw new RedactPostError(
            t`Please check if all the fields are filled correctly`,
            'error',
          );
      }
    },
  });

  return redactPost;
};
