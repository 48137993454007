import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {TextField, TextFieldProps} from '@mui/material';

interface Props extends Omit<TextFieldProps, 'variant'> {
  placeholderText?: string;
}

export const TextInput: FunctionComponent<Props> = ({
  label,
  placeholderText,
  ...props
}) => {
  return (
    <StyledInput
      variant="outlined"
      label={label}
      placeholder={placeholderText}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
};

const StyledInput = styled(TextField)`
  position: relative;

  & .MuiOutlinedInput-root,
  & .MuiInputBase-input,
  & .MuiInputLabel-outlined,
  & .MuiInputLabel-shrink,
  & .MuiFormHelperText-root {
    font-family: ${({theme}) => theme.fontFamily};
  }
  .MuiOutlinedInput-root {
    font-size: 1em;
    font-weight: 600;
    &:not(.Mui-error):not(.Mui-disabled) {
      fieldset {
        border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
      }
      color: ${({theme}) => theme.colors.neutral.shade7};
      background-color: ${({theme}) => theme.colors.neutral.shade1};

      &:hover fieldset {
        border: 1px solid ${({theme}) => theme.colors.neutral.shade7};
      }

      &.Mui-focused fieldset {
        border: 1px solid ${({theme}) => theme.colors.primary1.shade2};
        box-shadow: 0px 0px 0px 4px ${({theme}) => theme.colors.primary1.shade1};
      }
    }
    border-radius: 1em;
  }

  .Mui-error {
    .MuiInputBase-input {
      color: ${({theme}) => theme.colors.primary2.shade2};
    }
  }

  .Mui-disabled {
    background-color: ${({theme}) => theme.colors.neutral.shade2};
    fieldset {
      border: none;
    }
    border-radius: 1em;
  }
  .MuiInputBase-input {
    color: ${({theme}) => theme.colors.neutral.shade11};
    padding: 1em;
  }

  .Mui-focused .MuiInputBase-input {
    margin-top: 0.2em;
  }

  .MuiInputLabel-outlined {
    font-size: 0.8em;
    font-weight: 600;
    color: ${({theme}) => theme.colors.neutral.shade7};
    transform: translate(1.6em, -2em) scale(1);
    top: 0;
    transition:
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      opacity 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  .MuiInputLabel-shrink {
    transform: translate(1.6em, 1em) scale(1);
    opacity: 0;
  }

  .Mui-focused .MuiInputLabel-outlined,
  .MuiInputLabel-outlined.Mui-focused {
    transform: translate(1.6em, 0.2em) scale(1);
    opacity: 1;
  }

  .MuiFormHelperText-root {
    position: absolute;
    top: 100%;
    font-size: 0.8em;
    font-weight: 500;
    margin-top: 0.5em;
    margin-left: 0;
    color: ${({theme}) => theme.colors.primary2.shade2};
    word-wrap: break-word;
    width: 100%;
  }

  legend {
    width: 0;
  }

  .MuiInputBase-input::placeholder {
    color: ${({theme}) => theme.colors.neutral.shade9};
    font-size: 1em;
    font-weight: 400;
    opacity: 1;
  }
  .Mui-focused .MuiInputBase-input::placeholder {
    opacity: 0;
  }
`;
