msgid ""
msgstr ""
"POT-Creation-Date: 2023-08-20 18:39+0200\n"
"Mime-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: fr\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:110
msgid "- ..."
msgstr "- ..."

#: src/hooks/tutorial/useTutorialSteps.tsx:54
#~ msgid "- A list of ideas, separated by a newline and/or characters or semicolons"
#~ msgstr "- Une liste d'idées, séparées par un retour à la ligne et/ou des caractères ou des points-virgules"

#: src/hooks/tutorial/useTutorialSteps.tsx:84
#~ msgid "- A template text (advanced)"
#~ msgstr "- Un texte modèle (avancé)"

#: src/hooks/tutorial/useTutorialSteps.tsx:69
#~ msgid "- Any draft of text"
#~ msgstr "- Tout brouillon de texte"

#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:108
msgid "- Cheap"
msgstr "- Pas cher"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:45
#~ msgid "- Create an audience"
#~ msgstr "- Créer une audience"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:47
#~ msgid "- Find new clients"
#~ msgstr "- Trouver de nouveaux clients"

#: src/hooks/tutorial/useTutorialSteps.tsx:104
#~ msgid "- Professionals"
#~ msgstr "- Professionnels"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:46
#~ msgid "- Prove your skills"
#~ msgstr "- Prouver tes compétences"

#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:106
msgid "- Proximity with clients"
msgstr "- Proximité avec les clients"

#: src/hooks/tutorial/useTutorialSteps.tsx:101
#~ msgid "- Students"
#~ msgstr "- Etudiants"

#: src/components/molecules/RecyclePostCard.tsx:71
msgid "...see less"
msgstr "...voir moins"

#: src/components/molecules/RecyclePostCard.tsx:71
#: src/pages/redactpost/components/BasePostPreviewPopup.tsx:117
msgid "...see more"
msgstr "...voir plus"

#: src/components/molecules/ChooseStyleSourceCards/MyStyle.tsx:17
#: src/pages/onboarding/components/ChooseStyleSource.tsx:76
msgid "'s style"
msgstr "style"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:38
msgid "“I think squats and deadlift are bad for you. Best way to damage your back, shoulder, hips, knees. What I would do.”"
msgstr "” Je pense que les squats et le soulevé de terre sont mauvais pour vous. La meilleure façon d'endommager votre dos, vos épaules, vos hanches et vos genoux. Ce que je ferais.”"

#: src/hooks/tutorial/useTutorialSteps.tsx:77
#~ msgid "\"I think squats and deadlifts are bad for you."
#~ msgstr "\"Je pense que les squats et les soulevés de terre sont mauvais pour toi."

#: src/components/pages/Pricing/components/PricingTable.tsx:154
#~ msgid "{0} credits"
#~ msgstr "{0} crédits"

#: src/components/pages/Pricing/components/PricingTable.tsx:183
#: src/components/pages/Pricing/components/PricingTable.tsx:218
#~ msgid "{0} euro per post"
#~ msgstr "{0} euro par post"

#: src/components/molecules/UpgradePricingCard.tsx:81
#: src/components/molecules/UpgradePricingCard.tsx:122
msgid "{0} euros billed"
msgstr "{0} euros facturés"

#: src/components/organisms/SideBar/SideBar.tsx:119
#~ msgid "{0} subscription"
#~ msgstr "Abonnement {0}"

#: src/components/molecules/SavedPost.tsx:46
#~ msgid "{0} Words"
#~ msgstr "{0} Mots"

#: src/components/pages/Onboarding/components/ChooseStyleSource.tsx:28
#~ msgid "{0}'s style"
#~ msgstr "Le style de {0}"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:131
#~ msgid "* An email with a confirmation code may be sent by LinkedIn."
#~ msgstr "* Un email avec un code de confirmation peut être envoyé par LinkedIn."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:130
#~ msgid "* We will not store your LinkedIn credentials"
#~ msgstr "* Nous ne stockons pas vos identifiants LinkedIn"

#: src/components/pages/Pricing/components/PricingTable.tsx:190
#~ msgid "*7 days free trial | Cancelable anytime"
#~ msgstr "*7 jours gratuits | Annulable à tout moment"

#: src/pages/affiliate/Affiliate.tsx:38
msgid "*Offer Conditions"
msgstr "*Conditions de l'offre"

#: src/components/pages/Settings/Settings.tsx:75
#~ msgid "/month"
#~ msgstr "/mois"

#: src/components/pages/Settings/Settings.tsx:76
#~ msgid "/year"
#~ msgstr "/an"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:49
msgid "<directions>"
msgstr "<instructions>"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:57
msgid "<Hook with a statistics>"
msgstr "<Hook avec des statistiques>"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:59
msgid "<Optimistic conclusion about using LinkedIn>"
msgstr "<Conclusion optimiste sur l'utilisation de LinkedIn>"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:60
msgid "<Signature>"
msgstr "<Signature>"

#: src/components/atoms/PricingSlider.tsx:44
#: src/components/atoms/PricingSlider.tsx:52
msgid "€ per post"
msgstr "€ par post"

#: src/components/molecules/SubscriptionInformationCard.tsx:60
msgid "0 Credit left"
msgstr "0 Crédits restants"

#: src/components/molecules/SubscriptionInformationCard.tsx:145
msgid "0 posts left"
msgstr "0 posts restants"

#: src/components/pages/Onboarding/components/ChooseStyleSource.tsx:24
#~ msgid "1 minute"
#~ msgstr "1 minute"

#: src/components/molecules/GeneratedPost.tsx:91
msgid "1d"
msgstr "1j"

#: src/components/molecules/GeneratedPost.tsx:38
#~ msgid "1d •"
#~ msgstr "1j •"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:30
#~ msgid "2 minutes"
#~ msgstr "2 minutes"

#: src/components/organisms/PricingTable.tsx:115
#: src/components/organisms/PricingTable.tsx:145
msgid "2 months FREE"
msgstr "2 mois GRATUITS"

#: src/components/pages/Pricing/components/StripePricingTable.tsx:16
#~ msgid "2 months free on annual subscriptions"
#~ msgstr "2 mois offerts sur les abonnements annuels"

#: src/components/molecules/UpgradePricingCard.tsx:79
#~ msgid "349.90 euros billed annually"
#~ msgstr "349,90 euros facturés annuellement"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:120
#~ msgid "6 digits code you received by email"
#~ msgstr "Code à 6 chiffres reçu par email"

#: src/components/molecules/SubscriptionInformationCard.tsx:84
msgid "7 days"
msgstr "7 jours"

#: src/components/molecules/SubscriptionInformationCard.tsx:85
#~ msgid "7 days at Free"
#~ msgstr "7 jours gratuits"

#: src/components/organisms/PricingTable.tsx:108
msgid "7 days free trial"
msgstr "7 jours gratuits"

#: src/components/pages/Pricing/components/PricingTable.tsx:149
#~ msgid "7 days Free Trial"
#~ msgstr "7 jours gratuits"

#: src/components/molecules/ChooseContentSourceCards/FromUrl.tsx:36
msgid "A blog article"
msgstr "Un article de blog"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:31
#~ msgid "A content url"
#~ msgstr "Un contenu"

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:42
msgid "A content URL"
msgstr "Une URL de contenu"

#: src/components/molecules/ChooseContentSourceCards/FromUrl.tsx:32
msgid "A Linkedin post"
msgstr "Un post LinkedIn"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:63
#~ msgid "A LinkedIn post"
#~ msgstr "Un post LinkedIn"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:22
msgid "A list of ideas, separated by a newline and/or charaters or semicolons"
msgstr "Une liste d'idées, séparées par une nouvelle ligne et/ou des caractères ou des points-virgules"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:45
msgid "A template text"
msgstr "Un texte modèle"

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:50
msgid "A user with this email already exists. Please sign-in using Email/Password instead."
msgstr "Un utilisateur avec cet email existe déjà. Veuillez vous connecter en utilisant email/mot de passe."

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:39
#~ msgid "A user with this email already exists. Please sign-in using Email/Password."
#~ msgstr "Un utilisateur avec cet email existe déjà. Veuillez vous connecter en utilisant email/mot de passe."

#: src/components/organisms/LoginForms/SignUpForm.tsx:147
msgid "A user with this email already exists. Please sign-in with Google instead."
msgstr "Un utilisateur avec cet email existe déjà. Veuillez vous connecter avec Google."

#: src/components/organisms/LoginForms/EmailVerification.tsx:63
msgid "A verification email has been sent to your email, please check your inbox."
msgstr "Un e-mail de vérification a été envoyé à votre adresse e-mail, veuillez vérifier votre boîte de réception."

#: src/components/molecules/ChooseContentSourceCards/FromUrl.tsx:28
msgid "A Youtube video"
msgstr "Une vidéo Youtube"

#: src/components/pages/Pricing/components/PricingTable.tsx:72
#~ msgid "Access to new features"
#~ msgstr "Accès aux nouvelles fonctionnalités"

#: src/components/molecules/popups/DeleteAccountPopupConfirmed.tsx:22
msgid "account deleted"
msgstr "compte supprimé"

#: src/components/molecules/InvoiceTable.tsx:25
msgid "Actions"
msgstr "Actions"

#: src/components/molecules/affiliateHistory.tsx:24
msgid "Active"
msgstr "Actif"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:111
msgid "Add \"X\""
msgstr "Ajoute \"X\""

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:106
msgid "Add emojis"
msgstr "Ajoute des emojis"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:46
msgid "Advanced"
msgstr "Avancé"

#: src/pages/onboarding/components/ToolTipFirstStep.tsx:21
msgid "Advantage of street marketing"
msgstr "Avantage du street marketing"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:14
#: src/hooks/tutorial/useTutorialSteps.tsx:44
#~ msgid "Advantages of street marketing"
#~ msgstr "Avantages du street marketing"

#: src/pages/affiliate/Affiliate.tsx:37
msgid "Affiliate"
msgstr "Affilié"

#: src/components/molecules/FilterSelectUsedNotUsed.tsx:24
#: src/pages/findIdea/components/MyIdeasTab.tsx:40
#: src/pages/myPosts/MyPosts.tsx:49
msgid "All"
msgstr "Tous"

#: src/pages/recyclepost/RecyclePost.tsx:70
msgid "All time"
msgstr "Tout le temps"

#: src/components/molecules/popups/DeleteAccountPopupConfirmation.tsx:33
msgid "All your data will be deleted, including your saved posts and ideas."
msgstr "Toutes vos données seront supprimées, y compris vos publications et idées enregistrées."

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:57
msgid "Almost there! One more step to go"
msgstr "Presque là! Encore un pas"

#: src/components/organisms/LoginForms/SignUpForm.tsx:237
msgid "Already have an account?"
msgstr "Tu as déjà un compte ?"

#: src/pages/findIdea/FindIdea.tsx:95
msgid "Already have an idea ?"
msgstr "Tu as déjà une idée ?"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:111
#~ msgid "Already have an idea ? Generate a post"
#~ msgstr "Tu as déjà une idée ? Génère un post"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:43
msgid "Alright, I've changed my mind :)"
msgstr "Ok, j'ai changé d'avis :)"

#: src/components/molecules/InvoiceTable.tsx:24
msgid "Amount"
msgstr "Montant"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:58
#~ msgid "An email with a new code has been sent to {email}"
#~ msgstr "Un email avec un nouveau code a été envoyé à {email}"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:15
msgid "An error occurred during email verification."
msgstr "Une erreur s'est produite lors de la vérification de l'e-mail."

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:17
msgid "An error occurred during password reset."
msgstr "Une erreur s'est produite lors de la réinitialisation du mot de passe."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:53
#~ msgid "An error occurred, please make sure that you have entered the right email."
#~ msgstr "Une erreur est survenue, vérifie que tu as bien entré le bon email."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:67
#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:73
#~ msgid "An error occurred, please make sure that you have entered the right password."
#~ msgstr "Une erreur est survenue, vérifie que tu as bien entré le bon mot de passe."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:91
#~ msgid "An error occurred, please make sure you entered the last pin you received by email"
#~ msgstr "Une erreur est survenue, vérifie que tu as bien entré le dernier code reçu par email"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:99
#: src/hooks/resetPassword/useResetPassword.ts:45
msgid "An error occurred. Please try again later."
msgstr "Une erreur s'est produite. Veuillez réessayer plus tard."

#: src/hooks/usePostToneOptions.ts:21
msgid "Analytical / Pedagogical"
msgstr "Analyse / Pédagogique"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:20
#: src/pages/onboarding/components/PostStyleSelect.tsx:18
#~ msgid "Analytical/Pedagogical"
#~ msgstr "Analyse/Pédagogique"

#: src/pages/redactpost/components/LoadingLayout.tsx:30
#: src/pages/redactpost/components/LoadingLayout.tsx:52
msgid "Analyzing the content"
msgstr "Analyse du contenu"

#: src/components/pages/RedactPost/components/Loader.tsx:25
#~ msgid "Analyzing the style"
#~ msgstr "Analyse du style"

#: src/pages/redactpost/components/RedactPostContent.tsx:142
#~ msgid "Angle of the post"
#~ msgstr "Angle du post"

#: src/components/molecules/CurrentSubscription.tsx:50
msgid "Annual"
msgstr "Annuel"

#: src/components/molecules/PricingCard.tsx:71
#: src/components/molecules/UpgradePricingCard.tsx:84
#: src/components/molecules/UpgradePricingCard.tsx:125
msgid "annually"
msgstr "annuellement"

#: src/components/molecules/ChooseStyleSourceCards/CreatorStyle.tsx:13
#: src/pages/onboarding/components/ChooseStyleSource.tsx:89
msgid "Another creator's style"
msgstr "Style d'un autre créateur"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:33
msgid "Any draft of text"
msgstr "Tout brouillon de texte"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:323
#~ msgid "Any ideas you would like to share ?"
#~ msgstr "Des idées à partager ?"

#: src/components/molecules/ProfileInformationCard.tsx:27
msgid "Are you looking for a way to monetize your website, blog, or social media following?"
msgstr "Cherchez-vous à monétiser votre site web, blog ou votre présence sur les réseaux sociaux ?"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:24
msgid "Are you sure you want to cancel the subscription?"
msgstr "Etes-vous sûr de vouloir annuler l'abonnement ?"

#: src/components/organisms/LinkedInNewPostPopup/LinkedInNewPostPopup.tsx:38
#~ msgid "Are you sure you want to close the popup ? The text will be lost."
#~ msgstr "Es-tu sûr de vouloir fermer la popup ? Le texte sera perdu."

#: src/components/molecules/popups/DeleteIdeaPopup.tsx:36
msgid "Are you sure you want to delete this idea?"
msgstr "Êtes-vous sûr de vouloir supprimer cette idée?"

#: src/components/organisms/SavedIdea/SavedIdeaCard.tsx:30
#~ msgid "Are you sure you want to delete this idea? This action cannot be undone."
#~ msgstr "Êtes-vous sûr de vouloir supprimer cette idée ? Cette action ne peut pas être annulée."

#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:45
#~ msgid "Are you sure you want to delete this post ? The text will be lost."
#~ msgstr "Es-tu sûr de vouloir supprimer ce post ? Le texte sera perdu."

#: src/components/organisms/SavedPost/SavedPostCard.tsx:57
#~ msgid "Are you sure you want to delete this post? This action cannot be undone."
#~ msgstr "Êtes-vous sûr de vouloir supprimer ce post ? Cette action ne peut pas être annulée."

#: src/components/molecules/popups/DeleteAccountPopupConfirmation.tsx:30
msgid "Are you sure you want to delete your account?"
msgstr "Êtes-vous sûr de vouloir supprimer votre compte ?"

#: src/components/molecules/ProfileInformationCard.tsx:47
msgid "Are you the agency, copywriters or companies?"
msgstr "Êtes-vous une agence, des rédacteurs ou des entreprises ?"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:30
#~ msgid "Arrogant"
#~ msgstr "Arrogant"

#: src/components/molecules/LinkedInSchedulePostPopover/LinkedInSchedulePostPopover.tsx:64
#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:73
#~ msgid "at"
#~ msgstr "à"

#: src/components/molecules/GeneratedPost.tsx:126
#: src/components/molecules/SavedPost.tsx:67
#: src/pages/redactpost/components/BasePostPreviewPopup.tsx:71
msgid "Automatically saved"
msgstr "Sauvegardé automatiquement"

#: src/components/organisms/LoginForms/ResetPasswordForm.tsx:39
msgid "Back"
msgstr "Retour"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:110
msgid "Be more formal"
msgstr "Sois plus formel"

#: src/components/pages/Pricing/components/PricingTable.tsx:194
#~ msgid "Best value"
#~ msgstr "Meilleure offre"

#: src/hooks/tutorial/useTutorialSteps.tsx:80
#~ msgid "Best way to damage your back, shoulders, hips, knees. What I would do:\""
#~ msgstr "Meilleur moyen de te blesser le dos, les épaules, les hanches, les genoux. Ce que je ferais :\""

#: src/components/templates/Page.tsx:62
#~ msgid "Black Friday 🔥 50% off on all annual subscriptions with the code BLACKFRIDAY50"
#~ msgstr "Promo Black Friday 🔥 50% de réduction sur tous les abonnements annuels avec le code BLACKFRIDAY50"

#: src/components/pages/Pricing/components/PricingTable.tsx:172
#~ msgid "Book a demo with Yohan"
#~ msgstr "Réserve une démo avec Yohan"

#: src/components/molecules/ProfileInformationCard.tsx:24
msgid "Boost your earnings—join our affiliate program today!"
msgstr "Augmentez vos revenus—rejoignez notre programme d'affiliation dès aujourd'hui!"

#: src/components/pages/Pricing/components/PricingTable.tsx:219
#~ msgid "Business"
#~ msgstr "Business"

#: src/components/organisms/SideBar/SideBar.tsx:113
#~ msgid "Buy credits"
#~ msgstr "Acheter des crédits"

#: src/components/molecules/SubscriptionInformationCard.tsx:157
#~ msgid "Buy more credits"
#~ msgstr "Acheter plus de crédits"

#: src/components/molecules/popups/DeleteAccountPopupConfirmation.tsx:37
#: src/components/molecules/popups/DeleteIdeaPopup.tsx:41
#: src/components/molecules/popups/DescribeReason.tsx:35
msgid "Cancel"
msgstr "Annuler"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:85
msgid "Cancel Plan"
msgstr "Annuler le plan"

#: src/components/molecules/CurrentSubscription.tsx:59
#: src/components/molecules/CurrentSubscription.tsx:79
msgid "Cancel subscription"
msgstr "Annuler l'abonnement"

#: src/components/pages/Settings/Settings.tsx:109
#~ msgid "Canceled subscription"
#~ msgstr "Abonnement annulé"

#: src/components/molecules/RecyclePostCard.tsx:97
#: src/pages/onboarding/components/GenerateFromContentURL.tsx:46
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:50
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:145
#: src/pages/onboarding/components/PostStyle.tsx:52
#: src/pages/onboarding/components/ProfileUrlStyle.tsx:102
msgid "Change"
msgstr "Changer"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:113
msgid "Change \"X\" by \"Y\""
msgstr "Change \"X\" par \"Y\""

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:30
msgid "Change my inputs"
msgstr "Revenir en arrière"

#: src/components/molecules/ProfileHeader.tsx:49
#: src/components/molecules/ProfileHeaderMobile.tsx:50
msgid "Change password"
msgstr "Changer le mot de passe"

#: src/pages/pricing/Pricing.tsx:11
#~ msgid "Change plan"
#~ msgstr "Changer le plan"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:28
msgid "Cheap"
msgstr "Bon marché"

#: src/components/pages/Pricing/components/StripePricingTable.tsx:14
#~ msgid "Choose a subscription"
#~ msgstr "Choisis un abonnement"

#: src/pages/onboarding/components/ChooseContentSource.tsx:29
msgid "Choose the content of your post"
msgstr "Choisis le contenu de ton post"

#: src/components/pages/Pricing/components/PricingTable.tsx:64
#~ msgid "Choose the plan that works best for you. you can always upgrade later."
#~ msgstr "Choisis le plan qui te convient le mieux. Tu peux toujours changer plus tard."

#: src/components/organisms/PricingTable.tsx:142
msgid "Choose the plan that works best for you. You can always upgrade later."
msgstr "Choisis le plan qui te convient le mieux. Tu peux toujours changer plus tard."

#: src/pages/onboarding/components/ChooseStyleSource.tsx:60
msgid "Choose the style of your post"
msgstr "Choisis le style de ton post"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:86
#: src/components/pages/RedactPost/components/RedactPostForm.tsx:283
#~ msgid "Close"
#~ msgstr "Fermer"

#: src/components/molecules/RecyclePostCard.tsx:65
msgid "Comments: {0}"
msgstr "Commentaires : {0}"

#: src/components/molecules/PricingCard.tsx:97
#: src/components/molecules/UpgradePricingCard.tsx:157
msgid "companies"
msgstr "entreprises"

#: src/components/pages/Settings/Settings.tsx:112
#~ msgid "Company name"
#~ msgstr "Nom de l'entreprise"

#: src/components/pages/Settings/Settings.tsx:118
#~ msgid "Company position"
#~ msgstr "Poste"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:152
msgid "Confirm new password"
msgstr "Confirmer le nouveau mot de passe"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:151
msgid "Confirm New Password"
msgstr "Confirmer le nouveau mot de passe"

#: src/components/molecules/Popup/ConfirmationPopup.tsx:24
#~ msgid "Confirmation"
#~ msgstr "Confirmation"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:128
#~ msgid "Connect my LinkedIn account"
#~ msgstr "Connecter mon compte LinkedIn"

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:38
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:42
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:137
#: src/pages/redactpost/components/RedactPostContent.tsx:51
msgid "content"
msgstr "contenu"

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:35
msgid "Content"
msgstr "Contenu"

#: src/components/organisms/SavedPost/SavedPostCard.tsx:72
#~ msgid "Content url:"
#~ msgstr "Url du contenu :"

#: src/components/molecules/OnboardingReferralSource/OnboardingReferralSource.tsx:86
#~ msgid "Continue"
#~ msgstr "Continuer"

#: src/components/molecules/affiliateLinkCopy.tsx:19
#: src/components/molecules/GeneratedPost.tsx:162
#: src/components/molecules/SavedPost.tsx:91
#: src/hooks/redactPost/usePostPreview.ts:26
msgid "Copied to clipboard"
msgstr "Copié dans le presse-papier"

#: src/components/molecules/affiliateLinkCopy.tsx:31
msgid "Copied!"
msgstr "Copié!"

#: src/components/molecules/affiliateLinkCopy.tsx:31
#: src/pages/redactpost/components/BasePostPreviewPopup.tsx:137
msgid "Copy"
msgstr "Copier"

#: src/components/molecules/PricingCard.tsx:28
#: src/components/molecules/UpgradePricingCard.tsx:34
msgid "Copy writing style from any content creator"
msgstr "Ecris comme les meilleurs créateurs de contenu"

#: src/components/molecules/PricingCard.tsx:96
#: src/components/molecules/UpgradePricingCard.tsx:156
msgid "copywriters, agencies or"
msgstr "les copywriters, agences ou"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:114
#~ msgid "Cost: 1 credit"
#~ msgstr "Coût : 1 crédit"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:326
#~ msgid "Cost: 3 credits"
#~ msgstr "Coût : 3 crédits"

#: src/components/atoms/PricingSlider.tsx:28
msgid "Create {0} posts per month"
msgstr "Crée {0} posts par mois"

#: src/components/pages/Pricing/components/PricingTable.tsx:148
#~ msgid "Create {0} posts per week"
#~ msgstr "{0} posts par semaine"

#: src/components/atoms/PricingSlider.tsx:24
#~ msgid "Create {value} posts per month"
#~ msgstr "Créez {value} posts par mois"

#: src/components/pages/Pricing/components/PricingTable.tsx:116
#~ msgid "Create ≈ {0}+ posts or {1}+ posts ideas each month."
#~ msgstr "Environ {0} posts ou {1} idées de posts chaque mois."

#: src/components/atoms/PricingSlider.tsx:49
msgid "Create 8 posts per month"
msgstr "Créez 8 posts par mois"

#: src/components/templates/Page.tsx:46
#: src/pages/redactpost/RedactPost.tsx:199
msgid "Create a post"
msgstr "Créer un post"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:27
msgid "Create an audience"
msgstr "Créer une audience"

#: src/components/pages/RedactPost/RedactPost.tsx:44
#~ msgid "Create your LinkedIn post in 30 seconds"
#~ msgstr "Ecris ton post LinkedIn en 30 secondes"

#: src/components/pages/RedactPost/RedactPost.tsx:13
#~ msgid "Create your LinkedIn post in 30 seconds 😎"
#~ msgstr "Ecris ton post LinkedIn en 30 secondes 😎"

#: src/components/organisms/PricingTable.tsx:111
msgid "Create your LinkedIn posts in 30 seconds."
msgstr "Créez vos posts LinkedIn en 30 secondes."

#: src/components/pages/Pricing/components/PricingTable.tsx:128
#~ msgid "Create your LinkedIn posts in 30 seconds. You can cancel anytime."
#~ msgstr "Rédige tes posts LinkedIn en 30 secondes. Tu peux annuler à tout moment."

#: src/components/organisms/SavedIdea/SavedIdeaCard.tsx:23
#: src/components/organisms/SavedPost/SavedPostCard.tsx:45
#~ msgid "Created at"
#~ msgstr "Créé le"

#: src/components/molecules/SavedIdea.tsx:26
#: src/components/molecules/SavedPost.tsx:42
msgid "Created at {0}"
msgstr "Créé le {0}"

#: src/components/molecules/UpgradePricingCard.tsx:112
msgid "Creator"
msgstr "Créateur"

#: src/components/organisms/SideBar/SideBar.tsx:111
#~ msgid "Credits left"
#~ msgstr "Crédits restants"

#: src/components/pages/Settings/Settings.tsx:142
#~ msgid "credits per month"
#~ msgstr "crédits par mois"

#: src/components/pages/Settings/Settings.tsx:145
#~ msgid "Credits renewal on"
#~ msgstr "Renouvellement des crédits le"

#: src/components/molecules/CurrentSubscription.tsx:48
#: src/components/molecules/CurrentSubscriptionFree.tsx:14
msgid "Current plan"
msgstr "Abonnement actuel"

#: src/components/molecules/affiliateEarnings.tsx:17
msgid "Currently, you earn"
msgstr "Actuellement, vous gagnez"

#: src/components/pages/Pricing/components/PricingTable.tsx:225
#~ msgid "Custom"
#~ msgstr "Personnalisé"

#: src/components/pages/Pricing/components/PricingTable.tsx:227
#~ msgid "Custom IA solution"
#~ msgstr "Solution IA personnalisée"

#: src/components/pages/Pricing/components/PricingTable.tsx:165
#~ msgid "Custom pricing"
#~ msgstr "Devis personnalisé"

#: src/components/pages/Pricing/components/PricingTable.tsx:234
#~ msgid "Customize your own IA"
#~ msgstr "Personnalise ta propre IA"

#: src/components/templates/Page.tsx:19
#~ msgid "Dashboard"
#~ msgstr "Dashboard"

#: src/components/molecules/affiliateHistory.tsx:36
#: src/components/molecules/InvoiceTable.tsx:23
msgid "Date"
msgstr "Date"

#: src/components/molecules/PricingCard.tsx:48
#: src/components/molecules/UpgradePricingCard.tsx:54
msgid "Dedicated customer support"
msgstr "Support client dédié"

#: src/components/molecules/Popup/ConfirmationPopup.tsx:35
#: src/components/organisms/SavedIdea/SavedIdeaCard.tsx:44
#: src/components/organisms/SavedPost/SavedPostCard.tsx:106
#~ msgid "Delete"
#~ msgstr "Supprimer"

#: src/components/molecules/ProfileHeader.tsx:62
#: src/components/molecules/ProfileHeaderMobile.tsx:63
msgid "Delete account"
msgstr "Supprimer le compte"

#: src/components/molecules/popups/DeleteAccountPopupConfirmation.tsx:45
#: src/components/molecules/popups/DeleteIdeaPopup.tsx:51
msgid "Delete Now"
msgstr "Supprimer maintenant"

#: src/components/pages/Pricing/components/PricingTable.tsx:170
#~ msgid "Demo"
#~ msgstr "Démo"

#: src/components/molecules/popups/DescribeReason.tsx:25
msgid "Describe the reason"
msgstr "Décrivez la raison"

#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:56
#~ msgid "Desktop"
#~ msgstr "Ordinateur"

#: src/components/organisms/LinkedInNewPostPopup/LinkedInNewPostPopup.tsx:38
#~ msgid "Discard post"
#~ msgstr "Supprimer le post"

#: src/components/organisms/LoginForms/EmailVerification.tsx:72
msgid "Disconnect"
msgstr "Se déconnecter"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmed.tsx:25
#: src/components/molecules/popups/DeleteAccountPopupConfirmed.tsx:28
msgid "Dismiss"
msgstr "Rejeter"

#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:82
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:169
#: src/pages/onboarding/components/ProfileUrlStyle.tsx:138
msgid "Do you want suggestions on ideas?"
msgstr "Voulez-vous des suggestions d'idées?"

#: src/components/organisms/LoginForms/LoginForm.tsx:144
msgid "Don't have an account?"
msgstr "Vous n'avez pas de compte ?"

#: src/components/molecules/InvoiceTable.tsx:39
msgid "Download Invoice"
msgstr "Télécharger la facture"

#: src/pages/redactpost/components/LoadingLayout.tsx:28
#: src/pages/redactpost/components/LoadingLayout.tsx:48
msgid "Downloading the content"
msgstr "Téléchargement du contenu"

#: src/components/pages/RedactPost/components/Loader.tsx:25
#~ msgid "Downloading the style"
#~ msgstr "Récupération du style"

#: src/components/molecules/ProfileInformationCard.tsx:52
msgid "Each brings a unique perspective and set of skills to the table, ensuring the success of marketing campaigns and content strategies."
msgstr "Chacun apporte une perspective unique et un ensemble de compétences à la table, assurant le succès des campagnes marketing et des stratégies de contenu."

#: src/components/molecules/CurrentSubscription.tsx:56
#: src/components/molecules/CurrentSubscription.tsx:76
msgid "Edit plan"
msgstr "Modifier l'abonnement"

#: src/components/molecules/affiliateHistory.tsx:38
#: src/components/organisms/ReferralSourceForm.tsx:52
msgid "Email"
msgstr "Email"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:104
msgid "Email Verification Failed"
msgstr "La vérification de l'email a échoué"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:91
msgid "Email Verification Successful"
msgstr "Vérification de l'email réussie"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:56
#~ msgid "Email verified!"
#~ msgstr "Email vérifié!"

#: src/components/organisms/LoginForms/LoginForm.tsx:105
#: src/components/organisms/LoginForms/ResetPasswordForm.tsx:25
#: src/components/organisms/LoginForms/SignUpForm.tsx:164
msgid "Enter email"
msgstr "Entrez votre e-mail"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:144
msgid "Enter new password"
msgstr "Entrez le nouveau mot de passe"

#: src/components/organisms/LoginForms/LoginForm.tsx:117
#: src/components/organisms/LoginForms/SignUpForm.tsx:176
msgid "Enter password"
msgstr "Entrez le mot de passe"

#: src/components/organisms/LoginForms/LoginForm.tsx:12
#~ msgid "Enter your email"
#~ msgstr "Entrez votre e-mail"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:15
msgid "Enter your new password"
msgstr "Entrez votre nouveau mot de passe"

#: src/components/organisms/LoginForms/LoginForm.tsx:16
#~ msgid "Enter your password"
#~ msgstr "Entrez votre mot de passe"

#: src/components/organisms/LinkedInPostsCalendar/LinkedInPostsCalendar.tsx:63
#~ msgid "Error while deleting post"
#~ msgstr "Erreur lors de la suppression du post"

#: src/components/organisms/LinkedInPostsCalendar/LinkedInPostsCalendar.tsx:83
#~ msgid "Error while scheduling post"
#~ msgstr "Erreur lors de la planification du post"

#: src/components/organisms/ReferralSourceForm.tsx:125
#~ msgid "Error!"
#~ msgstr "Erreur!"

#: src/components/molecules/UpgradePricingCard.tsx:75
msgid "Essential"
msgstr "Essentiel"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:28
#: src/components/pages/Onboarding/components/ChooseStyleSource.tsx:22
#~ msgid "Estimated time:"
#~ msgstr "Temps estimé :"

#: src/hooks/tutorial/useTutorialSteps.tsx:98
#~ msgid "Everybody is using LinkedIn nowadays"
#~ msgstr "Tout le monde utilise LinkedIn de nos jours"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:58
msgid "Everybody is using LinkedIn nowadays-Students-Professional"
msgstr "Tout le monde utilise LinkedIn de nos jours-Étudiants-Professionnels"

#: src/hooks/tutorial/useTutorialSteps.tsx:40
#: src/hooks/tutorial/useTutorialSteps.tsx:58
#: src/hooks/tutorial/useTutorialSteps.tsx:73
#: src/hooks/tutorial/useTutorialSteps.tsx:91
#: src/hooks/tutorial/useTutorialSteps.tsx:131
#~ msgid "Example :"
#~ msgstr "Exemple :"

#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:104
#: src/pages/onboarding/components/ToolTipFinalStep.tsx:26
#: src/pages/onboarding/components/ToolTipFinalStep.tsx:37
#: src/pages/onboarding/components/ToolTipFinalStep.tsx:56
#: src/pages/onboarding/components/ToolTipFirstStep.tsx:20
msgid "Example:"
msgstr "Exemple :"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:114
#~ msgid "Example: \"Digital marketing\" or \"How to write a newsletter\""
#~ msgstr "Exemple : \"Marketing digital\" ou \"Comment écrire une newsletter\""

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:286
#~ msgid "Example: 10 reasons why you should use street marketing"
#~ msgstr "Exemple : 10 raisons d'utiliser le street marketing"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:47
#~ msgid "Example: advantages of street marketing"
#~ msgstr "Exemple : avantages du street marketing"

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:72
#~ msgid "Example: The story of elon musk"
#~ msgstr "Exemple : L'histoire d'Elon Musk"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:38
#~ msgid "Example: The story of Elon Musk"
#~ msgstr "Exemple : L'histoire d'Elon Musk"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:39
#~ msgid "Example: Why do you need to post on LinkedIn ?"
#~ msgstr "Exemple : Pourquoi poster sur LinkedIn ?"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:21
msgid "Example: Why do you need to post on Linkedin?"
msgstr "Exemple : Pourquoi poster sur LinkedIn ?"

#: src/hooks/tutorial/useTutorialSteps.tsx:15
#~ msgid "Examples :"
#~ msgstr "Exemples :"

#: src/components/molecules/UpgradePricingCard.tsx:129
msgid "excluding taxes"
msgstr "hors taxes"

#: src/components/molecules/PricingCard.tsx:75
#: src/components/molecules/UpgradePricingCard.tsx:88
msgid "excluding taxes | Cancel anytime"
msgstr "Hors taxes | Annulation à tout moment"

#: src/components/organisms/LoginForms/EmailVerification.tsx:47
msgid "Failed to resend verification email. Please try again later."
msgstr "Échec de la renvoyer l'email de vérification. Veuillez réessayer plus tard."

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:84
msgid "Failed to reset password. Please try again."
msgstr "Échec de la réinitialisation du mot de passe. Veuillez réessayer."

#: src/hooks/resetPassword/useResetPassword.ts:34
msgid "Failed to send reset email. Please try again."
msgstr "Échec de l'envoi de l'email de réinitialisation. Veuillez réessayer."

#: src/components/molecules/FilterSelectUsedNotUsed.tsx:39
msgid "Filter By"
msgstr "Filtrer par"

#: src/pages/redactpost/components/LoadingLayout.tsx:31
#: src/pages/redactpost/components/LoadingLayout.tsx:56
msgid "Final steps"
msgstr "Dernières étapes"

#: src/components/templates/Page.tsx:45
#: src/pages/findIdea/components/MyIdeasTab.tsx:75
#: src/pages/findIdea/FindIdea.tsx:93
#: src/pages/findIdea/FindIdea.tsx:114
msgid "Find an idea"
msgstr "Trouver une idée"

#: src/pages/redactpost/RedactPost.tsx:203
msgid "Find an idea here →"
msgstr "Trouve une idée ici →"

#: src/pages/findIdea/FindIdea.tsx:158
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:194
msgid "Find my ideas"
msgstr "Trouver mon idée"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:35
msgid "Find new clients"
msgstr "Trouver de nouveaux clients"

#: src/components/molecules/PricingCard.tsx:40
#: src/components/molecules/UpgradePricingCard.tsx:46
msgid "Find original and up-to-date post ideas"
msgstr "Trouve des idées de posts originales et à jour"

#: src/components/pages/FindIdea/FindIdea.tsx:13
#~ msgid "Find your LinkedIn post idea in a few words"
#~ msgstr "Trouve ton idée de post LinkedIn en quelques mots"

#: src/components/organisms/ChatWithRedactAI.tsx:49
msgid "Finish tutorial"
msgstr "Finir le tutoriel"

#: src/components/organisms/LoginForms/SignUpForm.tsx:187
#: src/components/organisms/LoginForms/SignUpForm.tsx:188
msgid "First name"
msgstr "Prénom"

#: src/components/molecules/SubscriptionInformationCard.tsx:83
#~ msgid "for"
#~ msgstr "pendant"

#: src/components/molecules/PricingCard.tsx:94
#: src/components/molecules/UpgradePricingCard.tsx:154
msgid "For"
msgstr "Pour"

#: src/components/pages/Pricing/components/PricingTable.tsx:232
#~ msgid "For copywriters, agencies or companies"
#~ msgstr "Pour les copywriters, agences ou entreprises"

#: src/components/molecules/SubscriptionInformationCard.tsx:86
msgid "for free"
msgstr "gratuitement"

#: src/components/molecules/affiliateConditions.tsx:10
msgid "For me"
msgstr "Pour moi"

#: src/components/molecules/affiliateConditions.tsx:15
msgid "For my referral"
msgstr "Pour mon parrainage"

#: src/components/organisms/LoginForms/LoginForm.tsx:129
msgid "Forgot password?"
msgstr "Mot de passe oublié ?"

#: src/hooks/usePostToneOptions.ts:29
msgid "Formal / Serious"
msgstr "Formel / Sérieux"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:22
#: src/pages/onboarding/components/PostStyleSelect.tsx:20
#~ msgid "Formal/Serious"
#~ msgstr "Formel / Sérieux"

#: src/components/molecules/CurrentSubscriptionFree.tsx:15
msgid "Free"
msgstr "Gratuit"

#: src/components/molecules/PricingCard.tsx:64
msgid "Free Trial for 7 days"
msgstr "7 jours d'essai gratuit"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:51
#~ msgid "From an url"
#~ msgstr "Depuis une url"

#: src/components/molecules/ChooseContentSourceCards/FromUrl.tsx:19
#: src/pages/redactpost/components/RedactPostContent.tsx:60
msgid "From an URL"
msgstr "Depuis une url"

#: src/pages/onboarding/components/ProfileUrlStyle.tsx:98
msgid "From LinkedIn profile url"
msgstr "A partir d'un profil LinkedIn"

#: src/pages/redactpost/components/RedactPostStyle.tsx:74
msgid "From LinkedIn profile URL"
msgstr "A partir d'un profil LinkedIn"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:29
#: src/pages/onboarding/components/ChooseStyleSource.tsx:106
msgid "From our list of style"
msgstr "Depuis notre liste de styles"

#: src/pages/onboarding/components/PostStyle.tsx:48
#: src/pages/redactpost/components/RedactPostStyle.tsx:80
msgid "From post type"
msgstr "A partir d'un type de post"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:300
#~ msgid "From tone"
#~ msgstr "A partir d'un ton"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:360
#~ msgid "From tone, pronoun and length"
#~ msgstr "A partir d'un ton, pronom et longueur"

#: src/pages/findIdea/FindIdea.tsx:97
msgid "Generate a post"
msgstr "Générer un post"

#: src/components/pages/RedactPost/RedactPost.tsx:50
#~ msgid "Generate a post V2"
#~ msgstr "Générer un post V2"

#: src/components/molecules/PricingCard.tsx:32
#: src/components/molecules/UpgradePricingCard.tsx:38
msgid "Generate all your posts in minutes"
msgstr "Génère tous tes posts en quelques minutes"

#: src/components/molecules/GeneratedIdea.tsx:54
#: src/components/molecules/SavedIdea.tsx:51
msgid "Generate Post"
msgstr "Générer un post"

#: src/components/organisms/SideBar/SideBar.tsx:134
#~ msgid "Generated ideas"
#~ msgstr "Idées générées"

#: src/components/organisms/SideBar/SideBar.tsx:127
#~ msgid "Generated posts"
#~ msgstr "Posts générés"

#: src/pages/onboarding/components/PostGeneration.tsx:46
#: src/pages/redactpost/components/RedactPostGeneration.tsx:41
msgid "Generating posts..."
msgstr "Génération du post..."

#: src/components/pages/RedactPost/components/Loader.tsx:27
#~ msgid "Generating the post"
#~ msgstr "Génération du post"

#: src/components/pages/Pricing/components/PricingTable.tsx:139
#~ msgid "Get 2 free months"
#~ msgstr "Obtiens 2 mois gratuits"

#: src/components/pages/Pricing/components/PricingTable.tsx:174
#~ msgid "Get a 15 minutes tour of our product"
#~ msgstr "Découvre notre produit en 15 minutes"

#: src/components/molecules/SubscriptionInformationCard.tsx:126
#: src/components/molecules/SubscriptionInformationCard.tsx:156
msgid "Get more posts"
msgstr "Plus de posts"

#: src/components/pages/Pricing/components/PricingTable.tsx:124
#~ msgid "Get started"
#~ msgstr "Démarrer"

#: src/components/pages/Pricing/components/PricingTable.tsx:126
#~ msgid "Get started for Free"
#~ msgstr "Démarrer gratuitement"

#: src/components/pages/Pricing/components/PricingTable.tsx:186
#~ msgid "Get started for Free*"
#~ msgstr "Démarrer gratuitement*"

#: src/pages/onboarding/components/ChooseStyleSource.tsx:114
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:187
#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:69
#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:88
msgid "Go back"
msgstr "Retour"

#: src/pages/onboarding/Onboarding.tsx:184
msgid "Good to have you,"
msgstr "Content de t'avoir,"

#: src/components/organisms/ReferralSourceForm.tsx:54
msgid "Google search"
msgstr "Recherche Google"

#: src/components/organisms/PricingTable.tsx:37
#: src/components/organisms/PricingTable.tsx:55
#~ msgid "Great!"
#~ msgstr "Super !"

#: src/components/pages/Dashboard/Dashboard.tsx:10
#~ msgid "Hello {0} 👋"
#~ msgstr "Salut {0} 👋"

#: src/components/pages/Dashboard/Dashboard.tsx:8
#~ msgid "Hello {Yohan} 👋"
#~ msgstr "Salut {Yohan} 👋"

#: src/components/organisms/NavigationBar.tsx:41
#: src/components/organisms/NavigationBarMobile.tsx:56
#~ msgid "Help & support"
#~ msgstr "Aide & soutien"

#: src/components/organisms/PostEditor/PostEditor.tsx:90
#~ msgid "Here will appear your generated post"
#~ msgstr "Ici apparaîtra ton post généré"

#: src/pages/redactpost/components/BasePostPreviewPopup.tsx:80
msgid "Here will appear your generated post.."
msgstr "Ici apparaîtra ton post généré.."

#: src/components/organisms/SavedPost/SavedPostCard.tsx:51
#~ msgid "Hide"
#~ msgstr "Cacher"

#: src/components/molecules/PricingCard.tsx:24
#: src/components/molecules/UpgradePricingCard.tsx:30
msgid "High quality post generation"
msgstr "Posts de qualité professionnelle"

#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:91
msgid "Hiring"
msgstr "Recrutement"

#: src/pages/findIdea/components/MyIdeasTab.tsx:81
#: src/pages/findIdea/FindIdea.tsx:120
#: src/pages/myPosts/MyPosts.tsx:85
msgid "History"
msgstr "Historique"

#: src/components/pages/FindIdea/FindIdea.tsx:20
#~ msgid "History ({0} ideas)"
#~ msgstr "Historique ({0} idées)"

#: src/components/pages/RedactPost/RedactPost.tsx:47
#~ msgid "History ({0} posts)"
#~ msgstr "Historique ({0} posts)"

#: src/components/molecules/OnboardingReferralSource/OnboardingReferralSource.tsx:72
#~ msgid "How did you know RedactAI ?"
#~ msgstr "Comment as-tu connu RedactAI ?"

#: src/components/molecules/OnboardingReferralSource/OnboardingReferralSource.tsx:72
#~ msgid "How did you know us ?"
#~ msgstr "Comment nous as-tu connu ?"

#: src/components/organisms/ReferralSourceForm.tsx:94
msgid "How did you know us?"
msgstr "Comment nous as-tu connu ?"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:16
#~ msgid "How to create an audience ?"
#~ msgstr "Comment créer une audience ?"

#: src/hooks/tutorial/useTutorialSteps.tsx:135
#~ msgid "https://www.linkedin.com/in/benoitdubos/"
#~ msgstr "https://www.linkedin.com/in/benoitdubos/"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:26
#~ msgid "Humble"
#~ msgstr "Modeste"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:16
#~ msgid "Humorous"
#~ msgstr "Humoristique"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:54
#~ msgid "I"
#~ msgstr "Je"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:49
msgid "I did not receive adequate assistance when I had a problem."
msgstr "Je n'ai pas reçu une aide adéquate lorsque j'ai eu un problème."

#: src/components/molecules/ChooseContentSourceCards/NoIdeas.tsx:15
msgid "I don't have ideas"
msgstr "Je n'ai pas d'idées"

#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:141
msgid "I Don't have ideas"
msgstr "Je n'ai pas d'idées"

#: src/components/organisms/LoginForms/SignUpForm.tsx:219
msgid "I have read and accept the"
msgstr "J'ai lu et j'accepte les"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:36
msgid "I have to cancel it"
msgstr "je dois l'annuler"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:53
msgid "I only needed the product for a short-term project"
msgstr "Je n'avais besoin du produit que pour un projet à court terme"

#: src/components/molecules/GeneratedPost.tsx:89
#: src/components/molecules/SavedPost.tsx:51
#: src/pages/redactpost/components/BasePostPreviewPopup.tsx:105
msgid "I use RedactAI because I'm smart"
msgstr "J'utilise RedactAI car je suis smart"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:41
msgid "I'm not using the product as much as I thought I would."
msgstr "Je n'utilise pas le produit autant que je le pensais."

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:25
msgid "IA model"
msgstr "Modèle IA"

#: src/components/pages/Settings/Settings.tsx:91
#~ msgid "idea generation per month"
#~ msgstr "générations d'idées par mois"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:24
msgid "Ideas"
msgstr "Idées"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:42
#~ msgid "Ideas:"
#~ msgstr "Idées :"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:25
msgid "If you are doing it, you are messing one of us and that's....unacceptable!"
msgstr "Si vous le faites, vous dérangez l'un de nous et c'est... inacceptable !"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:330
#~ msgid "In which style ?"
#~ msgstr "Dans quel style ?"

#: src/components/molecules/affiliateHistory.tsx:26
msgid "Inactive"
msgstr "Inactif"

#: src/hooks/usePostToneOptions.ts:9
msgid "Informal / Cool"
msgstr "Informel / Cool"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:17
#: src/pages/onboarding/components/PostStyleSelect.tsx:15
#~ msgid "Informal/Cool"
#~ msgstr "Informel / Cool"

#: src/pages/redactpost/components/LoadingLayout.tsx:44
msgid "Initializing the IA model"
msgstr "Initialisation du modèle IA"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:22
#~ msgid "Inspirational"
#~ msgstr "Inspirant"

#: src/pages/login/LoginPage.tsx:53
msgid "Introducing"
msgstr "Découvre"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:18
msgid "Invalid password reset link."
msgstr "Lien de réinitialisation du mot de passe invalide."

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:16
msgid "Invalid verification link."
msgstr "Lien de vérification invalide."

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:20
#~ msgid "Ironic"
#~ msgstr "Ironique"

#: src/pages/onboarding/components/ChooseContentSource.tsx:32
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:132
msgid "It will only take 2 mins"
msgstr "Cela ne prendra que 2 minutes"

#: src/components/molecules/ProfileInformationCard.tsx:49
msgid "It's essential to understand the roles and responsibilities of the key players"
msgstr "Il est essentiel de comprendre les rôles et les responsabilités des principaux acteurs."

#: src/components/molecules/ProfileInformationCard.tsx:35
msgid "Join affiliate program"
msgstr "Rejoignez notre programme d'affiliation."

#: src/components/molecules/GeneratedIdea.tsx:31
#: src/components/molecules/SavedIdea.tsx:35
msgid "Key ideas"
msgstr "Idées clées"

#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:100
msgid "Key Ideas"
msgstr "Idées clées"

#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:102
msgid "Key ideas."
msgstr "Idées clées."

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:87
#: src/components/pages/RedactPost/components/RedactPostForm.tsx:284
#~ msgid "Last"
#~ msgstr "Dernier"

#: src/pages/recyclepost/RecyclePost.tsx:54
msgid "Last 14 days"
msgstr "Les 14 derniers jours"

#: src/pages/recyclepost/RecyclePost.tsx:58
msgid "Last 28 days"
msgstr "Les 28 derniers jours"

#: src/pages/recyclepost/RecyclePost.tsx:66
msgid "Last 365 days"
msgstr "Les 365 derniers jours"

#: src/pages/recyclepost/RecyclePost.tsx:50
msgid "Last 7 days"
msgstr "Les 7 derniers jours"

#: src/pages/recyclepost/RecyclePost.tsx:62
msgid "Last 90 days"
msgstr "Les 90 derniers jours"

#: src/components/organisms/LoginForms/SignUpForm.tsx:198
#: src/components/organisms/LoginForms/SignUpForm.tsx:199
msgid "Last name"
msgstr "Nom"

#: src/components/molecules/PricingCard.tsx:92
#: src/components/molecules/UpgradePricingCard.tsx:152
msgid "Learn about RedactAI"
msgstr "En savoir plus sur RedactAI"

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:63
#~ msgid "Learn how to generate the content"
#~ msgstr "Apprenez à générer le contenu"

#: src/components/organisms/SideBar/SideBar.tsx:36
#~ msgid "Left"
#~ msgstr "Restant"

#: src/components/pages/Pricing/components/PricingTable.tsx:60
#: src/components/pages/Pricing/components/PricingTable.tsx:78
#~ msgid "Legend"
#~ msgstr "Légende"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:373
#~ msgid "Length"
#~ msgstr "Longueur"

#: src/components/organisms/SavedPost/SavedPostCard.tsx:87
#~ msgid "Length:"
#~ msgstr "Longueur :"

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:95
msgid "Let's choose a writing style"
msgstr "Choisissons un style d'écriture"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:23
#~ msgid "Let's create your first post"
#~ msgstr "Créons ton premier post"

#: src/pages/onboarding/components/ChooseContentSource.tsx:31
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:131
msgid "Let's do this,"
msgstr "Faisons cela,"

#: src/components/pages/Onboarding/Onboarding.tsx:133
#~ msgid "Let's find an idea"
#~ msgstr "Trouvons une idée"

#: src/components/molecules/OnboardingReferralSource/OnboardingReferralSource.tsx:86
#~ msgid "Let's go"
#~ msgstr "C'est parti"

#: src/components/organisms/OnboardingPopup/OnboardingPopup.tsx:55
#~ msgid "Let's go !"
#~ msgstr "C'est parti !"

#: src/components/pages/Pricing/components/PricingTable.tsx:227
#~ msgid "Let's talk"
#~ msgstr "Nous rencontrer"

#: src/components/molecules/PricingCard.tsx:91
#: src/components/molecules/UpgradePricingCard.tsx:151
msgid "Let's Talk"
msgstr "Nous rencontrer"

#: src/components/molecules/ChooseContentSourceCards/NoIdeas.tsx:16
msgid "Let's use our idea generator"
msgstr "Utilisons notre générateur d'idées"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:74
#~ msgid "Let's use our idea generator 😉"
#~ msgstr "Utilisons notre générateur d'idées 😉"

#: src/components/organisms/ReferralSourceForm.tsx:42
msgid "LinkedIn message"
msgstr "Message LinkedIn"

#: src/components/organisms/ReferralSourceForm.tsx:44
msgid "LinkedIn post"
msgstr "Post LinkedIn"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:103
#~ msgid "LinkedIn post scheduling is temporarily disabled"
#~ msgstr "La planification de post LinkedIn est temporairement désactivée"

#: src/pages/recyclepost/RecyclePost.tsx:240
msgid "LinkedIn profile url"
msgstr "Url du profil LinkedIn"

#: src/components/organisms/ReferralSourceForm.tsx:46
msgid "LinkedIn training"
msgstr "Formation LinkedIn"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:122
#: src/components/molecules/popups/DescribeReason.tsx:63
#~ msgid "Loading..."
#~ msgstr "Chargement..."

#: src/components/organisms/LoginForms/LoginForm.tsx:19
#~ msgid "Log in"
#~ msgstr "Se connecter"

#: src/components/organisms/LoginForms/LoginForm.tsx:11
#~ msgid "Log in to explore"
#~ msgstr "Connectez-vous pour explorer"

#: src/components/organisms/LoginForms/LoginForm.tsx:21
#~ msgid "Log in via Google"
#~ msgstr "Connectez-vous pour explorer"

#: src/components/organisms/LoginForms/LoginForm.tsx:102
#: src/components/organisms/LoginForms/LoginForm.tsx:131
msgid "Login"
msgstr "Connexion"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:112
#~ msgid "Login code required"
#~ msgstr "Code de connexion requis"

#: src/components/organisms/LoginForms/LoginForm.tsx:84
msgid "Login successful!"
msgstr "Connexion réussie!"

#: src/components/organisms/LoginForms/LoginForm.tsx:137
msgid "Login via Google"
msgstr "Connexion via Google"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:8
#~ msgid "Long"
#~ msgstr "Long"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:108
msgid "Make it longer"
msgstr "Rend le post plus long"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:109
msgid "Make it shorter"
msgstr "Rend le post plus court"

#: src/components/pages/RedactPost/RedactPost.tsx:230
#~ msgid "Make your post unique !"
#~ msgstr "Rends ton post unique !"

#: src/components/pages/Settings/Settings.tsx:153
#~ msgid "Manage subscription"
#~ msgstr "Gérer mon abonnement"

#: src/components/molecules/SavedIdea.tsx:45
#: src/components/molecules/SavedPost.tsx:100
msgid "Mark as not used"
msgstr "Marquer comme non utilisé"

#: src/components/molecules/SavedIdea.tsx:45
#: src/components/molecules/SavedPost.tsx:100
msgid "Mark as used"
msgstr "Marquer comme utilisé"

#: src/hooks/tutorial/useTutorialSteps.tsx:19
#~ msgid "Marketing"
#~ msgstr "Marketing"

#: src/hooks/tutorial/useTutorialSteps.tsx:22
#~ msgid "Marketing Digital"
#~ msgstr "Marketing Digital"

#: src/components/pages/Pricing/components/PricingTable.tsx:156
#: src/components/pages/Pricing/components/PricingTable.tsx:198
#~ msgid "mo"
#~ msgstr "mois"

#: src/pages/redactpost/components/BasePostPreviewPopup.tsx:93
msgid "Mobile"
msgstr "Mobile"

#: src/components/molecules/PricingCard.tsx:72
#: src/components/molecules/UpgradePricingCard.tsx:85
#: src/components/molecules/UpgradePricingCard.tsx:126
msgid "monthly"
msgstr "mensuellement"

#: src/components/molecules/CurrentSubscription.tsx:50
msgid "Monthly"
msgstr "Mensuel"

#: src/pages/recyclepost/RecyclePost.tsx:83
msgid "More comments"
msgstr "Plus de commentaires"

#: src/components/organisms/SideBar/SideBar.tsx:115
#~ msgid "More credits"
#~ msgstr "Plus de crédits"

#: src/components/organisms/SideBar/SideBar.tsx:145
#~ msgid "More posts"
#~ msgstr "Plus de posts"

#: src/pages/recyclepost/RecyclePost.tsx:79
msgid "More reactions"
msgstr "Plus de réactions"

#: src/components/pages/Pricing/components/PricingTable.tsx:145
#~ msgid "Most Popular"
#~ msgstr "Le plus populaire"

#: src/components/pages/FindIdea/components/MyIdeasTab.tsx:51
#: src/components/pages/RedactPost/components/HistoryTab.tsx:64
#~ msgid "Most recent"
#~ msgstr "Plus récent"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:28
#~ msgid "Motivating"
#~ msgstr "Motivant"

#: src/components/molecules/PricingCard.tsx:44
#: src/components/molecules/UpgradePricingCard.tsx:50
msgid "Multilingual support"
msgstr "Posts multi-langues"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:16
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:46
#: src/pages/redactpost/components/RedactPostContent.tsx:54
msgid "My own ideas"
msgstr "Mes propres idées"

#: src/components/templates/Page.tsx:53
msgid "My posts"
msgstr "Mes posts"

#: src/components/molecules/affiliateHistory.tsx:37
msgid "Name"
msgstr "Nom"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:98
#: src/components/pages/RedactPost/components/RedactPostForm.tsx:301
#~ msgid "Need help ? See an explanatory video"
#~ msgstr "Besoin d'aide ? Regarde une vidéo explicative"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:82
#~ msgid "Neuromarketing"
#~ msgstr "Neuromarketing"

#: src/components/atoms/NewLabel.tsx:6
#~ msgid "New"
#~ msgstr "New"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:143
msgid "New Password"
msgstr "Nouveau mot de passe"

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:37
msgid "New post"
msgstr "Nouveau post"

#: src/components/organisms/ReferralSourceForm.tsx:50
msgid "Newsletter"
msgstr "Newsletter"

#: src/pages/onboarding/components/ChooseStyleSource.tsx:129
#: src/pages/onboarding/components/ToolTipFirstStep.tsx:32
msgid "Next"
msgstr "Suivant"

#: src/components/molecules/InvoiceTable.tsx:22
msgid "No"
msgstr "No"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:132
#~ msgid "No Email ? Click here to resend the email"
#~ msgstr "Tu n'as pas reçu d'email ? Clique ici pour le renvoyer"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:116
#: src/components/pages/RedactPost/components/RedactPostContent.tsx:49
#~ msgid "No idea ? Choose from these ones:"
#~ msgstr "Pas d'idée ? Choisis parmi celles-ci :"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:44
#~ msgid "No idea ? Find an idea here"
#~ msgstr "Pas d'idée ? Trouve une idée ici"

#: src/components/pages/RedactPost/components/RedactPostStyle.tsx:64
#~ msgid "No idea ? Here are some suggestions:"
#~ msgstr "Pas d'idée ? Voici quelques suggestions :"

#: src/pages/redactpost/RedactPost.tsx:201
msgid "No idea?"
msgstr "Pas d'idée ?"

#: src/hooks/findIdeas/useFindIdeas.ts:36
#~ msgid "Not enough credits"
#~ msgstr "Pas assez de crédits"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:216
msgid "Not satisfied with the result ? Tell RedactAI what to change you can leave empty to regenerate new variations"
msgstr "Vous n'êtes pas satisfait du résultat ? Dites à RedactAI quoi changer, vous pouvez laisser vide pour régénérer de nouvelles variantes"

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:204
#~ msgid "Not satisfied with the result? Tell RedactAI what to change. You can leave it empty to regenerate new variations."
#~ msgstr "Vous n'êtes pas satisfait du résultat ? Dites à RedactAI ce qu'il faut changer. Vous pouvez laisser vide pour régénérer de nouvelles variations."

#: src/components/organisms/SavedIdea/SavedIdeaCard.tsx:26
#: src/components/organisms/SavedPost/SavedPostCard.tsx:48
#: src/components/pages/FindIdea/components/MyIdeasTab.tsx:47
#: src/components/pages/RedactPost/components/HistoryTab.tsx:60
#~ msgid "Not used"
#~ msgstr "Non utilisé"

#: src/components/molecules/FilterSelectUsedNotUsed.tsx:24
#: src/pages/findIdea/components/MyIdeasTab.tsx:45
#: src/pages/myPosts/MyPosts.tsx:55
msgid "Not Used"
msgstr "Non utilisé"

#: src/pages/redactpost/components/RedactPostPreview.tsx:25
#: src/utils/formatCreatedAt.ts:8
msgid "Now"
msgstr "Maintenant"

#: src/components/molecules/SubscriptionInformationCard.tsx:87
#~ msgid "of cost"
#~ msgstr "de coût"

#: src/common/snackbar/SnackbarProvider.tsx:23
#~ msgid "Ok, I understand"
#~ msgstr "Ok, j'ai compris"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:73
msgid "Okay, I got it"
msgstr "Ok, j'ai compris"

#: src/components/pages/FindIdea/components/MyIdeasTab.tsx:52
#: src/components/pages/RedactPost/components/HistoryTab.tsx:65
#~ msgid "Oldest"
#~ msgstr "Plus ancien"

#: src/hooks/tutorial/useTutorialSteps.tsx:10
#~ msgid "One or multiple words representing a domain or a work area. RedactAI will give you 3 different ideas to choose from."
#~ msgstr "Un ou plusieurs mots représentant un domaine ou un secteur d'activité. RedactAI te donnera 3 idées différentes à choisir."

#: src/pages/onboarding/Onboarding.tsx:186
msgid "Only one question before crafting your first post!"
msgstr "Seulement une question avant de créer ton premier post!"

#: src/components/pages/Onboarding/Onboarding.tsx:109
#~ msgid "Only one question before creating your first post."
#~ msgstr "Seulement une question avant de créer ton premier post."

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:41
#: src/utils/handleGoogleSignInUp.ts:25
msgid "Oops! Something went wrong with Google sign-in."
msgstr "Oups! Quelque chose s'est mal passé avec la connexion Google."

#: src/components/organisms/LoginForms/LoginForm.tsx:94
#: src/components/organisms/LoginForms/SignUpForm.tsx:141
#: src/hooks/redactPost/useRedactPost.ts:75
msgid "Oops! Something went wrong."
msgstr "Oups ! Quelque chose s'est mal passé."

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:89
#: src/components/pages/RedactPost/components/RedactPostForm.tsx:286
#~ msgid "Open the dialog"
#~ msgstr "Ouvrir la fenêtre"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:32
#~ msgid "Optimistic"
#~ msgstr "Optimiste"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:57
#: src/components/organisms/ReferralSourceForm.tsx:56
msgid "Other"
msgstr "Autre"

#: src/components/molecules/ProfileInformationCard.tsx:30
msgid "Our Affiliate Program offers a fantastic opportunity to earn generous commissions while promoting products you love"
msgstr "Notre programme d'affiliation offre une formidable opportunité de gagner des commissions généreuses tout en promouvant des produits que vous aimez."

#: src/components/pages/RedactPost/components/RedactPostFormV2.tsx:137
#~ msgid "Our AI will imitate the style of the given LinkedIn profile (from the 100 last posts of the person)."
#~ msgstr "Notre IA imitera le style du profil LinkedIn donné (à partir des 100 derniers posts de la personne)."

#: src/components/organisms/LoginForms/LoginForm.tsx:116
#: src/components/organisms/LoginForms/SignUpForm.tsx:175
msgid "Password"
msgstr "Mot de passe"

#: src/hooks/resetPassword/useResetPassword.ts:40
msgid "Password reset email sent. Please check your inbox."
msgstr "Email de réinitialisation du mot de passe envoyé. Veuillez vérifier votre boîte de réception."

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:113
msgid "Password Reset Failed"
msgstr "Échec de la réinitialisation du mot de passe"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:127
msgid "Password Reset Successful"
msgstr "Réinitialisation du mot de passe réussie"

#: src/components/organisms/LoginForms/SignUpForm.tsx:70
msgid "Password: Min 6 chars, 1 number, 1 letter."
msgstr "Mot de passe : Min 6 caractères, 1 chiffre, 1 lettre."

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:64
msgid "Passwords do not match."
msgstr "Les mots de passe ne correspondent pas."

#: src/components/pages/Settings/Settings.tsx:155
#~ msgid "Payment renewal on"
#~ msgstr "Prochain paiement le"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:24
#~ msgid "Pedagogical"
#~ msgstr "Pédagogique"

#: src/components/molecules/affiliateHistory.tsx:22
msgid "Pending"
msgstr "En attente"

#: src/components/molecules/CurrentSubscription.tsx:67
msgid "per month."
msgstr "par mois."

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:18
#~ msgid "Persuasive"
#~ msgstr "Persuasif"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:34
#~ msgid "Pessimistic"
#~ msgstr "Pessimiste"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmation.tsx:26
msgid "Pick who will take a pay cut."
msgstr "Choisissez qui aura une réduction de salaire."

#: src/components/organisms/LoginForms/SignUpForm.tsx:85
msgid "Please accept the Terms and Conditions."
msgstr "Veuillez accepter les Termes et Conditions."

#: src/hooks/findIdeas/useFindIdeas.ts:46
#: src/hooks/pricing/createCheckoutSession.ts:35
#: src/hooks/redactPost/useRedactPostMutation.ts:106
#: src/hooks/regeneratePost/useRegeneratePostMutation.ts:62
msgid "Please check if all the fields are filled correctly"
msgstr "Vérifie que tous les champs sont correctement remplis"

#: src/hooks/redactPost/useRedactPostMutation.ts:96
msgid "Please check the video link. Sometimes, the video is not compatible because captions are disabled."
msgstr "Vérifie le lien de la vidéo. Parfois, la vidéo n'est pas compatible car les sous-titres sont désactivés."

#: src/pages/onboarding/components/ChooseStyleSource.tsx:122
msgid "Please choose a style"
msgstr "Veuillez choisir un style"

#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:95
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:107
msgid "Please choose one of the three ideas"
msgstr "Veuillez choisir une des trois idées"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:115
msgid "Please contact support for assistance."
msgstr "Veuillez contacter le support pour assistance."

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:107
msgid "Please contact the support by chat on the bottom right corner for help."
msgstr "Veuillez contacter le support par chat dans le coin inférieur droit pour obtenir de l'aide."

#: src/pages/recyclepost/components/RecyclePostForm.tsx:81
msgid "Please ensure the LinkedIn profile URL is correct and accessible."
msgstr "Veuillez vérifier que l'URL du profil LinkedIn est correcte et accessible."

#: src/pages/onboarding/Onboarding.tsx:228
#: src/pages/onboarding/Onboarding.tsx:333
#~ msgid "Please enter a content URL"
#~ msgstr "Veuillez saisir une URL de contenu"

#: src/pages/findIdea/FindIdea.tsx:55
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:65
#: src/utils/validateForm.ts:36
#: src/utils/validateForm.ts:47
msgid "Please enter a post subject"
msgstr "Veuillez entrer un sujet"

#: src/utils/validateForm.ts:30
msgid "Please enter a valid content URL"
msgstr "Veuillez saisir un URL valide."

#: src/components/organisms/LoginForms/LoginForm.tsx:36
#: src/components/organisms/LoginForms/SignUpForm.tsx:60
#: src/hooks/resetPassword/useResetPassword.ts:22
msgid "Please enter a valid email address."
msgstr "Veuillez saisir une adresse e-mail valide."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:116
#~ msgid "Please enter the LinkedIn code you received by email ({email})"
#~ msgstr "Entre le code LinkedIn reçu par email ({email})"

#: src/hooks/resetPassword/useResetPassword.ts:15
msgid "Please enter your email address."
msgstr "Veuillez entrer votre adresse e-mail."

#: src/pages/redactpost/RedactPost.tsx:83
#~ msgid "Please fill in all required fields."
#~ msgstr "Veuillez remplir tous les champs obligatoires."

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:55
msgid "Please fill in both password fields."
msgstr "Veuillez remplir les deux champs de mot de passe."

#: src/components/organisms/ReferralSourceForm.tsx:78
msgid "Please fill in the referral source details"
msgstr "Renseigne les détails"

#: src/components/organisms/LoginForms/LoginForm.tsx:31
#: src/components/organisms/LoginForms/SignUpForm.tsx:55
msgid "Please fill in your email."
msgstr "Veuillez saisir votre e-mail."

#: src/components/organisms/LoginForms/SignUpForm.tsx:75
msgid "Please fill in your first name."
msgstr "Veuillez saisir votre prénom."

#: src/components/organisms/LoginForms/SignUpForm.tsx:80
msgid "Please fill in your last name."
msgstr "Veuillez saisir votre nom"

#: src/components/organisms/LoginForms/LoginForm.tsx:45
#: src/components/organisms/LoginForms/SignUpForm.tsx:65
msgid "Please fill in your password."
msgstr "Veuillez saisir votre mot de passe."

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:70
msgid "Please let us know why exactly you want to leave?"
msgstr "S'il vous plaît laissez-nous savoir pourquoi exactement vous souhaitez partir?"

#: src/components/pages/RedactPost/components/RedactPostGeneration.tsx:33
#~ msgid "Please like or dislike the posts below to help us improve our IA model"
#~ msgstr "Like les posts ci-dessous pour nous aider à améliorer notre modèle IA"

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:24
msgid "Please like or dislike the posts to improve our"
msgstr "Like les posts ci-dessous pour nous aider à améliorer notre"

#: src/pages/recyclepost/components/RecyclePostForm.tsx:72
msgid "Please provide a post to recycle."
msgstr "Veuillez fournir un post à recycler."

#: src/utils/validateForm.ts:61
msgid "Please provide a url that begins with ‘https://www.linkedin.com/in/’"
msgstr "Veuillez fournir une URL commençant par ’https://www.linkedin.com/in/’"

#: src/pages/settings/components/CancelSubscription.tsx:105
msgid "Please select a reason"
msgstr "Veuillez sélectionner une raison"

#: src/components/organisms/ReferralSourceForm.tsx:72
msgid "Please select a referral source"
msgstr "Sélectionne une source"

#: src/components/molecules/OnboardingReferralSource/OnboardingReferralSource.tsx:82
#~ msgid "Please specify"
#~ msgstr "Préciser"

#: src/pages/settings/components/CancelSubscription.tsx:114
msgid "Please type a reason"
msgstr "Veuillez saisir une raison"

#: src/components/pages/RedactPost/components/HelpPopup.tsx:8
#~ msgid "Please watch this video entirely to get advanced AI techniques"
#~ msgstr "Conseil: Regarde cette vidéo entièrement pour obtenir des techniques avancées sur l'utilisation de RedactAI"

#: src/components/molecules/CurrentSubscription.tsx:65
msgid "post"
msgstr "poste"

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:31
msgid "Post"
msgstr "Post"

#: src/components/organisms/LinkedInPostsCalendar/LinkedInPostsCalendar.tsx:69
#~ msgid "Post deleted successfully"
#~ msgstr "Post supprimé avec succès"

#: src/pages/redactpost/components/BasePostPreviewPopup.tsx:60
msgid "Post preview"
msgstr "Aperçu du post"

#: src/components/organisms/LinkedInPostsCalendar/LinkedInPostsCalendar.tsx:89
#~ msgid "Post scheduled successfully"
#~ msgstr "Post planifié avec succès"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:309
#~ msgid "Post tone"
#~ msgstr "Ton du post"

#: src/pages/onboarding/components/PostStyleSelect.tsx:45
#: src/pages/redactpost/components/RedactPostStyleSelect.tsx:40
msgid "Post type"
msgstr "Type de post"

#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:63
#~ msgid "Posted on"
#~ msgstr "Posté le"

#: src/components/molecules/CurrentSubscription.tsx:65
msgid "posts"
msgstr "posts"

#: src/components/templates/Page.tsx:49
#~ msgid "Posts calendar"
#~ msgstr "Calendrier"

#: src/components/pages/Settings/Settings.tsx:87
#~ msgid "posts generations per month"
#~ msgstr "générations de posts par mois"

#: src/components/molecules/SubscriptionInformationCard.tsx:55
#: src/components/molecules/SubscriptionInformationCard.tsx:108
#: src/components/molecules/SubscriptionInformationCard.tsx:142
#: src/components/molecules/SubscriptionInformationCard.tsx:172
msgid "posts left"
msgstr "posts restants"

#: src/components/organisms/SideBar/SideBar.tsx:136
#~ msgid "Posts left"
#~ msgstr "Posts restants"

#: src/components/pages/Settings/Settings.tsx:143
#~ msgid "posts per month"
#~ msgstr "posts par mois"

#: src/components/molecules/affiliateEarnings.tsx:19
msgid "posts per month."
msgstr "publications par mois."

#: src/components/pages/Settings/Settings.tsx:146
#~ msgid "Posts renewal on"
#~ msgstr "Renouvellement des posts le"

#: src/components/molecules/affiliateConditions.tsx:12
#: src/components/molecules/affiliateConditions.tsx:17
msgid "Posts/month*"
msgstr "Publications/mois*"

#: src/components/pages/Pricing/components/PricingTable.tsx:51
#: src/components/pages/Pricing/components/PricingTable.tsx:69
#~ msgid "Premium"
#~ msgstr "Premium"

#: src/components/organisms/SideBar/SideBar.tsx:114
#~ msgid "Premium subscription"
#~ msgstr "Subscription premium"

#: src/components/pages/Settings/Settings.tsx:139
#~ msgid "Price:"
#~ msgstr "Prix :"

#: src/pages/settings/Settings.tsx:62
msgid "Profile"
msgstr "Profil"

#: src/components/pages/Settings/Settings.tsx:19
#~ msgid "Profile Details"
#~ msgstr "Profil"

#: src/components/organisms/SavedPost/SavedPostCard.tsx:93
#~ msgid "Profile url:"
#~ msgstr "Url du profil :"

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:203
#~ msgid "Prompt"
#~ msgstr "Prompt"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:372
#~ msgid "Pronoun"
#~ msgstr "Pronom"

#: src/components/organisms/SavedPost/SavedPostCard.tsx:81
#~ msgid "Pronoun:"
#~ msgstr "Pronom :"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:31
msgid "Prove your skills"
msgstr "Prouvez vos compétences"

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:27
msgid "Proximity with clients"
msgstr "Proximité avec les clients"

#: src/components/molecules/LinkedInSchedulePostPopover/LinkedInSchedulePostPopover.tsx:54
#~ msgid "Publish on"
#~ msgstr "Publier le"

#: src/components/molecules/PricingCard.tsx:107
#: src/components/molecules/ProfileInformationCard.tsx:57
#: src/components/molecules/UpgradePricingCard.tsx:167
msgid "Quick demo with Yohan"
msgstr "Démo rapide avec Yohan"

#: src/components/molecules/ChatInputBar.tsx:41
#~ msgid "Re-generate posts"
#~ msgstr "Regénérer les posts"

#: src/components/molecules/ChatInputBar.tsx:41
msgid "Re-generate posts (free)"
msgstr "Regénérer les posts (gratuit)"

#: src/components/molecules/RecyclePostCard.tsx:64
msgid "Reactions: {0}"
msgstr "Réactions : {0}"

#: src/pages/settings/components/SettingsSubscription.tsx:39
#~ msgid "Recent transactions"
#~ msgstr "Transactions recentes"

#: src/components/templates/Page.tsx:48
#: src/pages/recyclepost/RecyclePost.tsx:234
msgid "Recycle a post"
msgstr "Recycler un post"

#: src/components/molecules/RecyclePostCard.tsx:80
msgid "Recycle Post"
msgstr "Recycler le post"

#: src/components/pages/RecyclePost/components/RecyclePostContent.tsx:7
#~ msgid "Recycle this post"
#~ msgstr "Recycler ce post"

#: src/pages/recyclepost/components/RecyclePostForm.tsx:125
msgid "Recycle This Post"
msgstr "Recycler ce post"

#: src/components/molecules/ChooseStyleSourceCards/CreatorStyle.tsx:15
#: src/components/molecules/ChooseStyleSourceCards/MyStyle.tsx:22
msgid "Redact AI will analyze your last posts to copy your writing style"
msgstr "Redact AI analysera vos derniers messages pour copier votre style d'écriture"

#: src/components/pages/Onboarding/components/ChooseStyleSource.tsx:49
#~ msgid "RedactAI will analyze their last posts to copy their writing style."
#~ msgstr "RedactAI analysera leurs derniers posts pour copier leur style d'écriture."

#: src/components/pages/Onboarding/components/ChooseStyleSource.tsx:36
#~ msgid "RedactAI will analyze your last posts to copy your writing style."
#~ msgstr "RedactAI analysera tes derniers posts pour copier ton style d'écriture."

#: src/hooks/tutorial/useTutorialSteps.tsx:124
#~ msgid "RedactAI will copy his writing style based on his most recent posts. Writing style include any things like: emojis, tone, language level, expressions, signature, ..."
#~ msgstr "RedactAI copiera son style d'écriture basé sur ses posts les plus récents. Le style d'écriture inclut des choses comme : emojis, ton, niveau de langage, expressions, signature, ..."

#: src/components/pages/RedactPost/components/RedactPostFormV2.tsx:148
#~ msgid "RedactAI will imitate the style of the given LinkedIn profile."
#~ msgstr "RedactAI imitera le style du profil LinkedIn donné."

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:129
msgid "Regenerate my posts"
msgstr "Régénérer mes posts"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:112
msgid "Remove \"Y\""
msgstr "Enlève \"Y\""

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:107
msgid "Remove emojis"
msgstr "Enlève les emojis"

#: src/components/molecules/SubscriptionInformationCard.tsx:183
msgid "Renew subscription"
msgstr "Renouveler l'abonnement"

#: src/components/molecules/SubscriptionInformationCard.tsx:118
#: src/components/molecules/SubscriptionInformationCard.tsx:148
msgid "Renewal date:"
msgstr "Renouvellement le:"

#: src/components/pages/Settings/Settings.tsx:108
#~ msgid "Renewal on"
#~ msgstr "Renouvellement le"

#: src/components/organisms/SideBar/SideBar.tsx:113
#~ msgid "Renewal:"
#~ msgstr "Renouvellement :"

#: src/components/organisms/SideBar/SideBar.tsx:140
#~ msgid "Renews on {formattedRenewalDate} (in {remainingDays} days)"
#~ msgstr "Renouvellement le {formattedRenewalDate} (dans {remainingDays} jours)"

#: src/components/molecules/PricingCard.tsx:36
#: src/components/molecules/UpgradePricingCard.tsx:42
msgid "Repurpose any piece of content to a LinkedIn post"
msgstr "Transforme n'importe quel contenu en post LinkedIn"

#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:97
#~ msgid "Reschedule"
#~ msgstr "Replanifier"

#: src/components/organisms/LoginForms/EmailVerification.tsx:66
msgid "Resend"
msgstr "Renvoyer"

#: src/pages/findIdea/FindIdea.tsx:152
#: src/pages/recyclepost/components/RecyclePostForm.tsx:145
#: src/pages/redactpost/RedactPost.tsx:235
msgid "Reset"
msgstr "Réinitialiser"

#: src/components/pages/Settings/Settings.tsx:124
#~ msgid "Reset password"
#~ msgstr "Réinitialiser mon mot de passe"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:159
msgid "Reset Password"
msgstr "Réinitialiser le mot de passe"

#: src/components/organisms/LoginForms/ResetPasswordForm.tsx:21
msgid "Reset your password"
msgstr "Réinitialisez votre mot de passe"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:139
msgid "Reset Your Password"
msgstr "Réinitialisez votre mot de passe"

#: src/pages/onboarding/components/PostGeneration.tsx:36
msgid "Review & publish"
msgstr "Réviser & publier"

#: src/pages/login/LoginPage.tsx:51
msgid "Revolutionizing Content Creation:"
msgstr "Ton post LinkedIn en 30 secondes :"

#: src/hooks/usePostToneOptions.ts:17
msgid "Sales / Persuasive"
msgstr "Vente / Persuasif"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:19
#: src/pages/onboarding/components/PostStyleSelect.tsx:17
#~ msgid "Sales/Persuasive"
#~ msgstr "Vente / Persuasif"

#: src/components/pages/Settings/Settings.tsx:131
#~ msgid "Save changes"
#~ msgstr "Sauvegarder"

#: src/components/organisms/PostEditor/PostEditor.tsx:111
#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:149
#~ msgid "Saved automatically"
#~ msgstr "Sauvegardé automatiquement"

#: src/components/pages/Pricing/components/PricingTable.tsx:170
#~ msgid "Scale your agency business"
#~ msgstr "Scale ton business d'agence"

#: src/components/pages/Pricing/components/PricingTable.tsx:172
#~ msgid "Scale your content creation"
#~ msgstr "Scale ta création de contenu"

#: src/components/molecules/LinkedInSchedulePostPopover/LinkedInSchedulePostPopover.tsx:66
#: src/components/organisms/LinkedInNewPost/LinkedInNewPost.tsx:50
#: src/components/organisms/LinkedInNewPostPopup/LinkedInNewPostPopup.tsx:53
#~ msgid "Schedule"
#~ msgstr "Planifier"

#: src/components/pages/Pricing/components/PricingTable.tsx:238
#~ msgid "Schedule a demo"
#~ msgstr "Programmer une démo"

#: src/components/pages/Calendar/Calendar.tsx:10
#~ msgid "Schedule all your posts in 30 seconds"
#~ msgstr "Planifie tous tes posts en 30 secondes"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:112
#~ msgid "Schedule my post"
#~ msgstr "Planifier mon post"

#: src/components/organisms/PostEditor/PostEditor.tsx:132
#~ msgid "Schedule on LinkedIn"
#~ msgstr "Planifier sur LinkedIn"

#: src/components/pages/RedactPost/RedactPost.tsx:45
#~ msgid "Schedule posts"
#~ msgstr "Planifier mes posts"

#: src/components/organisms/LinkedInPostPopup/LinkedInPostPopup.tsx:63
#~ msgid "Scheduled for"
#~ msgstr "Planifié pour le"

#: src/components/pages/FindIdea/components/MyIdeasTab.tsx:44
#: src/components/pages/RedactPost/components/HistoryTab.tsx:57
#~ msgid "Search for a post or idea"
#~ msgstr "Chercher un post ou une idée"

#: src/pages/redactpost/components/BasePostPreviewPopup.tsx:124
msgid "See less"
msgstr "Voir moins"

#: src/components/pages/RecyclePost/components/RecyclePostBox.tsx:70
#~ msgid "See less..."
#~ msgstr "Voir moins..."

#: src/components/pages/RecyclePost/components/RecyclePostBox.tsx:70
#~ msgid "See more..."
#~ msgstr "Voir plus..."

#: src/components/organisms/PostEditor/PostEditor.tsx:97
#~ msgid "See my posts"
#~ msgstr "Voir mes posts"

#: src/components/molecules/GeneratedPost.tsx:137
#: src/components/molecules/SavedPost.tsx:73
msgid "See preview"
msgstr "Voir l'aperçu"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:74
msgid "Select a reason"
msgstr "Sélectionnez une raison"

#: src/components/organisms/LoginForms/ResetPasswordForm.tsx:33
msgid "Send email"
msgstr "Envoyer l'email"

#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:91
msgid "SEO"
msgstr "SEO"

#: src/components/templates/Page.tsx:54
#: src/pages/settings/Settings.tsx:50
msgid "Settings"
msgstr "Paramètres"

#: src/components/molecules/affiliateLinkCopy.tsx:27
msgid "Share this link with your friends and win free posts every month:"
msgstr "Partagez ce lien avec vos amis et gagnez des publications gratuites chaque mois:"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:6
#~ msgid "Short"
#~ msgstr "Court"

#: src/components/organisms/LoginForms/SignUpForm.tsx:241
msgid "Sign in"
msgstr "Se connecter"

#: src/components/molecules/ProfileHeader.tsx:53
#: src/components/molecules/ProfileHeaderMobile.tsx:54
msgid "Sign out"
msgstr "Déconnexion"

#: src/components/organisms/LoginForms/LoginForm.tsx:148
#: src/components/organisms/LoginForms/SignUpForm.tsx:224
msgid "Sign up"
msgstr "S'inscrire"

#: src/components/organisms/LoginForms/SignUpForm.tsx:161
msgid "Sign Up"
msgstr "S'inscrire"

#: src/components/organisms/LoginForms/SignUpForm.tsx:230
msgid "Sign up via Google"
msgstr "S'inscrire via Google"

#: src/components/organisms/LoginForms/EmailVerification.tsx:28
msgid "Sign-up successful! Please verify your email."
msgstr "Inscription réussie! Veuillez vérifier votre email."

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:33
msgid "Signed in with Google successfully"
msgstr "Connexion réussie avec Google"

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:20
#~ msgid "Signed in with Google successfuly"
#~ msgstr "Connexion réussie avec Google"

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:32
msgid "Signed up with Google successfully"
msgstr "Inscription réussie avec Google"

#: src/components/organisms/LoginForms/GoogleCallbackHandler.tsx:16
#~ msgid "Signed up with Google successfuly"
#~ msgstr "Inscription réussie avec Google"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:90
#: src/components/pages/RedactPost/components/RedactPostForm.tsx:287
#~ msgid "Skip"
#~ msgstr "Passer"

#: src/components/organisms/OnboardingPopup/OnboardingPopup.tsx:52
#~ msgid "Some advices for a good start:"
#~ msgstr "Quelques conseils pour bien démarrer :"

#: src/components/organisms/ReferralSourceForm.tsx:120
msgid "Start exploring"
msgstr "Commencez à explorer"

#: src/components/organisms/PricingTable.tsx:108
msgid "Start your"
msgstr "Commencez votre"

#: src/components/pages/Pricing/components/PricingTable.tsx:122
#~ msgid "Start your 7 days free trial"
#~ msgstr "Démarre ton essai gratuit de 7 jours"

#: src/components/pages/Pricing/components/PricingTable.tsx:62
#~ msgid "Start your free trial"
#~ msgstr "Commence ton essai gratuit"

#: src/components/molecules/affiliateHistory.tsx:39
msgid "Status"
msgstr "Statut"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:92
msgid "Stay with us and support our families"
msgstr "Restez avec nous et soutenez nos familles"

#: src/components/pages/RedactPost/components/Loader.tsx:44
#~ msgid "Step {0} of {1}:"
#~ msgstr "Etape {0} sur {1} :"

#: src/pages/onboarding/components/PostGeneration.tsx:79
#~ msgid "Step 1"
#~ msgstr "Etape 1"

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:35
msgid "Step 1:"
msgstr "Etape 1:"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:18
#: src/components/pages/Onboarding/Onboarding.tsx:130
#: src/components/pages/Onboarding/Onboarding.tsx:145
#~ msgid "Step 1: Content"
#~ msgstr "Etape 1 : Contenu"

#: src/pages/redactpost/components/LoadingLayout.tsx:23
msgid "Step 2"
msgstr "Etape 2"

#: src/pages/onboarding/components/ChooseStyleSource.tsx:51
msgid "Step 2:"
msgstr "Etape 2 :"

#: src/components/pages/Onboarding/components/ChooseStyleSource.tsx:17
#~ msgid "Step 2: Style"
#~ msgstr "Etape 2 : Style"

#: src/pages/redactpost/components/LoadingLayout.tsx:23
msgid "Step 3"
msgstr "Etape 3"

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:31
msgid "Step 3:"
msgstr "Etape 3:"

#: src/components/pages/Onboarding/Onboarding.tsx:176
#~ msgid "Step 3: Post"
#~ msgstr "Etape 3 : Post"

#: src/pages/redactpost/components/LoadingLayout.tsx:23
msgid "Step 4"
msgstr "Etape 4"

#: src/pages/onboarding/components/PostGeneration.tsx:36
msgid "Step 4:"
msgstr "Etape 4:"

#: src/components/pages/RedactPost/components/RedactPostGeneration.tsx:48
#~ msgid "Step 4: Review & publish"
#~ msgstr "Etape 4 : Retouches"

#: src/hooks/usePostToneOptions.ts:13
msgid "Storytelling / Inspirational"
msgstr "Storytelling / Inspirant"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:18
#: src/pages/onboarding/components/PostStyleSelect.tsx:16
#~ msgid "Storytelling/Inspirational"
#~ msgstr "Storytelling / Inspirant"

#: src/pages/onboarding/components/ChooseStyleSource.tsx:51
msgid "Style"
msgstr "Style"

#: src/components/molecules/ChooseContentSourceCards/MyIdeas.tsx:20
#: src/pages/onboarding/components/GenerateFromContentURL.tsx:73
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:61
msgid "Subject"
msgstr "Sujet"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:316
#~ msgid "Subject ?"
#~ msgstr "Sujet ?"

#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:62
msgid "Subject of your post. Example: \"How to increase your sales by 30% in 3 months\""
msgstr "Subject de ton post. Exemple: \"Comment augmenter tes ventes de 30% en 3 mois\""

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:72
msgid "Subject of your post. Example: The story of elon musk"
msgstr "Sujet de ton post. Exemple: L'histoire d'Elon Musk"

#: src/components/pages/Onboarding/components/ChooseContentSource.tsx:36
#~ msgid "Subject:"
#~ msgstr "Sujet :"

#: src/components/molecules/popups/DescribeReason.tsx:42
msgid "Submit"
msgstr "Envoyer"

#: src/pages/settings/Settings.tsx:67
msgid "Subscription"
msgstr "Abonnement"

#: src/components/molecules/SubscriptionInformationCard.tsx:63
#: src/components/molecules/SubscriptionInformationCard.tsx:176
msgid "Subscription Canceled"
msgstr "Abonnement annulé"

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmed.tsx:20
msgid "subscription cancelled"
msgstr "abonnement annulé"

#: src/components/organisms/ChatWithRedactAIChatArea.tsx:215
msgid "Tell RedactAI what to change"
msgstr "Dites à RedactAI quoi changer"

#: src/components/organisms/LoginForms/SignUpForm.tsx:220
msgid "Terms and Conditions"
msgstr "Conditions générales"

#: src/components/molecules/GeneratedPost.tsx:48
msgid "Thanks for your feedback!"
msgstr "Merci pour ton retour !"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:127
#~ msgid "The connection make take up to 1 minute, please be patient"
#~ msgstr "La connexion peut prendre au moins 1 minute, sois patient 🙏"

#: src/hooks/redactPost/useRedactPostMutation.ts:91
msgid "The content link is not a valid LinkedIn post link. Please check that the url is the url of a LinkedIn post. Please note that LinkedIn articles are not supported for now. You can copy/paste the content of the article directly into the “Key ideas” form field."
msgstr "Le lien du contenu n'est pas un lien de post LinkedIn valide. Vérifie que l'url est bien celle d'un post LinkedIn. Note que les articles LinkedIn ne sont pas supportés pour le moment. Tu peux copier/coller le contenu de l'article directement dans le champ 'Idées clés'."

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:19
msgid "The Key ideas of your post, This field is recommended but  not mandatory. Keys ideas can be:"
msgstr "Idées clés de ton post. Ce champ est recommandé mais pas obligatoire. Les idées clés peuvent être:"

#: src/hooks/tutorial/useTutorialSteps.tsx:51
#~ msgid "The keys ideas of your post. This field is recommended but not mandatory. Keys ideas can be :"
#~ msgstr "Idées clés de ton post. Ce champ est recommandé mais pas obligatoire. Les idées clés peuvent être :"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:45
msgid "The product did not meet my expectations."
msgstr "Le produit n'a pas répondu à mes attentes."

#: src/pages/onboarding/components/ToolTipFirstStep.tsx:18
msgid "The subject of your post. It is recommended to write one sentence. This field is mandatory"
msgstr "Sujet de ton post. Il est recommandé d'écrire une phrase. Ce champ est obligatoire"

#: src/hooks/tutorial/useTutorialSteps.tsx:35
#~ msgid "The subject of your post. It is recommended to write one sentence. This field is mandatory."
#~ msgstr "Sujet de ton post. Il est recommandé d'écrire une phrase. Ce champ est obligatoire."

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:37
msgid "The subscription cost is too high for my budget."
msgstr "Le coût de l'abonnement est trop élevé pour mon budget."

#: src/hooks/tutorial/useTutorialSteps.tsx:120
#~ msgid "The url of the profile of the person you want to imitate."
#~ msgstr "L'url du profil de la personne que tu veux imiter."

#: src/components/molecules/PricingCard.tsx:68
msgid "Then {0} euros billed"
msgstr "Puis {0} euros facturés"

#: src/components/molecules/PricingCard.tsx:54
#: src/components/molecules/UpgradePricingCard.tsx:57
#~ msgid "Then 189.90 euros billed annually"
#~ msgstr "Puis 189.90 euros facturés annuellement"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:172
#~ msgid "There is an error in the form, please check the form fields."
#~ msgstr "Il y a une erreur dans le formulaire, veuillez vérifier les champs du formulaire."

#: src/pages/onboarding/components/PostGeneration.tsx:48
#~ msgid "There you go!"
#~ msgstr "Voilà!"

#: src/pages/redactpost/components/RedactPostChatWithAi.tsx:26
#~ msgid "There you go! You got the lightened fast results"
#~ msgstr "Voilà! Vous avez obtenu des résultats rapides et allégés"

#: src/components/molecules/popups/DeleteAccountPopupConfirmation.tsx:32
#: src/components/molecules/popups/DeleteIdeaPopup.tsx:37
msgid "This action cannot be undone."
msgstr "Cette action ne peut pas être annulée."

#: src/pages/recyclepost/RecyclePost.tsx:146
msgid "This LinkedIn profile url is not a valid url. Please provide a url that begins with ‘https://www.linkedin.com/in/’"
msgstr "Cette URL de profil LinkedIn n'est pas une URL valide. Veuillez fournir une URL qui commence par 'https://www.linkedin.com/in/'"

#: src/hooks/redactPost/useRedactPostMutation.ts:101
msgid "This profile link is not valid, please check that this is a real LinkedIn profile url."
msgstr "Ce lien de profil n'est pas valide, vérifie que c'est bien une URL de profil LinkedIn valide."

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:50
msgid "to help drive the creative work of our IA"
msgstr "pour aider à stimuler le travail créatif de notre IA"

#: src/components/organisms/SavedPost/SavedPostCard.tsx:77
#~ msgid "Tone:"
#~ msgstr "Ton :"

#: src/pages/findIdea/FindIdea.tsx:130
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:153
msgid "Topic or theme"
msgstr "Sujet ou thème"

#: src/pages/findIdea/FindIdea.tsx:131
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:154
msgid "Topic or theme you want to talk about. Example: \"Digital Marketing\" or \"SEO\"."
msgstr "Sujet ou thème que tu veux aborder. Exemple: \"Marketing digital\" ou \"SEO\"."

#: src/components/organisms/PostEditor/PostEditor.tsx:75
#~ msgid "Total words:"
#~ msgstr "Nombre de mots :"

#: src/hooks/usePostToneOptions.ts:25
msgid "Truth / Arrogant"
msgstr "Vérité / Arrogant"

#: src/components/molecules/ChooseStyleSourceCards/RedactaiStyle.tsx:21
#: src/pages/onboarding/components/PostStyleSelect.tsx:19
#~ msgid "Truth/Arrogant"
#~ msgstr "Vérité / Arrogant"

#: src/components/molecules/CurrentSubscriptionFree.tsx:23
msgid "Try for free"
msgstr "Essayer gratuitement"

#: src/components/molecules/SubscriptionInformationCard.tsx:45
#: src/components/molecules/SubscriptionInformationCard.tsx:93
msgid "Try for FREE"
msgstr "Essayer gratuitement"

#: src/components/molecules/SubscriptionInformationCard.tsx:81
#~ msgid "Try redact AI"
#~ msgstr "Essayer redact AI"

#: src/components/molecules/SubscriptionInformationCard.tsx:81
msgid "Try RedactAI"
msgstr "Essayer RedactAI"

#: src/components/molecules/PricingCard.tsx:80
msgid "Try RedactAI for Free"
msgstr "Essaye RedactAI gratuitement"

#: src/components/molecules/ChatInputBar.tsx:49
msgid "Try these"
msgstr "Essayez ceux-ci"

#: src/components/molecules/popups/DescribeReason.tsx:27
msgid "Type here.."
msgstr "Tapez ici.."

#: src/hooks/subscription/useCancelSubscription.ts:25
msgid "Unable to cancel the subscription"
msgstr "Impossible d'annuler l'abonnement"

#: src/hooks/savedPostsAndIdeas/useDeleteSavedIdea.ts:16
msgid "Unable to delete idea"
msgstr "Impossible de supprimer l'idée"

#: src/hooks/savedPostsAndIdeas/useLikeSavedPost.ts:40
msgid "Unable to delete post"
msgstr "Impossible de supprimer le post"

#: src/hooks/deleteAccount/useDeleteAccount.ts:12
msgid "Unable to delete the account"
msgstr "Impossible de supprimer le compte"

#: src/hooks/savedPostsAndIdeas/useLikeSavedPost.ts:47
msgid "Unable to like post"
msgstr "Impossible d'aimer le post"

#: src/hooks/savedPostsAndIdeas/useMarkSavedIdeaAsUsed.ts:18
msgid "Unable to mark idea as used"
msgstr "Impossible de marquer l'idée comme utilisée"

#: src/hooks/savedPostsAndIdeas/useMarkSavedPostAsUsed.ts:18
msgid "Unable to mark post as used"
msgstr "Impossible de marquer le post comme utilisé"

#: src/hooks/linkedin/useLinkedInLogin.ts:44
#: src/hooks/linkedin/useLinkedInLogin.ts:44
#~ msgid "Unable to resend the pin email, please retry or contact us"
#~ msgstr "Impossible de renvoyer l'email, réessaye ou contacte-nous"

#: src/hooks/savedPostsAndIdeas/useSavePost.ts:20
msgid "Unable to save post"
msgstr "Impossible de sauvegarder le post"

#: src/components/molecules/UpgradePricingCard.tsx:101
#: src/components/molecules/UpgradePricingCard.tsx:141
msgid "Upgrade"
msgstr "Changer d'abonnement"

#: src/components/molecules/SubscriptionInformationCard.tsx:127
#~ msgid "Upgrade now"
#~ msgstr "Mettre à jour maintenant"

#: src/components/pages/Settings/Settings.tsx:154
#~ msgid "Upgrade subscription"
#~ msgstr "Voir les abonnements"

#: src/components/organisms/PricingTable.tsx:140
msgid "Upgrade your account"
msgstr "Changer d'abonnement"

#: src/components/pages/Pricing/components/PricingTable.tsx:122
#~ msgid "Upgrade your acount"
#~ msgstr "Changer d'abonnement"

#: src/pages/onboarding/components/ProfileUrlStyle.tsx:115
msgid "Url of LinkedIn profile"
msgstr "URL du profil LinkedIn"

#: src/pages/onboarding/components/ProfileUrlStyle.tsx:116
msgid "Url of LinkedIn profile (you or your favourite content creator). Example: https://www.linkedin.com/in/pamartn/"
msgstr "URL du profil LinkedIn (le tien ou ton créateur de contenu préféré). Exemple: https://www.linkedin.com/in/pamartn/"

#: src/pages/onboarding/components/ProfileUrlStyle.tsx:116
#~ msgid "Url of LinkedIn profile (your favourite content creator)"
#~ msgstr "URL du profil LinkedIn (votre créateur de contenu préféré)"

#: src/components/pages/RedactPost/components/RedactPostStyle.tsx:61
#~ msgid "Url of LinkedIn profile (yours or your favorite content creator)"
#~ msgstr "Url du profil LinkedIn (le tien ou celui de ton créateur préféré)"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:344
#~ msgid "Url of LinkedIn profile (yours or your favorite influencer):"
#~ msgstr "Url du profil LinkedIn (le tien ou celui de ton influenceur préféré) :"

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:55
msgid "URL of the content"
msgstr "Url du contenu"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:35
#~ msgid "Url of the content (Website, Youtube, News article, LinkedIn post, ...)"
#~ msgstr "Url du contenu (Site web, Youtube, article de presse / blog, post LinkedIn, ...)"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:298
#~ msgid "Url of the content (Youtube, News article, LinkedIn post)"
#~ msgstr "Url du contenu (Youtube, article de presse, post LinkedIn)"

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:56
#~ msgid "URL of the content https://www.forbes.com/profile/elon-musk/?sh=33e5b9517999"
#~ msgstr "Url du contenu https://www.forbes.com/profile/elon-musk/?sh=33e5b9517999"

#: src/pages/onboarding/components/GenerateFromContentURL.tsx:56
msgid "URL of the content. Example: https://www.forbes.com/profile/elon-musk/?sh=33e5b9517999"
msgstr "URL du contenu. Exemple: https://www.forbes.com/profile/elon-musk/?sh=33e5b9517999"

#: src/components/molecules/FilterSelectUsedNotUsed.tsx:24
#: src/pages/findIdea/components/MyIdeasTab.tsx:48
#: src/pages/myPosts/MyPosts.tsx:58
msgid "Used"
msgstr "Utilisé"

#: src/components/organisms/LoginForms/EmailVerification.tsx:41
msgid "Verification email resent successfully!"
msgstr "Email de vérification renvoyé avec succès !"

#: src/components/organisms/LoginForms/EmailVerification.tsx:61
msgid "Verification email sent!"
msgstr "Email de vérification envoyé!"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:78
msgid "Verifying Email"
msgstr "Vérification de l'email"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:17
msgid "Verifying your email..."
msgstr "Vérification de votre email..."

#: src/components/organisms/SavedPost/SavedPostCard.tsx:51
#~ msgid "View"
#~ msgstr "Voir"

#: src/hooks/tutorial/useTutorialSteps.tsx:127
#~ msgid "Warning: We suggest using the writing style of well known content creators if you are a beginner on LinkedIn."
#~ msgstr "Attention: Nous te conseillons d'utiliser le style d'écriture de créateurs de contenu connus si tu es débutant sur LinkedIn."

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:58
#~ msgid "We"
#~ msgstr "Nous"

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:105
#~ msgid "We are currently working on a new version of the LinkedIn connection, it will be available soon."
#~ msgstr "Nous travaillons actuellement sur une nouvelle version de la connexion LinkedIn, elle sera bientôt disponible."

#: src/hooks/redactPost/useRedactPostMutation.ts:86
msgid "We can't retrieve the content of this website (it is blocking our AI). Please copy paste the content of the website or article directly into the “Key ideas” form field to redact your post."
msgstr "Nous ne pouvons pas récupérer le contenu de ce site web (il bloque notre IA). Copie-colle le contenu du site web ou de l'article directement dans le champ 'Idées clés' pour rédiger ton post."

#: src/components/molecules/popups/CancelSubscriptionPopupConfirmed.tsx:21
msgid "We hope to see you again!"
msgstr "Nous espérons vous revoir bientôt!"

#: src/components/organisms/LoginForms/ResetPasswordForm.tsx:22
msgid "We will send you an email to reset your password"
msgstr "Nous vous enverrons un e-mail pour réinitialiser votre mot de passe"

#: src/components/molecules/popups/CancelSubscriptionPopupReasonAsk.tsx:68
msgid "We'd be sad to see you go"
msgstr "Nous serions tristes de vous voir partir"

#: src/components/molecules/popups/DeleteAccountPopupConfirmed.tsx:24
msgid "We'll miss you"
msgstr "Vous nous manquerez"

#: src/pages/redactpost/components/BasePostPreviewPopup.tsx:87
msgid "Web"
msgstr "Web"

#: src/components/organisms/OnboardingPopup/OnboardingPopup.tsx:32
#~ msgid "Welcome on ..."
#~ msgstr "Bienvenue sur ..."

#: src/components/organisms/OnboardingPopup/OnboardingPopup.tsx:58
#~ msgid "Welcome to"
#~ msgstr "Bienvenue sur"

#: src/components/pages/Onboarding/Onboarding.tsx:102
#~ msgid "Welcome,"
#~ msgstr "Bienvenue,"

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:61
msgid "What do you want to talk about ?"
msgstr "De quoi veux-tu parler ?"

#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:153
#: src/pages/onboarding/components/GenerateFromNoIdeas.tsx:154
#~ msgid "What topic/theme you want to talk about"
#~ msgstr "De quoi veux-tu parler?"

#: src/components/pages/FindIdea/components/FindIdeaForm.tsx:108
#~ msgid "What topic/theme you want to talk about ?"
#~ msgstr "De quel thème souhaites-tu parler ?"

#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:36
msgid "What types of content are most successful on LinkedIn?"
msgstr "Quels types de contenus ont le plus de succès sur LinkedIn ?"

#: src/pages/findIdea/FindIdea.tsx:130
#: src/pages/findIdea/FindIdea.tsx:131
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:61
#: src/pages/onboarding/components/GenerateFromMyOwnIdeas.tsx:62
#~ msgid "What's your thought?"
#~ msgstr "Quelle est votre pensée ?"

#: src/components/organisms/ReferralSourceForm.tsx:109
#: src/components/organisms/ReferralSourceForm.tsx:110
msgid "Where did you know us?"
msgstr "Où nous as-tu connu ?"

#: src/components/pages/RedactPost/components/RedactPostContent.tsx:15
#~ msgid "Why do you need to post on LinkedIn ?"
#~ msgstr "Pourquoi veux-tu poster sur LinkedIn ?"

#: src/components/molecules/GeneratedPost.tsx:117
#: src/components/molecules/SavedPost.tsx:63
#: src/pages/redactpost/components/BasePostPreviewPopup.tsx:63
msgid "Word"
msgstr "Mot"

#: src/components/organisms/ReferralSourceForm.tsx:48
msgid "Word of mouth"
msgstr "Bouche à oreille"

#: src/components/organisms/PostEditor/PostEditor.tsx:109
#~ msgid "words"
#~ msgstr "mots"

#: src/components/molecules/GeneratedPost.tsx:117
#: src/components/molecules/SavedPost.tsx:63
#: src/pages/redactpost/components/BasePostPreviewPopup.tsx:63
msgid "Words"
msgstr "Mots"

#: src/components/pages/RedactPost/RedactPost.tsx:51
#~ msgid "Write a post"
#~ msgstr "Ecrire un post"

#: src/pages/onboarding/components/LearnHowToGenerateContentContainer.tsx:77
#: src/pages/recyclepost/components/RecyclePostForm.tsx:152
#: src/pages/redactpost/RedactPost.tsx:242
msgid "Write my post"
msgstr "Écrire mon post"

#: src/components/organisms/PostEditorV2/PostEditorV2.tsx:36
#~ msgid "Write your awesome post here ..."
#~ msgstr "Ecris ton super post ici ..."

#: src/pages/onboarding/components/PostStyle.tsx:40
#: src/pages/onboarding/components/ProfileUrlStyle.tsx:94
#: src/pages/redactpost/components/RedactPostStyle.tsx:71
msgid "writing style"
msgstr "Style d'écriture"

#: src/components/pages/RedactPost/components/RedactPostStyle.tsx:48
#~ msgid "Writing style"
#~ msgstr "Style d'écriture"

#: src/components/organisms/LoginForms/LoginForm.tsx:76
msgid "Wrong email or password. Please try again."
msgstr "Adresse e-mail ou mot de passe incorrect. Veuillez réessayer."

#: src/components/pages/Pricing/components/PricingTable.tsx:135
#~ msgid "Yearly"
#~ msgstr "Annuel"

#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:56
#: src/hooks/savedPostsAndIdeas/util/savedPostsTranslations.ts:60
#~ msgid "You"
#~ msgstr "Tu"

#: src/components/pages/RedactPost/components/RedactPostForm.tsx:135
#~ msgid "You (plural)"
#~ msgstr "Vous"

#: src/utils/handleGoogleSignInUp.ts:17
msgid "You are being redirected to Google for authentication..."
msgstr "Vous êtes redirigé vers Google pour l'authentification..."

#: src/components/pages/Pricing/components/PricingTable.tsx:70
#~ msgid "You are close to generate a LinkedIn post in your writing style in 30 seconds. You can cancel anytime."
#~ msgstr "C’est bientôt l’heure pour toi de générer des posts LinkedIn dans ton style d’écriture en 30 secondes."

#: src/components/organisms/PricingTable.tsx:154
#~ msgid "you are getting 2 months FREE with annual subscription"
#~ msgstr "vous bénéficiez de 2 mois GRATUITS avec l'abonnement annuel"

#: src/components/organisms/PricingTable.tsx:124
#: src/components/organisms/PricingTable.tsx:154
msgid "You are getting 2 months FREE with annual subscription"
msgstr "Tu bénéficies de 2 mois GRATUITS avec l'abonnement annuel"

#: src/components/organisms/PricingTable.tsx:124
#~ msgid "you are getting 2months FREE with annual subscription"
#~ msgstr "vous bénéficiez de 2 mois GRATUITS avec l'abonnement annuel"

#: src/components/organisms/PricingTable.tsx:112
msgid "You can cancel anytime."
msgstr "Vous pouvez annuler à tout moment."

#: src/pages/onboarding/components/ToolTipFinalStep.tsx:49
msgid "You can use"
msgstr "Tu peux utiliser"

#: src/hooks/tutorial/useTutorialSteps.tsx:87
#~ msgid "You can use <directions> to help drive the creative work of our IA."
#~ msgstr "Tu peux utiliser <directions> pour aider à orienter le travail créatif de notre IA."

#: src/components/pages/Settings/Settings.tsx:149
#~ msgid "You do not have an active subscription yet."
#~ msgstr "Vous n'avez pas encore d'abonnement actif."

#: src/components/molecules/CurrentSubscriptionFree.tsx:17
msgid "You do not have an active subscription."
msgstr "Vous n'avez pas encore d'abonnement actif."

#: src/components/pages/Pricing/components/PricingTable.tsx:124
#~ msgid "You do not have enough credits. Choose the plan that works best for you."
#~ msgstr "Tu n'as pas assez de crédits. Choisis le plan qui te convient le mieux."

#: src/components/pages/Pricing/components/StripePricingTable.tsx:11
#~ msgid "You don't have enough credits for this generation."
#~ msgstr "Tu n'as pas assez de crédits pour cette génération."

#: src/pages/onboarding/components/PostGeneration.tsx:49
#~ msgid "You got the lightened fast results"
#~ msgstr "Vous avez obtenu des résultats rapides et allégés"

#: src/pages/settings/components/DeleteAccount.tsx:35
msgid "You have an active subscription. Please cancel your subscription in the subscription tab before deleting your account."
msgstr "Vous avez un abonnement actif. Veuillez annuler votre abonnement dans l'onglet des abonnements avant de supprimer votre compte."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:117
#~ msgid "You must connect your LinkedIn account in order to schedule your posts*"
#~ msgstr "Tu dois connecter ton compte LinkedIn pour planifier tes posts*"

#: src/components/pages/Settings/Settings.tsx:149
#~ msgid "You subscription has been canceled."
#~ msgstr "Votre abonnement a été annulé."

#: src/components/molecules/LinkedinConnectionForm/LinkedInConnectionForm.tsx:46
#~ msgid "You successfully connected your LinkedIn account !"
#~ msgstr "Compte LinkedIn connecté avec succès !"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:129
msgid "You will be redirected to the login page shortly."
msgstr "Vous serez redirigé vers la page de connexion dans un court instant."

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:93
msgid "You will be redirected to the onboarding page shortly."
msgstr "Vous serez redirigé vers la page d'Onboarding dans un court instant."

#: src/components/molecules/affiliateEarnings.tsx:22
msgid "You’ve earned a total of {totalPosts} posts."
msgstr "Vous avez gagné un total de {totalPosts} publications."

#: src/pages/login/LoginPage.tsx:54
msgid "Your AI Writing Assistant!"
msgstr "Ton assistant IA à la rédaction de post !"

#: src/components/pages/Settings/Settings.tsx:112
#~ msgid "Your company name"
#~ msgstr "Ton entreprise"

#: src/components/pages/Settings/Settings.tsx:118
#~ msgid "Your company position"
#~ msgstr "Ton poste"

#: src/components/pages/Settings/Settings.tsx:111
#~ msgid "Your email"
#~ msgstr "Ton email"

#: src/components/organisms/LoginForms/EmailVerificationHandler.tsx:14
msgid "Your email has been successfully verified!"
msgstr "Votre e-mail a été vérifié avec succès !"

#: src/components/pages/Settings/Settings.tsx:110
#~ msgid "Your first name"
#~ msgstr "Ton prénom"

#: src/components/pages/Settings/Settings.tsx:115
#~ msgid "Your last name"
#~ msgstr "Ton nom de famille"

#: src/components/pages/Settings/Settings.tsx:117
#~ msgid "Your LinkedIn profile url"
#~ msgstr "Ton url de profil LinkedIn :"

#: src/components/organisms/LoginForms/PasswordResetHandler.tsx:16
msgid "Your password has been successfully reset!"
msgstr "Votre mot de passe a été réinitialisé avec succès !"

#: src/components/molecules/CurrentSubscription.tsx:70
msgid "Your plan will be renewed on"
msgstr "Votre abonnement sera renouvelé le"

#: src/hooks/tutorial/useTutorialSteps.tsx:141
#~ msgid "Your post has been created ! Please rework it a little to your convenience, and when ready to publish on LinkedIn just click copy and paste it on your LinkedIn post."
#~ msgstr "Ton post a été créé ! Peux-tu le retravailler un peu à ta convenance, et quand tu seras prêt à le publier sur LinkedIn, clique sur copier et colle-le dans ton post LinkedIn."

#: src/components/molecules/ChooseContentSourceCards/FromUrl.tsx:24
msgid "Your website"
msgstr "Ton site web"
