import {FunctionComponent, useCallback} from 'react';
import styled from 'styled-components';
import {Chip} from '../atoms/Chip';
import {Button} from '../atoms/Button';
import {t} from '@lingui/macro';
import {differenceInDays, format} from 'date-fns';
import {ResetIcon} from './Icons/ResetIcon';
import {Post} from '../../pages/recyclepost/RecyclePost';
import {useTracking} from '../../hooks/useTracking';
import {isTablet} from '../../utils/responsive';
import EditIcon from '../atoms/Icons/EditIcon';

interface Props {
  post: Post;
  setSelectedPost?: (post: Post) => void;
  isRecycleForm?: boolean;
  setIsRecycling?: (value: boolean) => void;
}

export const RecyclePostCard: FunctionComponent<Props> = ({
  post,
  setSelectedPost,
  isRecycleForm = false,
  setIsRecycling,
}) => {
  const {trackEvent} = useTracking();
  const handleRecycleClick = useCallback(() => {
    if (!setIsRecycling || !setSelectedPost) {
      return;
    }
    const currentDate = new Date();
    const postDate = new Date(post.date);
    const diffInDays = differenceInDays(currentDate, postDate);
    setIsRecycling(true);
    setSelectedPost(post);
    trackEvent('RecyclePost - Click - Recycle post', {
      daysFromNow: diffInDays,
    });
  }, [setIsRecycling, setSelectedPost, post, trackEvent]);

  return (
    <Card $isRecycleForm={isRecycleForm}>
      <CardContent $isRecycleForm={isRecycleForm}>
        <Header>
          <Chip
            label={format(new Date(post.date), 'dd/MM/yyyy, HH:mm:ss')}
            variant="date"
          />
        </Header>
        <ReactionsCommentsSection>
          <H4>{t`Reactions: ${post.reactionCount}`}</H4>
          <H4>{t`Comments: ${post.commentCount}`}</H4>
        </ReactionsCommentsSection>
        <ContentSection>{post.content}</ContentSection>
        <ImageContainer>
          {post.imageUrl && <img src={post.imageUrl} alt="Post image" />}
        </ImageContainer>
        {!isRecycleForm && (
          <StyledButton
            label={t`Recycle Post`}
            variant="main"
            fullWidth
            size="medium"
            startIcon={<ResetIcon />}
            onClick={handleRecycleClick}
          />
        )}
      </CardContent>
      {isRecycleForm && (
        <Change
          onClick={() => {
            if (setIsRecycling) {
              setIsRecycling(false);
            }
          }}>
          <StyledEditIcon width={20} height={20} />
          {t`Change`}
        </Change>
      )}
    </Card>
  );
};

const Card = styled.div<{$isRecycleForm?: boolean}>`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  width: ${({$isRecycleForm}) => !$isRecycleForm && '24em'};
  height: ${({$isRecycleForm}) => (!$isRecycleForm ? '38em' : '32em')};
  display: flex;
  gap: 1em;
  position: relative;
  @media (max-width: ${isTablet}) {
    width: ${({$isRecycleForm}) => !$isRecycleForm && 'calc(100% - 1em)'};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Change = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.primary4.shade1};
  font-size: 0.875em;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  top: 2em;
  right: 2em;
`;

const StyledEditIcon = styled(EditIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;

const ContentSection = styled.div`
  font-size: 1.1em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.3em;
  white-space: pre-wrap;
  overflow-y: scroll;
`;

const ReactionsCommentsSection = styled.div`
  display: flex;
  gap: 1.5em;
  font-size: 1em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;

  img {
    max-width: 100%;
    max-height: 15em;
    border-radius: 1em;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 0.3em;
`;

const CardContent = styled.div<{$isRecycleForm?: boolean}>`
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: ${({$isRecycleForm}) =>
    $isRecycleForm ? '36.3em' : 'calc((100% - 2em))'};
`;
const H4 = styled.h4`
  margin: 0.3em 0;
  font-size: 1.05em;
`;
