import {t} from '@lingui/macro';
import {FunctionComponent, useContext} from 'react';
import styled from 'styled-components';
import {UserContext} from '../../../common/UserContext/user.context';
import {getInitials} from '../../../utils/getInitials';

interface Props {
  onClick: () => void;
}

export const MyStyle: FunctionComponent<Props> = ({onClick}) => {
  const {me} = useContext(UserContext);
  return (
    <Container onClick={onClick}>
      <Header>
        {me?.firstName}
        {t`'s style`}
      </Header>
      <Avatar>
        <Name>{getInitials(`${me?.firstName} ${me?.lastName}`)}</Name>
      </Avatar>
      <Div>{t`Redact AI will analyze your last posts to copy your writing style`}</Div>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1em;
  padding-top: 2em;
  width: 19.875em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2em;
  align-items: center;
  cursor: pointer;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.25em;
  letter-spacing: 0.0125em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Avatar = styled.div`
  height: 7.25em;
  width: 7.25em;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Name = styled.span`
  font-weight: 700;
  font-size: 2em;
`;
const Div = styled.div`
  padding: 0.75em;
  border-radius: 0.875em;
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 0.875em;
  line-height: 1.25em;
  letter-spacing: 0.0125em;
`;
