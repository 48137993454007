import {useQuery} from '@tanstack/react-query';
import {recyclePostMock} from './recyclePost.mock';
import {fetchApi} from '../../utils/fetchApi';
import {useDebounce} from 'use-debounce';
import {Post} from '../../pages/recyclepost/RecyclePost';
import {isValidLinkedInProfileUrl} from '../../utils/isValidLinkedInProfileUrl';

export interface RecyclePostResponse {
  posts: Post[];
}

const STALE_TIME = 1000 * 60 * 5;
const GC_TIME = 1000 * 60 * 30;

export const usePostsToRecycle = (profileUrl: string) => {
  const [debouncedProfileUrl] = useDebounce(profileUrl, 1000);
  return useQuery<RecyclePostResponse, Error>({
    queryKey: ['postsToRecycle', debouncedProfileUrl],
    queryFn: async () => {
      const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development';
      const withAI = !isDevelopment || import.meta.env.VITE_WITH_AI === 'true';
      localStorage.setItem('recyclePostProfileUrl', debouncedProfileUrl);
      return withAI
        ? await fetchApi(
            `/recyclePost/listPosts?profileUrl=${debouncedProfileUrl}`,
          )
        : await recyclePostMock().json();
    },
    enabled: isValidLinkedInProfileUrl(debouncedProfileUrl),
    staleTime: STALE_TIME,
    gcTime: GC_TIME,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
  });
};
