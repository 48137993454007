import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Status} from '../atoms/Status';
import {t} from '@lingui/macro';
import {Button} from '../atoms/Button';
import {useNavigate} from 'react-router-dom';

export const CurrentSubscriptionFree: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <LeftContainer>
        <CurrentPlan>
          <StrongText>{t`Current plan`}</StrongText>
          <Status label={t`Free`} variant="info" />
        </CurrentPlan>
        <Text>{t`You do not have an active subscription.`}</Text>
      </LeftContainer>
      <Button
        onClick={() => navigate('/pricing')}
        variant="main"
        size="small"
        label={t`Try for free`}
      />
    </Container>
  );
};

const Container = styled.div`
  border-radius: 1.5em;
  padding: 1.5em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  box-sizing: border-box;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const Text = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1em;
  letter-spacing: 0.03125em;
`;
const CurrentPlan = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const StrongText = styled.span`
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
