import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useReducer,
} from 'react';
import styled from 'styled-components';
import {CancelSubscriptionPopupConfirmation} from '../../../components/molecules/popups/CancelSubscriptionPopupConfirmation';
import {
  CancelSubscriptionPopupReasonAsk,
  Reason,
} from '../../../components/molecules/popups/CancelSubscriptionPopupReasonAsk';
import {CancelSubscriptionPopupConfirmed} from '../../../components/molecules/popups/CancelSubscriptionPopupConfirmed';
import {DescribeReason} from '../../../components/molecules/popups/DescribeReason';
import {t} from '@lingui/macro';
import {useCancelSubscription} from '../../../hooks/subscription/useCancelSubscription';
import {useSnackbar} from 'notistack';
import {FilterSelectOption} from '../../recyclepost/components/FilterSelect';
import {CircularLoader} from '../../../components/atoms/CircularLoader';
import {useQueryClient} from '@tanstack/react-query';

interface Props {
  setIsCancelSubscription: Dispatch<SetStateAction<boolean>>;
}

interface State {
  step: string;
  selectedReason: FilterSelectOption<Reason> | null;
  otherReason: string;
}

enum CancelSubscriptionActionType {
  SHOW_REASON_ASK = 'SHOW_REASON_ASK',
  SHOW_CONFIRMED = 'SHOW_CONFIRMED',
  SHOW_OTHER = 'SHOW_OTHER',
  SET_REASON = 'SET_REASON',
  SET_OTHER_REASON = 'SET_OTHER_REASON',
}

interface CancelSubscriptionAction {
  type: CancelSubscriptionActionType;
  reason?: FilterSelectOption<Reason>;
  otherReason?: string;
}

const initialState: State = {
  step: 'CONFIRMATION',
  selectedReason: null,
  otherReason: '',
};

const reducer = (state: State, action: CancelSubscriptionAction): State => {
  switch (action.type) {
    case CancelSubscriptionActionType.SHOW_REASON_ASK:
      return {
        ...state,
        step: 'SHOW_REASON_ASK',
      };
    case CancelSubscriptionActionType.SHOW_CONFIRMED:
      return {
        ...state,
        step: 'SHOW_CONFIRMED',
      };
    case CancelSubscriptionActionType.SHOW_OTHER:
      return {
        ...state,
        step: 'SHOW_OTHER',
      };
    case CancelSubscriptionActionType.SET_REASON:
      return {...state, selectedReason: action.reason ? action.reason : null};
    case CancelSubscriptionActionType.SET_OTHER_REASON:
      return {
        ...state,
        otherReason: action.otherReason ? action.otherReason : '',
      };
    default:
      return state;
  }
};

export const CancelSubscription: FunctionComponent<Props> = ({
  setIsCancelSubscription,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const queryClient = useQueryClient();
  const {enqueueSnackbar} = useSnackbar();

  const {mutateAsync: cancelSubscription, isPending: loading} =
    useCancelSubscription(
      state.selectedReason?.value === Reason.OTHER
        ? Reason.OTHER
        : state.selectedReason?.value,
      state.selectedReason?.value === Reason.OTHER
        ? state.otherReason
        : undefined,
    );

  const onClose = () => {
    setIsCancelSubscription(false);
  };

  const onCancel = async () => {
    if (!state.selectedReason) {
      enqueueSnackbar(t`Please select a reason`, {variant: 'error'});
      return;
    }
    await cancelSubscription();
    dispatch({type: CancelSubscriptionActionType.SHOW_CONFIRMED});
  };

  const onSubmit = async () => {
    if (!state.otherReason) {
      enqueueSnackbar(t`Please type a reason`, {variant: 'error'});
      return;
    }
    await cancelSubscription();
    dispatch({type: CancelSubscriptionActionType.SHOW_CONFIRMED});
  };

  useEffect(() => {
    if (state.selectedReason?.value === Reason.OTHER) {
      dispatch({type: CancelSubscriptionActionType.SHOW_OTHER});
    }
  }, [state.selectedReason]);

  useEffect(() => {
    return () => {
      void (async () => {
        await queryClient.invalidateQueries({queryKey: ['subscription']});
      })();
    };
  });

  return (
    <PreviewPopupContainer>
      {loading ? (
        <CircularLoader />
      ) : (
        <>
          {state.step === 'CONFIRMATION' && (
            <CancelSubscriptionPopupConfirmation
              onClose={onClose}
              showReasonAsk={() =>
                dispatch({type: CancelSubscriptionActionType.SHOW_REASON_ASK})
              }
            />
          )}
          {state.step === 'SHOW_REASON_ASK' && (
            <CancelSubscriptionPopupReasonAsk
              onClose={onClose}
              onCancel={onCancel}
              setSelectedReason={(reason) =>
                dispatch({
                  type: CancelSubscriptionActionType.SET_REASON,
                  reason: reason ? reason : undefined,
                })
              }
              selectedReason={state.selectedReason}
            />
          )}
          {state.step === 'SHOW_CONFIRMED' && (
            <CancelSubscriptionPopupConfirmed onClose={onClose} />
          )}
          {state.step === 'SHOW_OTHER' && (
            <DescribeReason
              onClose={onClose}
              onSubmit={onSubmit}
              setOtherReason={(otherReason) =>
                dispatch({
                  type: CancelSubscriptionActionType.SET_OTHER_REASON,
                  otherReason,
                })
              }
              otherReason={state.otherReason}
            />
          )}
        </>
      )}
    </PreviewPopupContainer>
  );
};
const PreviewPopupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000099;
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 60;
`;
