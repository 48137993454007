import {FunctionComponent} from 'react';
import styled from 'styled-components';

interface Props {
  variant: 'monthly' | 'annually';
  onClick?: () => void;
}

export const ButtonTab: FunctionComponent<Props> = ({variant, onClick}) => {
  return (
    <StyledButtonTab onClick={onClick}>
      <StyledButton $variant="monthly" $active={variant === 'monthly'}>
        {`Monthly`}
      </StyledButton>
      <StyledButton $variant="annually" $active={variant === 'annually'}>
        {`Annually`}
      </StyledButton>
    </StyledButtonTab>
  );
};

const StyledButtonTab = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  padding: 0.25em;
  display: flex;
  align-items: center;
  gap: 0.25em;
  border-radius: 1em;
`;

const StyledButton = styled.button<{
  $variant: Props['variant'];
  $active: boolean;
}>`
  ${({theme, $active}) => `
    background-color: ${
      $active ? theme.colors.neutral.shade1 : theme.colors.neutral.shade5
    };
    color: ${
      !$active ? theme.colors.neutral.shade8 : theme.colors.neutral.shade11
    };
    border: none;
    border-radius: 0.75em;
    font-weight: 600;
    letter-spacing: 0.0125em;
    padding: 0.5em 0;
    line-height: 1.5em;
    cursor: pointer;
    transition: all 0.3s;
    width: 9.25em;
  `}
`;
