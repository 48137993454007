export const fetchApi = async (
  path: string,
  options?: RequestInit,
  errorMessage?: string,
) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}${path}`,
    options,
  );
  if (!response.ok) {
    throw new Error(errorMessage || response.statusText);
  }
  return response.json();
};

export const postApi = async (
  path: string,
  options?: RequestInit,
  errorMessage?: string,
) => {
  const response = await fetch(`${import.meta.env.VITE_API_URL}${path}`, {
    method: 'POST',
    ...options,
  });
  if (!response.ok) {
    throw new Error(errorMessage || response.statusText);
  }
  return response;
};

export const deleteApi = async (
  path: string,
  options?: RequestInit,
  errorMessage?: string,
) => {
  const response = await fetch(`${import.meta.env.VITE_API_URL}${path}`, {
    method: 'DELETE',
    ...options,
  });
  if (!response.ok) {
    throw new Error(errorMessage || response.statusText);
  }
  return response;
};
