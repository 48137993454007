import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import EmailVerification from 'supertokens-web-js/recipe/emailverification';
import {t} from '@lingui/macro';
import {ErrorIcon} from '../icons/ErrorIcon';
import {useSnackbar} from 'notistack';
import {BackgroundStripe} from '../../atoms/Images/BackgroundStripe.tsx';
import {useNavigate} from 'react-router-dom';
import {VerificationCheck} from '../icons/VerificationCheck';

export const EmailVerificationHandler: React.FunctionComponent = () => {
  const STATUS_MESSAGES = {
    success: t`Your email has been successfully verified!`,
    error: t`An error occurred during email verification.`,
    invalidLink: t`Invalid verification link.`,
    loading: t`Verifying your email...`,
  };

  const [status, setStatus] = useState<string>(STATUS_MESSAGES.loading);
  const location = useLocation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const verificationAttempted = useRef(false);

  const verifyEmailToken = async (token: string): Promise<string> => {
    try {
      const response = await EmailVerification.verifyEmail({
        userContext: {token},
      });
      return response.status === 'OK'
        ? STATUS_MESSAGES.success
        : STATUS_MESSAGES.error;
    } catch (error) {
      return STATUS_MESSAGES.error;
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    if (!token) {
      setStatus(STATUS_MESSAGES.invalidLink);
      return;
    }

    if (!verificationAttempted.current) {
      verificationAttempted.current = true;
      verifyEmailToken(token).then((result) => {
        setStatus(result);
        if (result === STATUS_MESSAGES.success) {
          setTimeout(() => {
            navigate('/onboarding');
          }, 3000);
        }
      });
    }
  }, [location.search, navigate]);

  useEffect(() => {
    if (status === STATUS_MESSAGES.success) {
      enqueueSnackbar(status, {variant: 'success'});
    }
    if (
      status === STATUS_MESSAGES.error ||
      status === STATUS_MESSAGES.invalidLink
    ) {
      enqueueSnackbar(status, {variant: 'error'});
    }
  }, [status, enqueueSnackbar]);

  if (status === STATUS_MESSAGES.loading) {
    return (
      <PageContainer>
        <BackgroundStripesStyled />
        <VerificationContainer>
          <Title>{t`Verifying Email`}</Title>
          <Message>{STATUS_MESSAGES.loading}</Message>
        </VerificationContainer>
      </PageContainer>
    );
  }

  if (status === STATUS_MESSAGES.success) {
    return (
      <PageContainer>
        <BackgroundStripesStyled />
        <VerificationContainer>
          <StyledVerificationCheck />
          <Title>{t`Email Verification Successful`}</Title>
          <SuccessMessage>{STATUS_MESSAGES.success}</SuccessMessage>
          <Message>{t`You will be redirected to the onboarding page shortly.`}</Message>
        </VerificationContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <BackgroundStripesStyled />
      <VerificationContainer>
        <StyledErrorIcon />
        <Title>{t`Email Verification Failed`}</Title>
        <ErrorMessage>{status}</ErrorMessage>
        <Message>
          {t`Please contact the support by chat on the bottom right corner for help.`}
        </Message>
      </VerificationContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const BackgroundStripesStyled = styled(BackgroundStripe)`
  position: fixed;
  bottom: 10em;
  right: 0em;
`;

const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em;
  background: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  box-shadow: 0 4px 12px ${({theme}) => theme.colors.neutral.shade5};
  width: 27em;
  z-index: 1;
`;

const Title = styled.h2`
  font-size: 2.3em;
  font-weight: 700;
  text-align: center;
`;

const ErrorMessage = styled.p`
  font-size: 1em;
  color: ${({theme}) => theme.colors.primary2.shade3};
  text-align: center;
  margin: 0em;
`;

const SuccessMessage = styled.p`
  font-size: 1em;
  color: ${({theme}) => theme.colors.primary3.shade2};
  text-align: center;
  margin: 0em;
`;

const Message = styled.p`
  font-size: 1.3em;
  font-weight: 500;
  color: ${({theme}) => theme.colors.neutral.shade8};
  text-align: center;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  align-self: center;
`;

const StyledVerificationCheck = styled(VerificationCheck)`
  align-self: center;
`;
