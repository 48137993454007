import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Button} from '../atoms/Button';
import ArrowRight from '../atoms/Icons/ArrowRight';
import DoneIcon from '../atoms/Icons/DoneIcon';
import Yohan from '../molecules/images/Yohan.png';
import {t} from '@lingui/macro';
import {useTheme} from '../theme/theme';
import {PricingSlider} from '../atoms/PricingSlider';
import {ProductPeriod} from '../../hooks/subscription/useSubscription';
import {Product} from '../../hooks/pricing/useProducts';

interface UpgradePricingCardProps {
  productsToDisplay: Product[];
  chosenSliderProduct: Product;
  redirectToCheckout: (productId: string) => void;
  priceToString: (price: number, period?: ProductPeriod) => string;
  chosenPostsPerMonth: number;
  setChosenPostsPerMonth: (value: number) => void;
  chosenPostsPerMonthMarks: {value: number; label: string}[];
  productToPricePerPost: (product: Product) => string;
}

const Footer: FunctionComponent = () => {
  const theme = useTheme();
  return (
    <Col>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`High quality post generation`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`Copy writing style from any content creator`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`Generate all your posts in minutes`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} width={24} height={23} />
        <Text>{t`Repurpose any piece of content to a LinkedIn post`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`Find original and up-to-date post ideas`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`Multilingual support`}</Text>
      </Row>
      <Row>
        <DoneIcon color={theme.colors.neutral.shade11} />
        <Text>{t`Dedicated customer support`}</Text>
      </Row>
    </Col>
  );
};

export const UpgradePricingCard: FunctionComponent<UpgradePricingCardProps> = ({
  productsToDisplay,
  chosenSliderProduct,
  redirectToCheckout,
  priceToString,
  chosenPostsPerMonth,
  setChosenPostsPerMonth,
  chosenPostsPerMonthMarks,
  productToPricePerPost,
}) => {
  return (
    <Container>
      {productsToDisplay.map((product, index) => (
        <UpgradeCard key={index}>
          <div>
            <Header>{t`Essential`}</Header>
            <TrialPrice>
              {priceToString(product.price, product.period)}€ <span>/ mo</span>
            </TrialPrice>
            <Text>
              <StrongText>
                {t`${priceToString(product.price)} euros billed` +
                  ` ` +
                  (product.period === ProductPeriod.YEARLY
                    ? t`annually`
                    : t`monthly`)}
              </StrongText>
              <br />
              {t`excluding taxes | Cancel anytime`}
            </Text>
          </div>
          <PricingSlider
            showSlider={false}
            chosenPostsPerMonth={chosenPostsPerMonth}
            setChosenPostsPerMonth={setChosenPostsPerMonth}
            chosenPostsPerMonthMarks={chosenPostsPerMonthMarks}
            productToPricePerPost={productToPricePerPost}
            chosenSliderProduct={chosenSliderProduct}
          />
          <Button
            onClick={() => redirectToCheckout(product.id)}
            label={t`Upgrade`}
            size="medium"
            variant="main"
            fullWidth
            endIcon={<ArrowRight height={18} width={18} />}
          />
          <Footer />
        </UpgradeCard>
      ))}
      <UpgradeCard>
        <div>
          <Header>{t`Creator`}</Header>
          <TrialPrice>
            {priceToString(
              chosenSliderProduct.price,
              chosenSliderProduct.period,
            )}
            € <span>/ mo</span>
          </TrialPrice>
          <Text>
            <StrongText>
              {t`${priceToString(chosenSliderProduct.price)} euros billed` +
                ` ` +
                (chosenSliderProduct.period === ProductPeriod.YEARLY
                  ? t`annually`
                  : t`monthly`)}
            </StrongText>
            <br />
            {t`excluding taxes`}
          </Text>
        </div>
        <PricingSlider
          chosenPostsPerMonth={chosenPostsPerMonth}
          setChosenPostsPerMonth={setChosenPostsPerMonth}
          chosenPostsPerMonthMarks={chosenPostsPerMonthMarks}
          productToPricePerPost={productToPricePerPost}
          chosenSliderProduct={chosenSliderProduct}
        />
        <Button
          onClick={() => redirectToCheckout(chosenSliderProduct.id)}
          label={t`Upgrade`}
          size="medium"
          variant="main"
          fullWidth
          endIcon={<ArrowRight height={18} width={18} />}
        />
        <Footer />
      </UpgradeCard>
      <TalkCard>
        <div>
          <Header>{t`Let's Talk`}</Header>
          <TrialPrice>{t`Learn about RedactAI`}</TrialPrice>
          <Text>
            {t`For `}{' '}
            <StrongText>
              {t`copywriters, agencies or `} <br />
              {t`companies`}
            </StrongText>
          </Text>
        </div>
        <Button
          onClick={() =>
            window.open(
              'https://calendly.com/yohan-fvs/presentation-redactai?month=2024-07',
            )
          }
          label={t`Quick demo with Yohan`}
          size="medium"
          variant="main"
          fullWidth
          startIcon={<StyeldImage src={Yohan} alt="Yohan" />}
          endIcon={<ArrowRight height={18} width={18} />}
        />
        <Footer />
      </TalkCard>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const UpgradeCard = styled.div`
  width: 20.7em;
  padding: 1.5em;
  border-radius: 1.5em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;

const TalkCard = styled.div`
  width: 20.7em;
  padding: 1.5em;
  border-radius: 1.5em;
  background-color: ${({theme}) => theme.colors.neutral.shade2};
  border: 2px solid ${({theme}) => theme.colors.primary1.shade2};
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;

const Header = styled.p`
  font-size: 2em;
  letter-spacing: -0.03125em;
  font-weight: 700;
  margin: 0;
`;

const TrialPrice = styled.p`
  font-size: 1.6em;
  font-weight: 700;
  & span {
    font-size: 0.7em;
    font-weight: 400;
  }
`;

const Text = styled.p`
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0.03125em;
  margin: 0;
`;

const StrongText = styled.span`
  font-weight: 700;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const Row = styled.div`
  display: flex;
  gap: 0.75em;
`;

const StyeldImage = styled.img`
  height: 1.5em;
  width: 1.5em;
`;
