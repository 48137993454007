import {FunctionComponent, useCallback, useState} from 'react';
import styled from 'styled-components';
import {Status} from '../atoms/Status';
import EditIcon from '../atoms/Icons/EditIcon';
import {format} from 'date-fns';
import {t} from '@lingui/macro';
import {isTablet} from '../../utils/responsive';
import {useNavigate} from 'react-router-dom';
import {CancelSubscription} from '../../pages/settings/components/CancelSubscription';
import {useRedirectToCustomerPortal} from '../../hooks/subscription/useRedirectToCustomerPortal.ts';
import {useUserContext} from '../../common/UserContext/useUserContext.ts';

interface Props {
  currentPlan: 'Annual' | 'Monthly';
  renewalDate: Date;
  posts: number;
}

export const CurrentSubscription: FunctionComponent<Props> = ({
  currentPlan,
  renewalDate,
  posts,
}) => {
  const navigate = useNavigate();
  const [isCancelSubscription, setIsCancelSubscription] =
    useState<boolean>(false);
  const {me} = useUserContext();

  const redirectToCustomerPortal = useRedirectToCustomerPortal();

  const onEditPlan = useCallback(() => {
    if (me?.stripeCustomerId) {
      redirectToCustomerPortal();
      return;
    }
    navigate('/pricing');
  }, [me?.stripeCustomerId, navigate, redirectToCustomerPortal]);

  const onCancel = useCallback(() => {
    // setIsCancelSubscription(true);
    onEditPlan();
  }, [onEditPlan]);

  return (
    <Container>
      <FlexRow>
        <CurrentPlan>
          <StrongText>{t`Current plan`}</StrongText>
          <Status
            label={currentPlan === 'Annual' ? t`Annual` : t`Monthly`}
            variant="info"
          />
        </CurrentPlan>
        <ActionsWrapperDesktop>
          <Action onClick={onEditPlan}>
            <EditIcon /> {t`Edit plan`}
          </Action>
          <Line />
          <Action onClick={onCancel}>{t`Cancel subscription`}</Action>
        </ActionsWrapperDesktop>
      </FlexRow>
      <div>
        <RenewalDate>
          <StrongText>
            {posts} {posts > 1 ? t`posts` : t`post`}{' '}
          </StrongText>
          {t`per month.`}
        </RenewalDate>
        <RenewalDate>
          {t`Your plan will be renewed on`}
          <StrongText> {format(renewalDate, 'do MMMM yyyy')}</StrongText>
        </RenewalDate>
      </div>
      <ActionsWrapperMobile>
        <Action onClick={onEditPlan}>
          <EditIcon /> {t`Edit plan`}
        </Action>
        <Line />
        <Action onClick={onCancel}>{t`Cancel subscription`}</Action>
      </ActionsWrapperMobile>
      {isCancelSubscription && (
        <CancelSubscription setIsCancelSubscription={setIsCancelSubscription} />
      )}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 1.5em;
  padding: 1.5em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  display: flex;
  flex-direction: column;
  gap: 1em;
  box-sizing: border-box;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CurrentPlan = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const ActionsWrapperDesktop = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
const ActionsWrapperMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  @media (min-width: ${isTablet}) {
    display: none;
  }
`;
const Action = styled.span`
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.25em;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.primary4.shade1};
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
`;

const RenewalDate = styled.div`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1em;
  letter-spacing: 0.03125em;
  line-height: 1.5em;
  @media (max-width: ${isTablet}) {
  }
`;
const StrongText = styled.span`
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Line = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  width: 1px;
  height: 11px;
`;
