import {FunctionComponent} from 'react';

interface Props {
  width?: number;
  height?: number;
}

const WebsiteIcon: FunctionComponent<Props> = ({width = 25, height = 24}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.29 20.76L21.57 20.04C21.94 19.48 22.16 18.8 22.16 18.08C22.16 16.1 20.56 14.5 18.58 14.5C16.6 14.5 15 16.1 15 18.08C15 20.06 16.6 21.66 18.58 21.66C19.31 21.66 19.98 21.44 20.54 21.07L21.26 21.79C21.4 21.93 21.59 22 21.77 22C21.96 22 22.14 21.93 22.28 21.79C22.57 21.5 22.57 21.04 22.29 20.76Z"
        fill="#B7761E"
      />
      <path
        opacity="0.4"
        d="M2.53001 14.6289C2.53001 14.6589 2.51001 14.6989 2.51001 14.7289C3.43001 16.5689 4.93002 18.0789 6.77002 18.9889C6.80002 18.9889 6.84001 18.9689 6.87001 18.9689C6.53001 17.8089 6.27002 16.6189 6.08002 15.4289C4.88002 15.2289 3.69001 14.9689 2.53001 14.6289Z"
        fill="#B7761E"
      />
      <path
        opacity="0.4"
        d="M19.57 6.4314C18.63 4.4614 17.04 2.87141 15.08 1.94141C15.44 3.13141 15.74 4.35141 15.94 5.57141C17.16 5.77141 18.38 6.0614 19.57 6.4314Z"
        fill="#B7761E"
      />
      <path
        opacity="0.4"
        d="M2.42993 6.42953C3.62993 6.06953 4.84995 5.76955 6.06995 5.56955C6.26995 4.37955 6.51994 3.19955 6.85994 2.03955C6.82994 2.03955 6.78993 2.01953 6.75993 2.01953C4.87993 2.94953 3.34993 4.51954 2.42993 6.42953Z"
        fill="#B7761E"
      />
      <path
        opacity="0.4"
        d="M14.3199 5.35828C14.0799 4.05828 13.7799 2.75829 13.3499 1.49829C13.3299 1.42829 13.3299 1.3683 13.3199 1.2883C12.5799 1.1083 11.7999 0.988281 10.9999 0.988281C10.1899 0.988281 9.41994 1.0983 8.66994 1.2883C8.65994 1.3583 8.66993 1.41829 8.64993 1.49829C8.22993 2.75829 7.91993 4.05828 7.67993 5.35828C9.88993 5.11828 12.1099 5.11828 14.3199 5.35828Z"
        fill="#B7761E"
      />
      <path
        opacity="0.4"
        d="M5.85999 7.17969C4.54999 7.41969 3.25998 7.71969 1.99998 8.14969C1.92998 8.16969 1.86998 8.16969 1.78998 8.17969C1.60998 8.91969 1.48999 9.69969 1.48999 10.4997C1.48999 11.3097 1.59998 12.0797 1.78998 12.8297C1.85998 12.8397 1.91998 12.8297 1.99998 12.8497C3.25998 13.2697 4.54999 13.5797 5.85999 13.8197C5.61999 11.6097 5.61999 9.38969 5.85999 7.17969Z"
        fill="#B7761E"
      />
      <path
        opacity="0.4"
        d="M20.2 8.17969C20.13 8.17969 20.07 8.16969 19.99 8.14969C18.73 7.72969 17.43 7.41969 16.13 7.17969C16.38 9.38969 16.38 11.6097 16.13 13.8097C17.43 13.5697 18.73 13.2697 19.99 12.8397C20.06 12.8197 20.12 12.8297 20.2 12.8197C20.38 12.0697 20.5 11.2997 20.5 10.4897C20.5 9.69969 20.39 8.92969 20.2 8.17969Z"
        fill="#B7761E"
      />
      <path
        opacity="0.4"
        d="M7.67993 15.6406C7.91993 16.9506 8.21993 18.2406 8.64993 19.5006C8.66993 19.5706 8.65994 19.6306 8.66994 19.7106C9.41994 19.8906 10.1899 20.0106 10.9999 20.0106C11.7999 20.0106 12.5799 19.9006 13.3199 19.7106C13.3299 19.6406 13.3299 19.5806 13.3499 19.5006C13.7699 18.2406 14.0799 16.9506 14.3199 15.6406C13.2199 15.7606 12.1099 15.8506 10.9999 15.8506C9.88994 15.8406 8.77993 15.7606 7.67993 15.6406Z"
        fill="#B7761E"
      />
      <path
        opacity="0.4"
        d="M7.44998 6.95156C7.14998 9.31156 7.14998 11.6915 7.44998 14.0615C9.80998 14.3615 12.19 14.3615 14.56 14.0615C14.86 11.7015 14.86 9.32156 14.56 6.95156C12.19 6.65156 9.80998 6.65156 7.44998 6.95156Z"
        fill="#B7761E"
      />
    </svg>
  );
};

export default WebsiteIcon;
