import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import {PricingCard} from '../molecules/PricingCard';
import {ButtonTab} from '../atoms/ButtonTab';
import {Toast} from '../atoms/Toast';
import {Chip} from '../atoms/Chip';
import {UpgradePricingCard} from '../molecules/UpgradePricingCard';
import ArrowIndicator from './images/ArrowIndicator';
import {t} from '@lingui/macro';
import {ProductPeriod} from '../../hooks/subscription/useSubscription';
import {Product, useProducts} from '../../hooks/pricing/useProducts';
import {UserContext} from '../../common/UserContext/user.context';
import {useCreateCheckoutSession} from '../../hooks/pricing/createCheckoutSession';
import {ThreeDotsLoader} from '../atoms/ThreeDotsLoader';

const priceToString = (price: number, period?: ProductPeriod) =>
  price === 0
    ? '0'
    : (price / 100 / (period === ProductPeriod.YEARLY ? 12 : 1)).toFixed(1) +
      '0';

const productToPricePerPost = (product: Product) => {
  return product.price === 0
    ? '0'
    : (
        product.price /
        100 /
        (product.period === ProductPeriod.YEARLY ? 12 : 1) /
        product.posts
      ).toFixed(1) + '0';
};

export const PricingTable: FunctionComponent = () => {
  const {me} = useContext(UserContext);
  const {products, loading} = useProducts();
  const [showYearly, setShowYearly] = useState<boolean>(true);
  const [chosenPostsPerMonth, setChosenPostsPerMonth] = useState<number>(0);

  const productsToDisplay = useMemo(
    () =>
      !me
        ? []
        : products
            .filter(
              (product) =>
                product.period ===
                (showYearly ? ProductPeriod.YEARLY : ProductPeriod.MONTHLY),
            )
            .sort((a, b) => a.price - b.price)
            .filter((_, index) => (!me?.stripeCustomerId ? index === 0 : true))
            .filter((product) => !product.isCreator),
    [me, products, showYearly],
  );

  const sliderProducts: Product[] = useMemo(() => {
    return products
      .sort((a, b) => a.price - b.price)
      .filter(
        (product) =>
          product.isCreator &&
          product.period ===
            (showYearly ? ProductPeriod.YEARLY : ProductPeriod.MONTHLY),
      );
  }, [products, showYearly]);

  const chosenPostsPerMonthMarks = useMemo(() => {
    return sliderProducts.map((product, index) => ({
      value: index,
      label: `${product.posts}`,
    }));
  }, [sliderProducts]);

  const chosenSliderProduct = useMemo(() => {
    return sliderProducts[chosenPostsPerMonth];
  }, [sliderProducts, chosenPostsPerMonth]);

  const createCheckoutSession = useCreateCheckoutSession();

  const redirectToCheckout = useCallback(
    async (productId: string) => {
      await createCheckoutSession.mutate({
        productId,
      });
    },
    [createCheckoutSession],
  );

  useEffect(() => {
    if (!createCheckoutSession.isSuccess) {
      return;
    }
    void (async () => {
      window.location.href = await createCheckoutSession.data.text();
    })();
  }, [createCheckoutSession.data, createCheckoutSession.isSuccess]);
  return (
    <Container>
      {!me?.stripeCustomerId ? (
        <>
          <Header>
            {t`Start your`} <span>{t`7 days free trial`}</span>
          </Header>
          <Subheader>
            {t`Create your LinkedIn posts in 30 seconds.`}{' '}
            <span>{t`You can cancel anytime.`}</span>
          </Subheader>
          <ButtonTabWrapper>
            <StyledChip label={t`2 months FREE`} variant="success" />
            <StyledArrowIndicator />
            <ButtonTab
              variant={showYearly ? 'annually' : 'monthly'}
              onClick={() => setShowYearly(!showYearly)}
            />
          </ButtonTabWrapper>
          {showYearly && (
            <Toast
              message={t`You are getting 2 months FREE with annual subscription`}
              variant="success"
            />
          )}
          {!loading ? (
            <PricingCard
              productsToDisplay={productsToDisplay}
              priceToString={priceToString}
              redirectToCheckout={redirectToCheckout}
            />
          ) : (
            <ThreeDotsLoader />
          )}
        </>
      ) : (
        <>
          <Header>{t`Upgrade your account`}</Header>
          <Subheader>
            {t`Choose the plan that works best for you. You can always upgrade later.`}
          </Subheader>
          <ButtonTabWrapper>
            <StyledChip label={t`2 months FREE`} variant="success" />
            <StyledArrowIndicator />
            <ButtonTab
              variant={showYearly ? 'annually' : 'monthly'}
              onClick={() => setShowYearly(!showYearly)}
            />
          </ButtonTabWrapper>
          {showYearly && (
            <Toast
              message={t`You are getting 2 months FREE with annual subscription`}
              variant="success"
            />
          )}
          {!loading ? (
            <UpgradePricingCard
              productsToDisplay={productsToDisplay}
              chosenSliderProduct={chosenSliderProduct}
              redirectToCheckout={redirectToCheckout}
              priceToString={priceToString}
              chosenPostsPerMonth={chosenPostsPerMonth}
              setChosenPostsPerMonth={setChosenPostsPerMonth}
              chosenPostsPerMonthMarks={chosenPostsPerMonthMarks}
              productToPricePerPost={productToPricePerPost}
            />
          ) : (
            <ThreeDotsLoader />
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({theme}) => theme.colors.neutral.shade11};
  gap: 1.5em;
`;

const Header = styled.div`
  font-size: 3.2em;
  text-align: center;
  font-weight: 700;
  span {
    color: ${({theme}) => theme.colors.primary1.shade2};
  }
`;

const Subheader = styled.div`
  text-align: center;
  font-size: 1.5em;
  font-weight: 600;
  span {
    color: ${({theme}) => theme.colors.neutral.shade8};
  }
`;

const ButtonTabWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 1em;
`;

const StyledArrowIndicator = styled(ArrowIndicator)`
  position: absolute;
  top: -1.8em;
  left: 93%;
`;

const StyledChip = styled(Chip)`
  position: absolute;
  top: -1.8em;
  left: 50%;
  transform: translateX(0.8em);
`;
