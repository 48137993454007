import {useQuery} from '@tanstack/react-query';
import {fetchApi} from '../../utils/fetchApi';
import {useMemo} from 'react';
import {ProductPeriod} from '../subscription/useSubscription';

export interface Product {
  id: string;
  name: string;
  price: number;
  credits: number;
  posts: number;
  ideas: number;
  isFreeTrial?: boolean;
  period: ProductPeriod;
  isCreator: boolean;
}

export const useProducts = () => {
  const {data, isLoading, refetch} = useQuery({
    queryKey: ['products'],
    queryFn: () => fetchApi(`/products`),
  });

  const products: Product[] = useMemo(() => (!data ? [] : data), [data]);

  return {
    products,
    loading: isLoading,
    refetch,
  };
};
