import {useMutation} from '@tanstack/react-query';
import {postApi} from '../../utils/fetchApi';
import {t} from '@lingui/macro';
import {useEffect, useState} from 'react';

export const useCreateCheckoutSession = () => {
  const [couponId, setCouponId] = useState<string>('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    rewardful('ready', function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setCouponId(Rewardful.coupon.id);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    });
  }, []);

  return useMutation({
    mutationFn: async (data: {productId: string}) => {
      return await postApi(
        '/stripe/createCheckoutSession',
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...data,
            couponId,
          }),
        },
        t`Please check if all the fields are filled correctly`,
      );
    },
  });
};
