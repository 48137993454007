import {useMemo} from 'react';
import {t} from '@lingui/macro';
import {SavedPostTone} from './savedPost/savedPost.types';

export const usePostToneOptions = () => {
  const postToneOptions = useMemo(
    () => [
      {
        name: t`Informal / Cool`,
        value: SavedPostTone.INFORMAL_COOL,
      },
      {
        name: t`Storytelling / Inspirational`,
        value: SavedPostTone.STORYTELLING_INSPIRATIONAL,
      },
      {
        name: t`Sales / Persuasive`,
        value: SavedPostTone.SALES_PERSUASIVE,
      },
      {
        name: t`Analytical / Pedagogical`,
        value: SavedPostTone.ANALYTICAL_PEDAGOGICAL,
      },
      {
        name: t`Truth / Arrogant`,
        value: SavedPostTone.TRUTH_ARROGANT,
      },
      {
        name: t`Formal / Serious`,
        value: SavedPostTone.FORMAL_SERIOUS,
      },
    ],
    [],
  );

  return postToneOptions;
};
