import {FunctionComponent, ReactNode} from 'react';
import styled from 'styled-components';

interface Props {
  label: string;
  fullWidth?: boolean;
  variant: 'main' | 'light' | 'inverted' | 'outlined';
  size: 'big' | 'medium' | 'small';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onClick?: () => void;
  className?: string;
}

export const Button: FunctionComponent<Props> = ({
  label,
  fullWidth,
  variant,
  size,
  startIcon,
  endIcon,
  onClick,
  className,
}) => {
  return (
    <StyledButton
      className={className}
      $variant={variant}
      $size={size}
      $fullWidth={fullWidth}
      onClick={onClick}>
      {startIcon && startIcon}
      {label}
      {endIcon && endIcon}
    </StyledButton>
  );
};

const StyledButton = styled.button<{
  $variant: Props['variant'];
  $size?: Props['size'];
  $fullWidth?: Props['fullWidth'];
}>`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625em;
  border: none;
  cursor: pointer;
  margin: 0;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.2px;
  line-height: 1.5em;
  font-size: 1em;
  border-radius: 1em;
  padding: 0.9375em 2.375em;

  &:hover {
    transform: translateY(-2px) scale(1.02);
  }

  ${({theme, $variant}) => {
    switch ($variant) {
      case 'main':
        return `
            background-color: ${theme.colors.primary1.shade2};
            color: ${theme.colors.neutral.shade11};
          `;
      case 'light':
        return `
            background-color: ${theme.colors.neutral.shade1};
            border: 1px solid ${theme.colors.neutral.shade4};
            color: ${theme.colors.neutral.shade11};
          `;
      case 'inverted':
        return `
            background-color: ${theme.colors.primary1.shade4};
            border: 1px solid ${theme.colors.primary1.shade2};
            color: ${theme.colors.neutral.shade11};
          `;
      case 'outlined':
        return `
            background-color: ${theme.colors.neutral.shade1};
            border: 1px solid ${theme.colors.primary1.shade2};
            color: ${theme.colors.neutral.shade11};
          `;
    }
  }}

  ${({$size, $fullWidth}) => {
    switch ($size) {
      case 'big':
        return `
        width: ${!$fullWidth ? `27.625em` : `100%`};
            `;
      case 'medium':
        return `
        padding: 0.8125em 1.375em;
        border-radius: 0.875em;
            `;
      case 'small':
        return `
        padding: 0.4375em 0.875em;
        border-radius: 0.75em;
            `;
    }
  }}
`;
