import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';
import {fetchApi} from '../../utils/fetchApi';
import {SavedIdeaInterface} from '../../pages/findIdea/components/MyIdeasTab';
import {savedIdeasMock} from '../findIdeas/savedIdeas.mock';

export const useSavedIdeas = () => {
  const {data, isLoading, refetch} = useQuery({
    queryKey: ['savedIdeas'],
    queryFn: async () => {
      const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development';
      const withAI = !isDevelopment || import.meta.env.VITE_WITH_AI === 'true';
      return withAI ? await fetchApi(`/savedIdeas`) : await savedIdeasMock();
    },
  });

  const savedIdeas: SavedIdeaInterface[] = useMemo(
    () =>
      !data
        ? []
        : data.map((element: SavedIdeaInterface) => ({
            ...element,
            createdAt: new Date(element?.createdAt),
          })),
    [data],
  );

  return {
    savedIdeas,
    loading: isLoading,
    refetch,
  };
};
