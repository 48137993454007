import {FunctionComponent, useMemo} from 'react';
import styled from 'styled-components';
import {Modal} from './Modal';
import {t} from '@lingui/macro';
import {Button} from '../../atoms/Button';
import {isTablet} from '../../../utils/responsive';
import {
  FilterSelect,
  FilterSelectOption,
} from '../../../pages/recyclepost/components/FilterSelect';

interface Props {
  onClose: () => void;
  onCancel: () => void;
  setSelectedReason: (reason: FilterSelectOption<Reason> | null) => void;
  selectedReason: FilterSelectOption<Reason> | null;
}

export enum Reason {
  HIGH_COST = 'HIGH_COST',
  LOW_USAGE = 'LOW_USAGE',
  EXPECTATIONS = 'EXPECTATIONS',
  ASSISTANCE = 'ASSISTANCE',
  SHORT_TERM = 'SHORT_TERM',
  OTHER = 'OTHER',
}

export const CancelSubscriptionPopupReasonAsk: FunctionComponent<Props> = ({
  onClose,
  onCancel,
  setSelectedReason,
  selectedReason,
}) => {
  const reasons = useMemo<FilterSelectOption<Reason>[]>(
    () => [
      {
        label: t`The subscription cost is too high for my budget.`,
        value: Reason.HIGH_COST,
      },
      {
        label: t`I'm not using the product as much as I thought I would.`,
        value: Reason.LOW_USAGE,
      },
      {
        label: t`The product did not meet my expectations.`,
        value: Reason.EXPECTATIONS,
      },
      {
        label: t`I did not receive adequate assistance when I had a problem.`,
        value: Reason.ASSISTANCE,
      },
      {
        label: t`I only needed the product for a short-term project`,
        value: Reason.SHORT_TERM,
      },
      {
        label: t`Other`,
        value: Reason.OTHER,
      },
    ],
    [],
  );

  return (
    <Modal onClose={onClose}>
      <Container>
        <Emoji>😶</Emoji>
        <StrongText>{t`We'd be sad to see you go`}</StrongText>
        <Col>
          <GrayText>{t`Please let us know why exactly you want to leave?`}</GrayText>
          <FilterSelect
            selectedOption={
              selectedReason || {
                label: t`Select a reason`,
                value: Reason.HIGH_COST,
              }
            }
            setSelectedOption={setSelectedReason}
            options={reasons}
          />
        </Col>
        <Row>
          <Button
            onClick={onCancel}
            label={t`Cancel Plan`}
            size="medium"
            variant="light"
            fullWidth
          />
          <StyledButton
            onClick={onClose}
            label={t`Stay with us and support our families`}
            size="medium"
            variant="main"
            fullWidth
          />
        </Row>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  width: 34em;
  @media (max-width: ${isTablet}) {
    width: 26em;
  }
`;
const StrongText = styled.span`
  font-weight: 700;
  font-size: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
  width: 14em;
`;
const Row = styled.div`
  display: flex;
  gap: 2em;
  width: 100%;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;
const Emoji = styled.span`
  font-size: 4em;
  margin-bottom: -0.1em;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  width: 100%;
  position: relative;
`;
const GrayText = styled.span`
  font-weight: 600;
  font-size: 1em;
  color: ${({theme}) => theme.colors.neutral.shade9};
  text-align: center;
  letter-spacing: 0.0125em;
`;
const StyledButton = styled(Button)`
  width: 100%;
`;
