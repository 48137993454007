import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {Page} from '../../components/templates/Page';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {LanguageSelect} from '../../components/atoms/LanguageSelect';
import useDefaultLanguage, {
  settingLanguages,
} from '../../hooks/postLang/useDefaultLanguage';
import {LightTab} from '../../components/atoms/LightTab';
import {ProfileHeader} from '../../components/molecules/ProfileHeader';
import {UserContext} from '../../common/UserContext/user.context';
import {ProfileInformationCard} from '../../components/molecules/ProfileInformationCard';
import {SettingsSubscription} from './components/SettingsSubscription';
import {isTablet} from '../../utils/responsive';
import {ProfileHeaderMobile} from '../../components/molecules/ProfileHeaderMobile';
import {signOut} from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import {useSubscription} from '../../hooks/subscription/useSubscription';
import {useResetPassword} from '../../hooks/resetPassword/useResetPassword';
import {switchLocale} from '../../activateLocale.ts';
import {DeleteAccount} from './components/DeleteAccount.tsx';

export const Settings: FunctionComponent = () => {
  const [postLang, setPostLang] = useDefaultLanguage();
  const {subscription} = useSubscription();
  const [isProfile, setIsProfile] = useState<boolean>(true);
  const {me, refetch} = useContext(UserContext);
  const [showDeleteAccountPopup, setShowDeleteAccountPopup] =
    useState<boolean>(false);
  const {setEmail, handleResetPassword} = useResetPassword();

  useEffect(() => {
    setEmail(me?.email as string);
  }, [setEmail, me?.email]);

  const onSignout = useCallback(async () => {
    await signOut();
    refetch();
  }, [refetch]);

  return (
    <Page>
      <Container>
        <Header>
          <StrongText>{t`Settings`}</StrongText>
          <div>
            <StyledLanguageSelect
              {...{postLang, setPostLang}}
              languages={settingLanguages}
              onChange={switchLocale}
            />
          </div>
        </Header>
        <Tabs>
          <LightTab
            isSelected={isProfile}
            label={t`Profile`}
            onClick={() => setIsProfile(true)}
          />
          <LightTab
            isSelected={!isProfile}
            label={t`Subscription`}
            onClick={() => setIsProfile(false)}
          />
        </Tabs>
        {isProfile ? (
          <Center>
            <ProfileHeader
              name={`${me?.firstName} ${me?.lastName}`}
              email={`${me?.email}`}
              onSignout={onSignout}
              handleResetPassword={handleResetPassword}
              setShowDeleteAccountPopup={setShowDeleteAccountPopup}
            />
            <ProfileHeaderMobile
              name={`${me?.firstName} ${me?.lastName}`}
              email={`${me?.email}`}
              onSignout={onSignout}
              handleResetPassword={handleResetPassword}
              setShowDeleteAccountPopup={setShowDeleteAccountPopup}
            />
            <ProfileInformationCard />
          </Center>
        ) : (
          <SettingsSubscription subscription={subscription!} />
        )}
      </Container>
      {showDeleteAccountPopup && (
        <DeleteAccount
          setShowDeleteAccountPopup={setShowDeleteAccountPopup}
          onSignout={onSignout}
        />
      )}
    </Page>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  @media (min-width: ${isTablet}) {
    width: 62em;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StrongText = styled.span`
  font-size: 1.5em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const StyledLanguageSelect = styled(LanguageSelect)`
  width: 9.5625em;
`;
const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
`;
const Center = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  @media (max-width: ${isTablet}) {
    align-items: center;
  }
`;
