import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Modal} from './Modal';
import Gabriel from '../images/Gabriel.png';
import Amelie from '../images/Amelie.png';
import Delphine from '../images/Delphine.png';
import {t} from '@lingui/macro';
import {Button} from '../../atoms/Button';
import {isTablet} from '../../../utils/responsive';

interface Props {
  onClose: () => void;
  showReasonAsk: () => void;
}

export const CancelSubscriptionPopupConfirmation: FunctionComponent<Props> = ({
  onClose,
  showReasonAsk,
}) => {
  return (
    <Modal onClose={onClose}>
      <Container>
        <FelxCol>
          <StrongText>{t`Are you sure you want to cancel the subscription?`}</StrongText>
          <GrayText>{t`If you are doing it, you are messing one of us and that's....unacceptable!`}</GrayText>
          <SmallStrongText>{t`Pick who will take a pay cut.`}</SmallStrongText>
        </FelxCol>
        <Images>
          <Image src={Gabriel} alt="gabriel" />
          <Image src={Amelie} alt="amelie" />
          <StyledImage src={Delphine} alt="delphine" />
        </Images>
        <Row>
          <Button
            onClick={showReasonAsk}
            label={t`I have to cancel it`}
            size="big"
            variant="light"
            fullWidth
          />
          <Button
            onClick={onClose}
            label={t`Alright, I've changed my mind :)`}
            size="big"
            variant="main"
            fullWidth
          />
        </Row>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  margin-top: 1em;
  @media (max-width: ${isTablet}) {
    width: 26em;
  }
`;
const StrongText = styled.span`
  font-weight: 700;
  font-size: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
  max-width: 14em;
`;
const FelxCol = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;
const GrayText = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  line-height: 1.5em;
`;
const SmallStrongText = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 1em;
  font-weight: 700;
  text-align: center;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;
const Images = styled.div`
  display: flex;
  gap: 0.75em;
`;
const Image = styled.img`
  width: 12.8em;
  @media (max-width: ${isTablet}) {
    width: 12.5em;
  }
`;
const StyledImage = styled(Image)`
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
