import {FunctionComponent} from 'react';
import styled, {keyframes} from 'styled-components';
import {isTablet} from '../../utils/responsive';

export const RecyclePostCardSkeleton: FunctionComponent = () => (
  <Card>
    <CardContent>
      <Header>
        <SkeletonLoader $width="10em" $height="1.5em" />
      </Header>
      <ReactionsCommentsSection>
        <SkeletonLoader $width="7em" $height="1em" />
        <SkeletonLoader $width="7em" $height="1em" />
      </ReactionsCommentsSection>
      <ContentSection>
        <SkeletonLoader $width="100%" $height="1em" $marginBottom="0.5em" />
        <SkeletonLoader $width="95%" $height="1em" $marginBottom="0.5em" />
        <SkeletonLoader $width="98%" $height="1em" $marginBottom="0.5em" />
        <SkeletonLoader $width="90%" $height="1em" />
      </ContentSection>
      <ImageContainer>
        <SkeletonLoader $width="100%" $height="15em" $borderRadius="1em" />
      </ImageContainer>
      <SkeletonLoader $width="100%" $height="2.5em" $borderRadius="0.5em" />
    </CardContent>
  </Card>
);

const Card = styled.div<{$isRecycleForm?: boolean}>`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
  width: ${({$isRecycleForm}) =>
    $isRecycleForm ? '100%' : 'calc((100% - 5.5em) / 3)'};
  display: flex;
  gap: 1em;
  position: relative;

  @media (max-width: ${isTablet}) {
    width: 100%;
    flex-direction: column;
  }
`;

const CardContent = styled.div<{$isRecycleForm?: boolean}>`
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: ${({$isRecycleForm}) =>
    $isRecycleForm ? '36.3em' : 'calc((100% - 2em))'};

  @media (max-width: ${isTablet}) {
    width: 36.3em;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const ContentSection = styled.div`
  font-size: 1.2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.3em;
  white-space: pre-wrap;
`;

const ReactionsCommentsSection = styled.div`
  display: flex;
  gap: 1.5em;
  font-size: 1em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;

  img {
    max-width: 100%;
    max-height: 15em;
    border-radius: 1em;
  }
`;

const SkeletonLoader = styled.div<{
  $width: string;
  $height: string;
  $borderRadius?: string;
  $marginBottom?: string;
}>`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border-radius: ${(props) => props.$borderRadius || '0.5em'};
  margin-bottom: ${(props) => props.$marginBottom || '0'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
`;
