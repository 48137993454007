import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import styled, {keyframes} from 'styled-components';
import ChevronBottom from '../../../components/atoms/Icons/ChevronBottom';
import {useTheme} from '../../../components/theme/theme';
import {t} from '@lingui/macro';
import {isTablet} from '../../../utils/responsive';
import {usePostToneOptions} from '../../../hooks/usePostToneOptions';
import {SavedPostTone} from '../../../hooks/savedPost/savedPost.types';
import {StyleSource} from './ChooseStyleSource';
import {useClickOutside} from '../../../hooks/useClickOutside';

interface Props {
  chooseStyleSource: (styleSource: StyleSource) => void;
  setPostTone: Dispatch<SetStateAction<SavedPostTone>>;
  postTone: SavedPostTone;
}

export const PostStyleSelect: FunctionComponent<Props> = ({
  postTone,
  setPostTone,
  chooseStyleSource,
}) => {
  const postToneOptions = usePostToneOptions();
  const theme = useTheme();
  const [isActive, setIsActive] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => {
    setIsActive(false);
  });
  return (
    <Container
      ref={containerRef}
      $active={isActive}
      onClick={() => {
        setIsActive(!isActive);
      }}>
      <Lang>
        <Text>{t`Post type`}</Text>
        <SemiBold>{postTone}</SemiBold>
      </Lang>
      <StyledChevronBottom
        $active={isActive}
        color={theme.colors.neutral.shade11}
      />
      {isActive && (
        <SubContainer>
          {postToneOptions.map((postToneOption, index) => (
            <StyledButton
              key={index}
              $selected={postTone === postToneOption.name}
              onClick={() => {
                setPostTone(postToneOption.value);
                chooseStyleSource(StyleSource.POST_TYPE);
              }}>
              {postToneOption.name}
            </StyledButton>
          ))}
        </SubContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{$active: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.625em;
  justify-content: space-between;
  padding: 0.59em 1em;
  border-radius: 1em;
  border: 1px solid
    ${({theme, $active}) =>
      $active ? theme.colors.primary1.shade2 : theme.colors.neutral.shade5};
  box-shadow: ${({$active, theme}) =>
    $active ? `0px 0px 0px 4px ${theme.colors.primary1.shade1}` : 'none'};
  flex: 1;
  cursor: pointer;
  &:hover {
    ${({$active, theme}) =>
      !$active && `border: 1px solid ${theme.colors.neutral.shade7}`}
  }
  @media (max-width: ${isTablet}) {
    padding: 0.55em 1em;
  }
`;
const Lang = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
`;
const Text = styled.span`
  font-size: 0.75em;
  color: ${({theme}) => theme.colors.neutral.shade8};
`;
const SemiBold = styled.span`
  font-weight: 600;
  font-size: 0.875em;
  letter-spacing: 0.03125em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;
const SubContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 4em;
  padding: 0.8em;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 0.625em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  z-index: 20;
  animation: ${fadeIn} 0.3s ease forwards;
  @media (max-width: ${isTablet}) {
    top: 3.8em;
  }
`;
const StyledButton = styled.button<{$selected: boolean}>`
  display: flex;
  align-items: center;
  gap: 0.625em;
  border-radius: 0.75em;
  padding: 0.5em;
  font-size: 0.875em;
  letter-spacing: 0.0125em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
  background-color: ${({theme, $selected}) =>
    $selected ? theme.colors.primary1.shade5 : 'transparent'};
  border: none;
  text-align: start;
  cursor: pointer;

  ${({theme, $selected}) =>
    $selected &&
    `
        border: 2px solid ${theme.colors.primary1.shade2};
      `}
`;
const StyledChevronBottom = styled(ChevronBottom)<{$active: boolean}>`
  transition: transform 0.3s ease;
  transform: ${({$active}) => ($active ? 'rotate(180deg)' : 'rotate(0deg)')};
  @media (max-width: ${isTablet}) {
    height: 1.25em;
    width: 1.25em;
  }
`;
