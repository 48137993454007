import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import styled from 'styled-components';
import {isTablet} from '../../../utils/responsive';
import {MyStyle} from './MyStyle';
import {CreatorStyle} from './CreatorStyle';
import {RedactaiStyle} from './RedactaiStyle';
import {StyleSource} from '../../../pages/onboarding/components/ChooseStyleSource';
import {SavedPostTone} from '../../../hooks/savedPost/savedPost.types';

interface Props {
  chooseStyleSource: (styleSource: StyleSource) => void;
  setPostTone: Dispatch<SetStateAction<SavedPostTone>>;
  setchoosenStyle: Dispatch<SetStateAction<StyleSource | undefined | string>>;
}

export const OnboardingStyleChoiceCards: FunctionComponent<Props> = ({
  chooseStyleSource,
  setPostTone,
  setchoosenStyle,
}) => {
  return (
    <CardsDesktop>
      <MyStyle
        onClick={() => {
          chooseStyleSource(StyleSource.PROFILE_URL);
        }}
      />
      <CreatorStyle
        onClick={() => {
          chooseStyleSource(StyleSource.OTHERS_PROFILE_URL);
        }}
      />
      <RedactaiStyle {...{setPostTone, setchoosenStyle}} />
    </CardsDesktop>
  );
};

const CardsDesktop = styled.div`
  display: flex;
  gap: 1em;
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
