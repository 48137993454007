import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import GeneratingPostIcon from '../../../components/atoms/Icons/GeneratingPostIcon';
import {RedactedPost} from '../../../hooks/redactPost/useRedactPostMutation';
import {RedactContentSource} from './RedactPostContent';
import {RedactStyleSource} from './RedactPostStyle';
import {RedactPostChatWithAi} from './RedactPostChatWithAi';
import {useLoadingProgress} from '../../../hooks/redactPost/useLoadingProgress';
import {LoadingLayout} from './LoadingLayout';

interface Props {
  posts: RedactedPost[];
  contentSource: RedactContentSource;
  styleSource: RedactStyleSource;
  onChangeInputs: () => void;
  isLoading: boolean;
}

export const RedactPostGeneration: FunctionComponent<Props> = ({
  posts,
  contentSource,
  styleSource,
  onChangeInputs,
  isLoading,
}) => {
  const {loading, progress} = useLoadingProgress(
    contentSource,
    styleSource,
    isLoading,
    true,
    posts,
  );

  return (
    <Container>
      <ContentWrapper>
        {loading && (
          <Col>
            <Top>
              <Header>{t`Generating posts...`}</Header>
            </Top>
            <GeneratingPostIcon />
          </Col>
        )}
        {loading ? (
          <LoadingContent>
            <LoadingLayout progress={progress} />
          </LoadingContent>
        ) : (
          <RedactPostChatWithAi posts={posts} onChangeInputs={onChangeInputs} />
        )}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 1em;
  width: 100%;
`;

const Header = styled.span`
  font-size: 1.7em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
