import {useState, useMemo, useEffect} from 'react';
import {useSnackbar} from 'notistack';
import {useDebounce} from 'use-debounce';
import {t} from '@lingui/macro';
import {wordCount} from '../../utils/WordCount';
import {useSavePost} from '../savedPostsAndIdeas/useSavePost';

export const usePostPreview = (initialText: string, postId?: string) => {
  const {enqueueSnackbar} = useSnackbar();
  const {mutate, isPending} = useSavePost();
  const [showFullText, setShowFullText] = useState(false);
  const [postText, setPostText] = useState<string>(initialText);
  const [isLaptopPreview, setIsLaptopPreview] = useState<boolean>(true);
  const [debouncedPostText] = useDebounce(postText, 500, {
    equalityFn: (prev, next) => prev === next,
  });

  const toggleShowFullText = () => setShowFullText(!showFullText);
  const previewWidth = useMemo(
    () => (isLaptopPreview ? 36.25 : 30),
    [isLaptopPreview],
  );

  const handleCopy = async () => {
    await navigator.clipboard.writeText(postText);
    enqueueSnackbar(t`Copied to clipboard`, {
      variant: 'success',
    });
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPostText(event.target.value);
  };

  useEffect(() => {
    if (!postId || !debouncedPostText) {
      return;
    }
    mutate({id: postId, text: debouncedPostText});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPostText, mutate, postId]);

  return {
    showFullText,
    postText,
    isLaptopPreview,
    previewWidth,
    wordCount: wordCount(postText),
    toggleShowFullText,
    setIsLaptopPreview,
    handleCopy,
    handleTextChange,
    loading: isPending,
  };
};
