import {t} from '@lingui/macro';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import styled from 'styled-components';
import {Button} from '../../../components/atoms/Button';
import ArrowLeftIcon from '../../../components/atoms/Icons/ArrowLeftIcon';
import {isTablet} from '../../../utils/responsive';
import LinkIcon from '../../../components/atoms/Icons/LinkIcon';
import {RadioButton} from '../../../components/atoms/RadioButton';
import LogoDotIcon from '../../../components/atoms/Icons/LogoDotIcon';
import {useTheme} from '../../../components/theme/theme';
import {useSnackbar} from 'notistack';
import {OnboardingStyleChoiceCards} from '../../../components/molecules/ChooseStyleSourceCards/OnboardingStyleChoiceCards';
import {UserContext} from '../../../common/UserContext/user.context';
import {getInitials} from '../../../utils/getInitials';
import {SavedPostTone} from '../../../hooks/savedPost/savedPost.types';

export enum StyleSource {
  PROFILE_URL = 'PROFILE_URL',
  OTHERS_PROFILE_URL = 'OTHERS_PROFILE_URL',
  POST_TYPE = 'POST_TYPE',
}

interface Props {
  chooseStyleSource: (styleSource: StyleSource) => void;
  onGoBack?: () => void;
  setPostTone: Dispatch<SetStateAction<SavedPostTone>>;
}

export const ChooseStyleSource: FunctionComponent<Props> = ({
  chooseStyleSource,
  onGoBack,
  setPostTone,
}) => {
  const theme = useTheme();
  const {me} = useContext(UserContext);
  const {enqueueSnackbar} = useSnackbar();
  const [choosenStyle, setchoosenStyle] = useState<
    StyleSource | undefined | string
  >(undefined);
  return (
    <Col>
      <Top>
        <Steps>
          <StepsCount>
            {t`Step 2:`} <StepsTitle>{t`Style`}</StepsTitle>
          </StepsCount>
          <StepsBar>
            <StepActive />
            <StepActive />
            <StepNotActive />
            <StepNotActive />
          </StepsBar>
        </Steps>
        <Header>{t`Choose the style of your post`}</Header>
      </Top>
      <Bottom>
        <OnboardingStyleChoiceCards
          {...{chooseStyleSource, setPostTone, setchoosenStyle}}
        />
        <CardsMobile>
          <MyStyleMobile
            onClick={() => {
              setchoosenStyle(StyleSource.PROFILE_URL);
            }}>
            <HeaderMobile>
              <AvatarContainer>
                {getInitials(`${me?.firstName} ${me?.lastName}`)}
              </AvatarContainer>
              {me?.firstName}
              {t`'s style`}
            </HeaderMobile>
            <RadioButton
              isSelected={choosenStyle === StyleSource.PROFILE_URL}
            />
          </MyStyleMobile>
          <Line />
          <CreatorStyleMobile
            onClick={() => {
              setchoosenStyle(StyleSource.OTHERS_PROFILE_URL);
            }}>
            <HeaderMobile>
              <LinkIcon height={15} width={15} />
              {t`Another creator's style`}
            </HeaderMobile>
            <RadioButton
              isSelected={choosenStyle === StyleSource.OTHERS_PROFILE_URL}
            />
          </CreatorStyleMobile>
          <Line />
          <RedactaiStyleMobile
            onClick={() => {
              setchoosenStyle(StyleSource.POST_TYPE);
            }}>
            <HeaderMobile>
              <RedactaiPoints>
                <LogoDotIcon />
                <LogoDotIcon />
                <StyledLogoDotIcon color={theme.colors.primary1.shade2} />
              </RedactaiPoints>
              {t`From our list of style`}
            </HeaderMobile>
            <RadioButton isSelected={choosenStyle === StyleSource.POST_TYPE} />
          </RedactaiStyleMobile>
        </CardsMobile>
        <Row>
          <Button
            onClick={onGoBack}
            label={t`Go back`}
            size="medium"
            variant="light"
            startIcon={<StyledArrowLeftIcon />}
          />
          <StyledButton
            onClick={() => {
              if (choosenStyle === undefined) {
                enqueueSnackbar(t`Please choose a style`, {
                  variant: 'error',
                });
                return;
              }
              chooseStyleSource(choosenStyle as StyleSource);
            }}
            label={t`Next`}
            size="medium"
            variant="main"
            fullWidth
          />
        </Row>
      </Bottom>
    </Col>
  );
};
const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4em;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1.5em;
`;
const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
`;
const StepsCount = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade10};
  letter-spacing: 0.03125em;
`;
const StepsTitle = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
`;
const StepsBar = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const StepActive = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade2};
  height: 0.6875em;
  width: 3.6875em;
  border-radius: 2.5em;
  @media (max-width: ${isTablet}) {
    width: 2em;
  }
`;
const StepNotActive = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade7};
  height: 0.6875em;
  width: 3.6875em;
  border-radius: 2.5em;
  @media (max-width: ${isTablet}) {
    width: 2em;
  }
`;
const Header = styled.span`
  font-size: 2.75em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Bottom = styled.div`
  width: 54.125em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;
const StyledButton = styled(Button)`
  width: 15.125em;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;
const CardsMobile = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  @media (min-width: ${isTablet}) {
    display: none;
  }
`;
const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const MyStyleMobile = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CreatorStyleMobile = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const RedactaiStyleMobile = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Line = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade4};
  height: 1px;
  width: 100%;
`;
const HeaderMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.25em;
  letter-spacing: 0.0125em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const RedactaiPoints = styled.div`
  height: 1.25em;
  width: 1.25em;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  border: 1px solid ${({theme}) => theme.colors.primary1.shade8};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8px;
`;
const StyledLogoDotIcon = styled(LogoDotIcon)`
  margin-bottom: 1px;
`;
const AvatarContainer = styled.div`
  height: 1.25em;
  width: 1.25em;
  padding: 1em;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6em;
  font-weight: 600;
  box-sizing: border-box;
`;
