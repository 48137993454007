import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Slider} from '@mui/material';
import {t} from '@lingui/macro';
import {Product} from '../../hooks/pricing/useProducts';

interface Props {
  showSlider?: boolean;
  chosenPostsPerMonth: number;
  setChosenPostsPerMonth: (value: number) => void;
  chosenPostsPerMonthMarks: {value: number; label: string}[];
  productToPricePerPost: (product: Product) => string;
  chosenSliderProduct: Product;
}

export const PricingSlider: FunctionComponent<Props> = ({
  showSlider = true,
  chosenPostsPerMonth,
  setChosenPostsPerMonth,
  chosenPostsPerMonthMarks,
  productToPricePerPost,
  chosenSliderProduct,
}) => {
  return (
    <Container>
      {showSlider ? (
        <>
          <Header>{t`Create ${chosenSliderProduct.posts} posts per month`}</Header>
          <SliderContainer>
            <StyledSlider
              aria-label="Restricted values"
              value={chosenPostsPerMonth}
              onChange={(_, newValue) =>
                setChosenPostsPerMonth(newValue as number)
              }
              step={null}
              marks={chosenPostsPerMonthMarks}
              min={0}
              max={chosenPostsPerMonthMarks.length - 1}
            />
          </SliderContainer>
          <PriceLabel>
            {productToPricePerPost(chosenSliderProduct)}{' '}
            <span>{t`€ per post`}</span>
          </PriceLabel>
        </>
      ) : (
        <>
          <Header>{t`Create 8 posts per month`}</Header>
          <PriceLabel>
            {productToPricePerPost(chosenSliderProduct)}{' '}
            <span>{t`€ per post`}</span>
          </PriceLabel>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  background-color: ${({theme}) => theme.colors.neutral.shade4};
  border-radius: 1.5em;
  gap: 0.5em;
`;

const Header = styled.div`
  font-size: 1.1em;
  font-weight: 700;
  align-self: flex-start;
`;

const SliderContainer = styled.div`
  width: 100%;
`;

const StyledSlider = styled(Slider)`
  margin-top: 2em;
  margin-bottom: 0em;
  & .MuiSlider-rail {
    color: ${({theme}) => theme.colors.neutral.shade1};
    opacity: 1;
    padding: 0.1em 0em;
  }

  & .MuiSlider-track {
    color: ${({theme}) => theme.colors.primary3.shade2};
  }

  & .MuiSlider-thumb {
    height: 1.5em;
    width: 1.5em;
    color: ${({theme}) => theme.colors.neutral.shade1};
    border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  }

  & .MuiSlider-markLabel {
    color: ${({theme}) => theme.colors.neutral.shade11};
    top: -1.5em !important;
    font-size: 0.8em;
    font-weight: 700;
  }
`;

const PriceLabel = styled.div`
  font-size: 1.1em;
  font-weight: 700;
  align-self: flex-start;
  & span {
    font-weight: 500;
  }
`;
