import {FunctionComponent, useContext} from 'react';
import TryForFreeImage from './images/TryForFreeImage.png';
import styled from 'styled-components';
import {Button} from '../atoms/Button';
import ArrowRight from '../atoms/Icons/ArrowRightIcon';
import PostsLeftIcon from '../atoms/Icons/PostsLeftIcon';
import {ProgressBar} from '../atoms/ProgressBar';
import {Status} from '../atoms/Status';
import {format} from 'date-fns';
import {t} from '@lingui/macro';
import {getInitials} from '../../utils/getInitials';
import {UserContext} from '../../common/UserContext/user.context';
import {useNavigate} from 'react-router-dom';
import {LoadingSubscriptionInformationCard} from './LoadingSubscriptionInformationCard';

interface Props {
  name: string;
  numberOfPostsLeft: number;
  numberOfPosts: number;
  renewalDate: Date;
  canceled: boolean;
  className?: string;
  isMobile?: boolean;
}

export const SubscriptionInformationCard: FunctionComponent<Props> = ({
  name,
  numberOfPostsLeft,
  numberOfPosts,
  renewalDate,
  canceled,
  className,
  isMobile,
}) => {
  const navigate = useNavigate();
  const {loading, me} = useContext(UserContext);
  if (loading) return <LoadingSubscriptionInformationCard />;
  if (isMobile) {
    return (
      <>
        {!me?.stripeCustomerId && (
          <Button
            onClick={() => navigate('/pricing')}
            endIcon={<ArrowRight height={15} width={15} />}
            label={t`Try for FREE`}
            size="small"
            variant="main"
            fullWidth
          />
        )}
        {numberOfPostsLeft > 0 && me?.stripeCustomerId && !canceled && (
          <PostsLeftMobile>
            <PostsLeftIcon width={15} height={15} color="white" />
            <StrongTextMobile>
              {numberOfPostsLeft}/{numberOfPosts} {t` posts left`}
            </StrongTextMobile>
          </PostsLeftMobile>
        )}
        {numberOfPostsLeft == 0 && me?.stripeCustomerId && !canceled && (
          <Status label={t`0 Credit left`} variant="warningOutlined" />
        )}
        {canceled && me?.stripeCustomerId && (
          <Status label={t`Subscription Canceled`} variant="warningOutlined" />
        )}
      </>
    );
  }
  return (
    <CardContainer className={className}>
      <Header>
        <AvatarContainer>
          <Avatar>{getInitials(name)}</Avatar>
        </AvatarContainer>
        <Name>{name}</Name>
      </Header>
      {!me?.stripeCustomerId && canceled && (
        <>
          <ImageContainer>
            <StyledImage src={TryForFreeImage} alt="try for free image" />
            <FreeText>
              {t`Try RedactAI`}
              <br />
              <Span>
                {t`7 days`}
                <br />
                {t`for free`}
              </Span>
            </FreeText>
          </ImageContainer>
          <Button
            onClick={() => navigate('/pricing')}
            endIcon={<ArrowRight height={15} width={15} />}
            label={t`Try for FREE`}
            size="small"
            variant="main"
            fullWidth
          />
        </>
      )}
      {numberOfPostsLeft > 0 && !canceled && (
        <>
          <PostsLeft>
            <PostsLeftIcon width={17} height={17} />
            <PostsLeftText>
              <StrongText>
                {numberOfPostsLeft}/{numberOfPosts}{' '}
              </StrongText>
              {t` posts left`}
            </PostsLeftText>
          </PostsLeft>
          <ProgressBar
            progress={(numberOfPostsLeft / numberOfPosts) * 100}
            height="0.6em"
            background="#E7E3DA"
            fullWidth
          />
          <PostsLeftText>
            {t`Renewal date: `}{' '}
            <StyledDate>
              {renewalDate && format(renewalDate, 'MMMM dd')}
            </StyledDate>
          </PostsLeftText>
          <Button
            onClick={() => navigate('/pricing')}
            endIcon={<ArrowRight height={15} width={15} />}
            label={t`Get more posts`}
            size="small"
            variant="main"
            fullWidth
          />
        </>
      )}
      {numberOfPostsLeft == 0 && !canceled && (
        <>
          <FlexDiv>
            <PostsLeft>
              <PostsLeftIcon width={17} height={17} />
              <PostsLeftText>
                <StrongText>
                  {numberOfPostsLeft}/{numberOfPosts}{' '}
                </StrongText>
                {t`posts left`}
              </PostsLeftText>
            </PostsLeft>
            <Status label={t`0 posts left`} variant="warningOutlined" />
          </FlexDiv>
          <PostsLeftText>
            {t`Renewal date: `}{' '}
            <StyledDate>
              {renewalDate && format(renewalDate, 'MMMM dd')}
            </StyledDate>
          </PostsLeftText>
          <Button
            onClick={() => navigate('/pricing')}
            endIcon={<ArrowRight height={15} width={15} />}
            label={t`Get more posts`}
            size="small"
            variant="main"
            fullWidth
          />
        </>
      )}
      {canceled && me?.stripeCustomerId && (
        <>
          <StyledFlexDiv>
            <PostsLeft>
              <PostsLeftIcon width={17} height={17} />
              <PostsLeftText>
                <StrongText>
                  {numberOfPostsLeft}/{numberOfPosts}{' '}
                </StrongText>
                {t`posts left`}
              </PostsLeftText>
            </PostsLeft>
            <Status
              label={t`Subscription Canceled`}
              variant="warningOutlined"
            />
          </StyledFlexDiv>
          <Button
            onClick={() => navigate('/pricing')}
            endIcon={<ArrowRight height={15} width={15} />}
            label={t`Renew subscription`}
            size="small"
            variant="main"
            fullWidth
          />
        </>
      )}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  padding: 1em;
  border-radius: 1.25em;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-top: -7px;
`;
const AvatarContainer = styled.div`
  height: 2.375em;
  width: 2.375em;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.neutral.shade5};
`;
const Avatar = styled.p`
  font-size: 0.9em;
  font-weight: 700;
`;
const Name = styled.p`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.0125em;
  line-height: 1.5em;
`;
const ImageContainer = styled.div`
  margin-bottom: 1.8em;
  margin-top: -0.4em;
  position: relative;
`;
const StyledImage = styled.img`
  height: 12.7em;
  width: 100%;
`;
const FreeText = styled.p`
  font-weight: 700;
  font-size: 1.6em;
  letter-spacing: 0.0125em;
  line-height: 1.3em;
  position: absolute;
  bottom: -1.25em;
`;
const Span = styled.span`
  color: ${({theme}) => theme.colors.primary1.shade3};
`;
const PostsLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const PostsLeftMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade9};
  border-radius: 0.625em;
  padding: 0.2em 0.625em 0.2em 0.3125em;
`;
const PostsLeftText = styled.p`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 0.9em;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
`;

const StrongText = styled.span`
  font-weight: 700;
  font-size: 1.25em;
  line-height: 0.5em;
  letter-spacing: 0.0125em;
`;
const StrongTextMobile = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade1};
  font-weight: 700;
  font-size: 1em;
  letter-spacing: 0.0125em;
`;
const StyledDate = styled.span`
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
`;
const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledFlexDiv = styled(FlexDiv)`
  margin-bottom: 1.25em;
`;
