import {FunctionComponent} from 'react';
import {Page} from '../../components/templates/Page';
import {PricingTable} from '../../components/organisms/PricingTable';

export const Pricing: FunctionComponent = () => {
  return (
    <Page>
      <PricingTable />
    </Page>
  );
};
