import {FunctionComponent, useState} from 'react';
import HelpRoundedIcon from '../../../components/atoms/Icons/HelpRoundedIcon';
import {t} from '@lingui/macro';
import styled from 'styled-components';
import EditIcon from '../../../components/atoms/Icons/EditIcon';
import LinkIcon from '../../../components/atoms/Icons/LinkIcon';
import {TextInput} from '../../../components/atoms/TextInput';
import {ToolTipFirstStep} from './ToolTipFirstStep';
import {isTablet} from '../../../utils/responsive';
import {ToolTipFinalStep} from './ToolTipFinalStep.tsx';

interface Props {
  onGoBack: () => void;
  setPostSubject: (subject: string) => void;
  setPostIdeas: (idaes: string) => void;
  postSubject: string;
  postIdeas: string;
  postSubjectError: string;
  isSubmitted: boolean;
  isOnboarding: boolean;
}

export const GenerateFromMyOwnIdeas: FunctionComponent<Props> = ({
  onGoBack,
  setPostIdeas,
  setPostSubject,
  postIdeas,
  postSubject,
  postSubjectError,
  isSubmitted,
  isOnboarding = true,
}) => {
  const [firstStep, setfirstStep] = useState<boolean>(false);
  const [finalStep, setfinalStep] = useState<boolean>(false);
  const suggestions: string[] = [
    t`What types of content are most successful on LinkedIn?`,
  ];
  return (
    <ContentContainer $isOnboarding={isOnboarding}>
      {isOnboarding && (
        <Between>
          <P>{t`content`}</P>
          <Div>
            <Type>
              <StyledLinkIcon />
              {t`My own ideas`}
            </Type>
            <Change onClick={onGoBack}>
              <StyledEditIcon width={20} height={20} />
              {t`Change`}
            </Change>
          </Div>
        </Between>
      )}
      <ChooseThemeContainer
        $postSubjectError={postSubjectError}
        $postSubject={postSubject}
        $isOnboarding={isOnboarding}>
        <TextInputContainer>
          <StyledTextInput
            label={t`Subject`}
            placeholderText={t`Subject of your post. Example: "How to increase your sales by 30% in 3 months"`}
            value={postSubject}
            onChange={(e) => setPostSubject(e.target.value)}
            error={!postSubject && isSubmitted && postSubjectError !== ''}
            helperText={!postSubject && postSubjectError}
          />
          <StyledHelpRoundedIcon
            $centered
            onClick={() => {
              setfirstStep(!firstStep);
              setfinalStep(false);
            }}>
            <StyHelpRoundedIcon />
          </StyledHelpRoundedIcon>
          {firstStep && <ToolTipFirstStep {...{setfirstStep, setfinalStep}} />}
        </TextInputContainer>
        {isOnboarding && (
          <SuggestionsContainer
            $postSubjectError={postSubjectError}
            $postSubject={postSubject}>
            <Quest>{t`Do you want suggestions on ideas?`}</Quest>
            <Suggestions>
              {suggestions.map((suggestion, index) => (
                <Suggestion
                  key={index}
                  onClick={() => setPostSubject(suggestion)}>
                  {suggestion}
                </Suggestion>
              ))}
            </Suggestions>
          </SuggestionsContainer>
        )}
      </ChooseThemeContainer>
      <TextInputContainer>
        {finalStep && (
          <ToolTipFinalStep {...{setfirstStep, setfinalStep, isOnboarding}} />
        )}
        <StyledTextArea
          label={t`Key Ideas`}
          placeholderText={
            t`Key ideas.` +
            '\n\n' +
            t`Example:` +
            '\n\n' +
            t`- Proximity with clients` +
            '\n' +
            t`- Cheap` +
            '\n' +
            t`- ...`
          }
          value={postIdeas}
          onChange={(e) => setPostIdeas(e.target.value)}
          multiline
          minRows={8}
          maxRows={16}
        />
        <StyledHelpRoundedIcon
          $centered={false}
          onClick={() => {
            setfinalStep(!finalStep);
            setfirstStep(false);
          }}>
          <StyHelpRoundedIcon />
        </StyledHelpRoundedIcon>
      </TextInputContainer>
    </ContentContainer>
  );
};

const ContentContainer = styled.div<{$isOnboarding: boolean}>`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  ${({$isOnboarding}) => $isOnboarding && 'padding: 1.5em;'}
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  width: 100%;
  box-sizing: border-box;
`;
const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    align-items: start;
    gap: 1em;
  }
`;
const P = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    width: 100%;
    justify-content: space-between;
  }
`;
const Type = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 1.125em;
  font-weight: 600;
`;
const Change = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.primary4.shade1};
  font-size: 0.875em;
  font-weight: 600;
  cursor: pointer;
`;
const ChooseThemeContainer = styled.div<{
  $postSubjectError: string;
  $postSubject: string;
  $isOnboarding: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: ${({$isOnboarding, $postSubjectError, $postSubject}) =>
    !$isOnboarding && $postSubjectError && !$postSubject ? '0.7em' : '0'};
`;
const TextInputContainer = styled.div`
  flex: 1;
  position: relative;
`;
const StyledTextInput = styled(TextInput)`
  width: 100%;
`;
const StyledTextArea = styled(TextInput)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 0.5em;
    padding-left: 0.4em;
  }
`;
const SuggestionsContainer = styled.div<{
  $postSubjectError: string;
  $postSubject: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  margin-top: ${({$postSubjectError, $postSubject}) =>
    $postSubjectError && !$postSubject && '0.7em'};
`;
const Quest = styled.span`
  color: ${({theme}) => theme.colors.primary4.shade10};
  font-size: 0.75em;
  letter-spacing: 0.0125em;
`;
const Suggestions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const Suggestion = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade3};
  border-radius: 3.125em;
  padding: 0.5em 0.75em;
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 0.75em;
  letter-spacing: 0.0125em;
  cursor: pointer;
`;
const StyledHelpRoundedIcon = styled.div<{$centered: boolean}>`
  position: absolute;
  right: 1em;
  top: ${({$centered}) => ($centered ? '50%' : '2em')};
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  background-color: white;
  height: 1.5em;
  padding-left: 6px;
`;
const StyledLinkIcon = styled(LinkIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const StyledEditIcon = styled(EditIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const StyHelpRoundedIcon = styled(HelpRoundedIcon)`
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    height: 1.25em;
    width: 1.25em;
  }
`;
